import React from "react";
import img from "../../../../assets/images/about/7.jpg";
import img2 from "../../../../assets/images/about/3.jpg";
import img3 from "../../../../assets/images/about/singnture.png";
import SideBarAccordion from "../../../../components/SideBarAccordion/SideBarAccordion";

export default function SolutionsLayoutThree() {
  return (
    <section id="about" className="about-layout2 animated-Progressbars">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
            <div className="heading mb-40">
              <div className="d-flex align-items-center mb-20">
                <div className="divider divider-primary mr-30" />
                <h2 className="heading__subtitle mb-0">
                  Curate And Efficient Solution Of IT Support
                </h2>
              </div>
              <h3 className="heading__title mb-40">
                At Business Savvy UK, we offer a comprehensive range of IT support and services designed to keep your business running smoothly.
              </h3>
            </div>
          </div>
          {/* /.col-xl-5 */}
        </div>
        {/* /.row */}
        <div className="row about__Text">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
            <div className="offset-xl-2 position-relative">
              <i className="icon-quote" />
              <p>Our team of IT experts has the experience and knowledge to help you manage your hardware, software, network, and security needs. In addition, we work closely with our clients to provide tailored solutions that meet their specific requirements.
                <br />
                <br />
                Our hardware support services ensure that your devices are always running smoothly and that any issues are addressed promptly. From desktops to laptops and everything in between, we have the expertise to keep your hardware running at peak performance.
                <br />
                <br />
                Our software support services assist with software installation, updates, and troubleshooting. We work with you to ensure your software is up-to-date and functioning correctly. Our network support services ensure that your network is always up and running. We provide support for network hardware and software, as well as assistance with network setup and troubleshooting.
                <br />
                <br />
                And with our security support services, we help businesses keep their data and systems safe from cyber threats. We provide a range of security services, including antivirus software installation and updates, firewall management, and data backup and recovery.
                <br />
                <br />
                At Business Savvy UK, we understand the importance of reliable and secure IT infrastructure. That's why we offer a full range of IT support and services designed to keep your business operating efficiently and securely.
                <br />
                <br />
                Contact us today to learn more about how we can help your business succeed with our IT support and services.
              </p>
            </div>
            <div className="about__img mt-80 mb-30">
              <img src={img} alt="about" />
            </div>
            {/* /.about__img*/}
            <div className="video__btn-wrapper">
              <a
                className="video__btn video__btn-white popup-video"
                href="https://www.youtube.com/watch?v=nrJtHemSPW4"
              >
                <div className="video__player">
                  <i className="fa fa-play" />
                </div>
                <span className="video__btn-title">
                  Watch Our Presentation!
                </span>
              </a>
            </div>
            {/* /.video__btn-wrapper*/}
          </div>
          {/* /.col-xl-6 */}
          <SideBarAccordion />
          {/* /.col-xl-5 */}
        </div>
        {/* /.row */}
      </div>
      {/* /.container */}
    </section>
  );
}

import React, { useEffect, useState } from "react";
import Headers from "../../../components/header/Headers";
import SecondaryNav from "../../../components/header/SecondaryNav";
import ItSolutionsSinglePageTitle from "./ITSupport/ItSolutionsSinglePageTitle";
import SolutionClient from "./ITSupport/SolutionClient";
import SolutionsLayoutThree from "./ITSupport/SolutionsLayoutThree";
import BannerLayoutThree from "../../../../src/pages/Industries/IndestriesSections/BannerLayoutThree";
import PortfolioGridSection from "../../Home/homeSections/PortfolioGridSection";
import Pricing from "./ITSupport/Pricing";
import SolutionBannerLayoutOne from "./ITSupport/SolutionBannerLayoutOne";
import SolutionFaq from "./ITSupport/SolutionFaq";
import DarkFooter from "../../../components/footer/DarkFooter";
import PricingContent from "../../../components/PriceTable/PricingContent";

export default function ITSupport() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <Headers />
      <ItSolutionsSinglePageTitle />
      <SecondaryNav />
      <SolutionsLayoutThree />
      <SolutionClient />
      <BannerLayoutThree />
      <PortfolioGridSection />
      <div className="checking">
        <PricingContent />
      </div>
      <SolutionBannerLayoutOne />
      <SolutionFaq />
      <DarkFooter />
    </>
  );
}

import React from "react";
import img from "../../../../assets/images/about/7.jpg";
import img3 from "../../../../assets/images/about/singnture.png";
import SideBarAccordion from "../../../../components/SideBarAccordion/SideBarAccordion";

export default function SolutionsLayoutThree() {
  return (
    <section id="about" className="about-layout2 animated-Progressbars">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
            <div className="heading mb-40">
              <div className="d-flex align-items-center mb-20">
                <div className="divider divider-primary mr-30" />
                <h2 className="heading__subtitle mb-0">
                  Curate And Efficient Solution Of eBay
                </h2>
              </div>
              <h3 className="heading__title mb-40">
                At BuyBox Savvy, we understand the unique challenges of being an eBay seller.
              </h3>
            </div>
          </div>
          {/* /.col-xl-5 */}
        </div>
        {/* /.row */}
        <div className="row about__Text">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
            <div className="offset-xl-2 position-relative">
              <i className="icon-quote" />
              <p>Our team of eBay consultants has years of experience working with businesses of all sizes and industries. We provide various services to help enterprises to build a strong presence on eBay, including optimizing product listings, improving search rankings, and creating effective marketing campaigns.
                <br />
                <br />
                We start by getting to know your business, products, and target audience. This allows us to develop a customized strategy that meets your needs and goals. Our eBay consulting services include market research, competitor analysis, and data analysis to help you stay ahead of the curve and maximize your sales.
                <br />
                <br />
                We also help businesses create an effective marketing strategy, including email marketing, social media marketing, and advertising campaigns. Our team of experts is skilled at creating compelling content and graphics that engage customers and drive sales.
                <br />
                <br />
                We understand the importance of staying up to date with the latest eBay policies and guidelines. That's why we offer ongoing support and guidance to ensure your business remains compliant and thriving on the platform.
                <br />
                <br />
                Partnering with us for your eBay consulting needs means working with a team of experts dedicated to your success.
                <br />
                <br />
                Contact us today to learn how we can help you achieve your business goals on eBay.
              </p>
            </div>
            <div className="about__img mt-80 mb-30">
              <img src={img} alt="about" />
            </div>
            {/* /.about__img*/}
            <div className="video__btn-wrapper">
              <a
                className="video__btn video__btn-white popup-video"
                href="https://www.youtube.com/watch?v=nrJtHemSPW4"
              >
                <div className="video__player">
                  <i className="fa fa-play" />
                </div>
                <span className="video__btn-title">
                  Watch Our Presentation!
                </span>
              </a>
            </div>
            {/* /.video__btn-wrapper*/}
          </div>
          {/* /.col-xl-6 */}
          <SideBarAccordion />
          {/* /.col-xl-5 */}
        </div>
        {/* /.row */}
      </div>
      {/* /.container */}
    </section>
  );
}

import React from "react";
import img from "../../../../assets/images/about/7.jpg";
import img2 from "../../../../assets/images/about/3.jpg";
import img3 from "../../../../assets/images/about/singnture.png";
import SideBarAccordion from "../../../../components/SideBarAccordion/SideBarAccordion";

export default function SolutionsLayoutThree() {
  return (
    <section id="about" className="about-layout2 animated-Progressbars">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
            <div className="heading mb-40">
              <div className="d-flex align-items-center mb-20">
                <div className="divider divider-primary mr-30" />
                <h2 className="heading__subtitle mb-0">
                  Curate And Efficient Solution Of Product Sourcing
                </h2>
              </div>
              <h3 className="heading__title mb-40">
                Our product sourcing and recommendation service is designed to help companies source the best products from manufacturers seeking dedicated distributors or resellers.
              </h3>
            </div>
          </div>
          {/* /.col-xl-5 */}
        </div>
        {/* /.row */}
        <div className="row about__Text">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
            <div className="offset-xl-2 position-relative">
              <i className="icon-quote" />
              <p>We tailor our sourcing to each client's individual needs, ensuring they have little to no competition in some cases. We aim to help businesses stand out in their market and gain a competitive edge.
                <br />
                <br />
                But our services don't stop at sourcing. We also offer logistics services, ensuring that the products are delivered to your doorstep promptly and efficiently. In addition, we help with the route to market in promoting the product, guiding the best ways to reach your target audience and promote your product effectively.
                <br />
                <br />
                At Business Savvy UK, we understand that every business has unique needs. That's why we offer customized product sourcing and recommendation services tailored to each client's individual needs. Whether you're a startup or an established business, we can help you find the right products to promote and grow your business.
                <br />
                <br />
                We have established partnerships with manufacturers and suppliers worldwide, allowing us to offer a wide range of products across various industries. Our team of experts is dedicated to providing the best service possible, ensuring our clients' access to the latest and most innovative products.
                <br />
                <br />
                Partnering with us means working with a team of professionals dedicated to your success. We take the time to understand your business and goals, providing personalized solutions that meet your needs.
                <br />
                <br />
                Contact us today to learn how we can help you find the best products to promote and grow your business.
              </p>
            </div>
            <div className="about__img mt-80 mb-30">
              <img src={img} alt="about" />
            </div>
            {/* /.about__img*/}
            <div className="video__btn-wrapper">
              <a
                className="video__btn video__btn-white popup-video"
                href="https://www.youtube.com/watch?v=nrJtHemSPW4"
              >
                <div className="video__player">
                  <i className="fa fa-play" />
                </div>
                <span className="video__btn-title">
                  Watch Our Presentation!
                </span>
              </a>
            </div>
            {/* /.video__btn-wrapper*/}
          </div>
          {/* /.col-xl-6 */}
          <SideBarAccordion />
          {/* /.col-xl-5 */}
        </div>
        {/* /.row */}
      </div>
      {/* /.container */}
    </section>
  );
}

import React from 'react';
import SingleServiceItems from './SingleServiceItems';
import DataDiscoverySub from './SalesAndMarketingAllSub/DataDiscoverySub/DataDiscoverySub';
import BusinessOpportunitieSub from './SalesAndMarketingAllSub/BusinessOpportunitieSub/BusinessOpportunitieSub';
import LinkedInMarketingSub from './SalesAndMarketingAllSub/LinkedInServicesSub/LinkedInServicesSub';
import ProductSourcingSub from './SalesAndMarketingAllSub/ProductSourcingSub/ProductSourcingSub';

const BrochureSection = () => {
    return (
        <section className="services-layout1 bg-gray pt-130 pb-90">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-6 offset-lg-3">
                        <div className="heading text-center mb-50">
                            <h2 className="heading__subtitle">
                                Business Savvy UK
                            </h2>
                            <h3 className="heading__title">
                                Sales & Marketing Single Category items. Selected your affordable Service.
                            </h3>
                        </div>
                        {/* /.heading */}
                    </div>
                    {/* /.col-lg-8 */}
                </div>
                <>
                    <SingleServiceItems />
                </>
                {/* data Discovery sub */}
                <DataDiscoverySub />
                {/* data Discovery card */}
                {/* <Pricing /> */}
                {/* Product Source sub */}
                <ProductSourcingSub />
                {/* Product Source card */}
                {/* <Pricing /> */}
                {/* Business Opportunity sub */}
                <BusinessOpportunitieSub />
                {/* Business Opportunity card */}
                {/* <Pricing /> */}
                {/* LinkedIn service sub */}
                <LinkedInMarketingSub />
                {/* LinkedIn service card */}
                {/* <Pricing /> */}
\

            </div>
            {/* /.container */}
        </section>
    );
};

export default BrochureSection;
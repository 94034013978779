import React from "react";
import img2 from "../../../assets/images/banners/YT-Banner.jpg";

export default function BannerLayoutThree() {
  return (
    <section
      id="banner3"
      className="banner-layout3 pb-70 bg-slider-img-Banner-multi"
    >
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-5 fancybox-light">
            <div className="heading heading-light mb-60">
              <h3 className="heading__title mb-30">
              The Business Savvy Assurance
              </h3>
              <p className="heading__desc">
              Your business is distinct, and its digital strategy should reflect
               that uniqueness. At Business Savvy, generic solutions don't cut. 
               We immerse ourselves in understanding your brand's ethos, target 
               audience, and aspirations. Our promise? A tailored digital roadmap 
               emphasising growth, meaningful engagements, and a prominent online
                footprint.</p>
            </div>
            {/* /.heading */}
            {/* fancybox item #1 */}
            <div className="fancybox-item d-flex flex-wrap">
              <div className="fancybox__icon">
                <i className="icon-coding" />
              </div>
              {/* /.fancybox__icon */}
              <div className="fancybox__content">
                <h4 className="fancybox__title">Customised Digital Strategy</h4>
                <p className="fancybox__desc">
                Solutions sculpted with your business essence at the heart.
                </p>
                <p className="fancybox__desc">
                Deep diving into your business, we strategise harmoniously with your core values.
                </p>
{/*                 
                <a href="#" className="btn btn__white btn__link">
                  <span>Read More</span>
                  <i className="icon-arrow-right" />
                </a> */}
              </div>
              {/* /.fancybox-content */}
            </div>
            {/* /.fancybox-item */}
            {/* fancybox item #2 */}
            <div className="fancybox-item d-flex flex-wrap">
              <div className="fancybox__icon">
                <i className="icon-programming2" />
              </div>
              {/* /.fancybox__icon */}
              <div className="fancybox__content">
                <h4 className="fancybox__title">Emphasis on Value-driven Results</h4>
                <p className="fancybox__desc">
                Our measure of success is rooted in impactful engagements, not just numbers.
                </p>
                <p className="fancybox__desc">
                We champion building genuine, enduring connections between your brand and clientele.
                </p>
                {/* <a href="#" className="btn btn__white btn__link">
                  <span>Read More</span>
                  <i className="icon-arrow-right" />
                </a> */}
              </div>
              {/* /.fancybox-content */}
            </div>
            {/* /.fancybox-item */}
            {/* fancybox item #3 */}
            <div className="fancybox-item d-flex flex-wrap">
              <div className="fancybox__icon">
                <i className="icon-script" />
              </div>
              {/* /.fancybox__icon */}
              <div className="fancybox__content">
                <h4 className="fancybox__title">Future-proof Digital Solutions:</h4>
                <p className="fancybox__desc">
                Stay ahead and adaptive in a dynamic digital world.
                </p>
                <p className="fancybox__desc">
                Our team is perpetually in sync with emerging digital trends, crafting strategies that stand the test of time.
                </p>
                {/* <a href="#" className="btn btn__white btn__link">
                  <span>Read More</span>
                  <i className="icon-arrow-right" />
                </a> */}
              </div>
              {/* /.fancybox-content */}
            </div>
            {/* /.fancybox-item */}
          </div>
          {/* /.col-xl-5 */}
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-7">
            <div className="sticky-top">
              <div className="banner-img">
                <img src={img2} alt="banner" />
                <a
                  className="video__btn video__btn-white popup-video"
                  href="https://www.youtube.com/watch?v=nrJtHemSPW4"
                >
                  <div className="video__player">
                    <i className="fa fa-play" />
                  </div>
                </a>
              </div>
              <div className="d-flex flex-wrap justify-content-end read-note mt-40">
                <div className="rating mb-10 mr-50">
                  <i className="fas fa-star" />
                  <i className="fas fa-star" />
                  <i className="fas fa-star" />
                  <i className="fas fa-star" />
                  <i className="fas fa-star" />
                </div>
                <p className="read-note__text color-white">
                  <span className="font-weight-bold text-underlined">
                    99.9% Customer Satisfaction
                  </span>
                  based on 750+ reviews and 20,000 Objective Resource
                </p>
              </div>
            </div>
          </div>
          {/* /.col-xl-7 */}
        </div>
        {/* /.row */}
      </div>
      {/* /.container */}
    </section>
  );
}

import React from "react";
import img from "../../../../assets/images/about/7.jpg";
import img2 from "../../../../assets/images/about/3.jpg";
import img3 from "../../../../assets/images/about/singnture.png";
import SideBarAccordion from "../../../../components/SideBarAccordion/SideBarAccordion";

export default function SolutionsLayoutThree() {
  return (
    <section id="about" className="about-layout2 animated-Progressbars">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
            <div className="heading mb-40">
              <div className="d-flex align-items-center mb-20">
                <div className="divider divider-primary mr-30" />
                <h2 className="heading__subtitle mb-0">
                  Curate And Efficient Solution Of Website Building
                </h2>
              </div>
              <h3 className="heading__title mb-40">
                That's why we offer a comprehensive website-building service that is tailored to meet the specific needs of each of our clients.
              </h3>
            </div>
          </div>
          {/* /.col-xl-5 */}
        </div>
        {/* /.row */}
        <div className="row about__Text">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
            <div className="offset-xl-2 position-relative">
              <i className="icon-quote" />
              <p>Our team of web developers use the latest MERN stack technology and SEO-friendly platforms to build custom websites that are designed to be engaging, responsive and optimized for search engines. We understand the importance of a user-friendly experience, and we work closely with our clients to create websites that are easy to navigate and encourage visitors to act.
                <br />
                <br />
                No matter the size of your business, our web development team can help you create a website that will effectively represent your brand and attract potential customers. Whether you need a simple one-page website or a complex e-commerce platform, we can build it for you.
                <br />
                <br />
                Our websites are optimized for search engines, ensuring your business is visible online and effectively reaches your target audience. With our expertise in SEO and digital marketing, we can help your website rank higher in search engine results, driving more traffic to your site and increasing your online presence.
                <br />
                <br />
                At Business Savvy UK, we take pride in building high-quality, custom websites that help our clients achieve their business goals. Our web development team has the skills and experience to deliver outstanding results for businesses of all sizes.
                <br />
                <br />
                Contact us today to learn how we can help you build a website that will take your business to the next level.
              </p>
            </div>
            <div className="about__img mt-80 mb-30">
              <img src={img} alt="about" />
            </div>
            {/* /.about__img*/}
            <div className="video__btn-wrapper">
              <a
                className="video__btn video__btn-white popup-video"
                href="https://www.youtube.com/watch?v=nrJtHemSPW4"
              >
                <div className="video__player">
                  <i className="fa fa-play" />
                </div>
                <span className="video__btn-title">
                  Watch Our Presentation!
                </span>
              </a>
            </div>
            {/* /.video__btn-wrapper*/}
          </div>
          {/* /.col-xl-6 */}
          <SideBarAccordion />
          {/* /.col-xl-5 */}
        </div>
        {/* /.row */}
      </div>
      {/* /.container */}
    </section>
  );
}

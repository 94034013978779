import React from "react";
import img from "../../../../assets/images/about/7.jpg";
import img2 from "../../../../assets/images/about/3.jpg";
import img3 from "../../../../assets/images/about/singnture.png";
import SideBarAccordion from "../../../../components/SideBarAccordion/SideBarAccordion";

export default function SolutionsLayoutThree() {
  return (
    <section id="about" className="about-layout2 animated-Progressbars">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
            <div className="heading mb-40">
              <div className="d-flex align-items-center mb-20">
                <div className="divider divider-primary mr-30" />
                <h2 className="heading__subtitle mb-0">
                  Curate And Efficient Solution Of Graphic Designs
                </h2>
              </div>
              <h3 className="heading__title mb-40">
                From logos and business cards to brochures and other marketing materials, we have the expertise to bring your vision to life.</h3>
            </div>
          </div>
          {/* /.col-xl-5 */}
        </div>
        {/* /.row */}
        <div className="row about__Text">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
            <div className="offset-xl-2 position-relative">
              <i className="icon-quote" />
              <p>We take the time to understand your business and target audience and use the latest design software and techniques to create custom designs that reflect your unique brand. Our graphic design services are tailored to help businesses build a professional image that engages their customers and sets them apart in the market.
                <br />
                <br />
                Don't settle for generic designs that blend in with the competition. Instead, let us help you create a standout image that showcases your business in the best possible light. Contact us today to learn more about our custom graphic design services.
              </p>
            </div>
            <div className="about__img mt-80 mb-30">
              <img src={img} alt="about" />
            </div>
            {/* /.about__img*/}
            <div className="video__btn-wrapper">
              <a
                className="video__btn video__btn-white popup-video"
                href="https://www.youtube.com/watch?v=nrJtHemSPW4"
              >
                <div className="video__player">
                  <i className="fa fa-play" />
                </div>
                <span className="video__btn-title">
                  Watch Our Presentation!
                </span>
              </a>
            </div>
            {/* /.video__btn-wrapper*/}
          </div>
          {/* /.col-xl-6 */}
          <SideBarAccordion />
          {/* /.col-xl-5 */}
        </div>
        {/* /.row */}
      </div>
      {/* /.container */}
    </section>
  );
}

import React from "react";

const PolicyContainer = () => {
  return (
    <div className="container md:mx-auto ">
      <div className=" bg-white mt-40 mb-40">
        <h2 className="text-[32px] text-center font-bold">Privacy Policy</h2>
        <p>
          This privacy policy sets out how Business Savvy UK Ltd uses and
          protects any information that you give Business Savvy UK when you use
          this website. Business Savvy UK is committed to ensuring that your
          privacy is protected. Should we ask you to provide certain information
          by which you can be identified when using this website, then you can
          be assured that it will only be used in accordance with this privacy
          statement. Business Savvy UK may change this policy from time to time
          by updating this page. You should check this page from time to time to
          ensure that you are happy with any changes.
        </p>

        <h2 className="text-2xl font-bold my-4">WHAT WE COLLECT</h2>
        <p>We may collect the following information:</p>

        <ul className="list-disc ml-8">
          <li>name</li>
          <li>contact information including email address</li>
          <li>
            demographic information such as postcode, preferences and interests
          </li>
          <li>other information relevant to customer surveys and/or offers</li>
        </ul>
        <p>
          For the exhaustive list of cookies we collect see the&nbsp;
          <a href="https://9-stars.co.uk/cookies-policy">
            List of cookies we collect
          </a>
          &nbsp;section.
        </p>

        <h2 className="text-2xl font-bold my-4">
          WHAT WE DO WITH THE INFORMATION WE GATHER
        </h2>

        <p>
          We require this information to understand your needs and provide you
          with a better service, and in particular for the following reasons:
        </p>

        <ul className="list-disc ml-8">
          <li>Internal record keeping.</li>
          <li>
            We may use the information to improve our products and services.
          </li>
          <li>
            We may periodically send promotional emails about new products,
            special offers or other information which we think you may find
            interesting using the email address which you have provided.
          </li>

          <li>
            From time to time, we may also use your information to contact you
            for market research purposes. We may contact you by email, phone,
            fax or mail. We may use the information to customise the website
            according to your interests.
          </li>
        </ul>

        <h2 className="text-2xl font-bold my-4">SECURITY</h2>

        <p>
          We are committed to ensuring that your information is secure. In order
          to prevent unauthorised access or disclosure, we have put in place
          suitable physical, electronic and managerial procedures to safeguard
          and secure the information we collect online.
        </p>

        <h2 className="text-2xl font-bold my-4">HOW WE USE COOKIES</h2>

        <p>
          A cookie is a small file which asks permission to be placed on your
          computer&apos;s hard drive. Once you agree, the file is added and the
          cookie helps analyse web traffic or lets you know when you visit a
          particular site. Cookies allow web applications to respond to you as
          an individual. The web application can tailor its operations to your
          needs, likes and dislikes by gathering and remembering information
          about your preferences.
        </p>

        <p>
          We use traffic log cookies to identify which pages are being used.
          This helps us analyse data about web page traffic and improve our
          website in order to tailor it to customer needs. We only use this
          information for statistical analysis purposes and then the data is
          removed from the system.
        </p>

        <p>
          Overall, cookies help us provide you with a better website, by
          enabling us to monitor which pages you find useful and which you do
          not. A cookie in no way gives us access to your computer or any
          information about you, other than the data you choose to share with
          us. You can choose to accept or decline cookies. Most web browsers
          automatically accept cookies, but you can usually modify your browser
          setting to decline cookies if you prefer. This may prevent you from
          taking full advantage of the website.
        </p>

        <h2 className="text-2xl font-bold my-4">LINKS TO OTHER WEBSITES</h2>

        <p>
          Our website may contain links to other websites of interest. However,
          once you have used these links to leave our site, you should note that
          we do not have any control over that other website. Therefore, we
          cannot be responsible for the protection and privacy of any
          information which you provide whilst visiting such sites and such
          sites are not governed by this privacy statement. You should exercise
          caution and look at the privacy statement applicable to the website in
          question.
        </p>

        <h2 className="text-2xl font-bold my-4">
          CONTROLLING YOUR PERSONAL INFORMATION
        </h2>

        <p>
          You may choose to restrict the collection or use of your personal
          information in the following ways:
        </p>

        <ul className="list-disc ml-8">
          <li>
            whenever you are asked to fill in a form on the website, look for
            the box that you can click to indicate that you do not want the
            information to be used by anybody for direct marketing purposes
          </li>

          <li>
            if you have previously agreed to us using your personal information
            for direct marketing purposes, you may change your mind at any time
            by writing to or emailing us at&nbsp;
            <a
              href="mailto:info@business-savvy-uk.co.uk"
              data-type="mailto"
              data-id="mailto:info@business-savvy-uk.co.uk"
            >
              info@business-savvy-uk.co.uk
            </a>
          </li>
        </ul>

        <p>
          We will not sell, distribute or lease your personal information to
          third parties unless we have your permission or are required by law to
          do so. We may use your personal information to send you promotional
          information about third parties which we think you may find
          interesting if you tell us that you wish this to happen.
        </p>

        <p>
          You may request details of personal information which we hold about
          you under the Data Protection Act 1998. A small fee will be payable.
          If you would like a copy of the information held on you please write
          to Business Savvy UK Ltd StorageMart, Blackburn Road, Houghton Regis,
          LU5 5BQ.
        </p>

        <p>
          If you believe that any information we are holding on you is incorrect
          or incomplete, please write to or email us as soon as possible, at the
          above address. We will promptly correct any information found to be
          incorrect.
        </p>

        <h2 className="text-2xl font-bold my-4">
          <a></a>LIST OF COOKIES WE COLLECT
        </h2>
        <p>
          The table below lists the cookies we collect and what information they
          store.
        </p>

        <figure>
          <table>
            <thead>
              <tr>
                <th>COOKIE NAME</th>
                <th>COOKIE DESCRIPTION</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th class="items-start" data-align="left">
                  CART
                </th>
                <td>The association with your shopping cart.</td>
              </tr>

              <tr>
                <th>CATEGORY_INFO</th>
                <td>
                  Stores the category info on the page, that allows to display
                  pages more quickly.
                </td>
              </tr>

              <tr>
                <th class="items-start" data-align="left">
                  COMPARE
                </th>
                <td>The items that you have in the Compare Products list.</td>
              </tr>
              <tr>
                <th class="items-start" data-align="left">
                  CURRENCY
                </th>
                <td>Your preferred currency</td>
              </tr>
              <tr>
                <th class="items-start" data-align="left">
                  CUSTOMER
                </th>
                <td>
                  An encrypted version of your customer id with the store.
                </td>
              </tr>
              <tr>
                <th class="items-start" data-align="left">
                  CUSTOMER_AUTH
                </th>
                <td>
                  An indicator if you are currently logged into the store.
                </td>
              </tr>
              <tr>
                <th class="items-start" data-align="left">
                  CUSTOMER_INFO
                </th>
                <td>
                  An encrypted version of the customer group you belong to.
                </td>
              </tr>

              <tr>
                <th class="items-start" data-align="left">
                  CUSTOMER_SEGMENT_IDS
                </th>
                <td>Stores the Customer Segment ID</td>
              </tr>
              <tr>
                <th class="items-start" data-align="left">
                  EXTERNAL_NO_CACHE
                </th>
                <td>
                  A flag, which indicates whether caching is disabled or not.
                </td>
              </tr>
              <tr>
                <th class="items-start" data-align="left">
                  FRONTEND
                </th>
                <td>You sesssion ID on the server.</td>
              </tr>
              <tr>
                <th class="items-start" data-align="left">
                  GUEST-VIEW
                </th>
                <td>Allows guests to edit their orders.</td>
              </tr>
              <tr>
                <th class="items-start" data-align="left">
                  LAST_CATEGORY
                </th>
                <td>The last category you visited.</td>
              </tr>
              <tr>
                <th class="items-start" data-align="left">
                  LAST_PRODUCT
                </th>
                <td>The most recent product you have viewed.</td>
              </tr>
              <tr>
                <th class="items-start" data-align="left">
                  NEW MESSAGE
                </th>
                <td>Indicates whether a new message has been received.</td>
              </tr>
              <tr>
                <th class="items-start" data-align="left">
                  NO_CACHE
                </th>
                <td>Indicates whether it is allowed to use cache.</td>
              </tr>
              <tr>
                <th class="items-start" data-align="left">
                  PERSISTENT_SHOPPING_CART
                </th>
                <td>
                  A link to information about your cart and viewing history if
                  you have asked the site.
                </td>
              </tr>
              <tr>
                <th class="items-start" data-align="left">
                  POLL
                </th>
                <td>The ID of any polls you have recently voted in.</td>
              </tr>
              <tr>
                <th class="items-start" data-align="left">
                  POLLN
                </th>
                <td>Information on what polls you have voted on.</td>
              </tr>
              <tr>
                <th class="items-start" data-align="left">
                  RECENTLY COMPARED
                </th>
                <td>The items that you have recently compared.</td>
              </tr>
              <tr>
                <th class="items-start" data-align="left">
                  STF
                </th>
                <td>Information on products you have emailed to friends.</td>
              </tr>
              <tr>
                <th class="items-start" data-align="left">
                  STORE
                </th>
                <td>The store view or language you have selected.</td>
              </tr>
              <tr>
                <th class="items-start" data-align="left">
                  USER_ALLOWED_SAVE_COOKIE
                </th>
                <td>Indicates whether a customer allowed to use cookies.</td>
              </tr>
              <tr>
                <th class="items-start" data-align="left">
                  VIEWED_PRODUCT_IDS
                </th>
                <td>The products that you have recently viewed.</td>
              </tr>
              <tr>
                <th class="items-start" data-align="left">
                  WISHLIST
                </th>
                <td>An encrypted list of products added to your Wishlist.</td>
              </tr>
              <tr>
                <th class="items-start" data-align="left">
                  WISHLIST_CNT
                </th>
                <td>The number of items in your Wishlist.</td>
              </tr>
            </tbody>
          </table>
        </figure>
      </div>
    </div>
  );
};

export default PolicyContainer;

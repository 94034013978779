import React from "react";
import img from "../../../../assets/images/about/7.jpg";
import img2 from "../../../../assets/images/about/3.jpg";
import img3 from "../../../../assets/images/about/singnture.png";
import SideBarAccordion from "../../../../components/SideBarAccordion/SideBarAccordion";

export default function SolutionsLayoutThree() {
  return (
    <section id="about" className="about-layout2 animated-Progressbars">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
            <div className="heading mb-40">
              <div className="d-flex align-items-center mb-20">
                <div className="divider divider-primary mr-30" />
                <h2 className="heading__subtitle mb-0">
                  Curate And Efficient Solution Of OnBuy
                </h2>
              </div>
              <h3 className="heading__title mb-40">
                That's why we offer OnBuy seller consulting services to help you take advantage of this emerging marketplace.
              </h3>
            </div>
          </div>
          {/* /.col-xl-5 */}
        </div>
        {/* /.row */}
        <div className="row about__Text">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
            <div className="offset-xl-2 position-relative">
              <i className="icon-quote" />
              <p>With our team of experts by your side, we'll help you optimize your OnBuy presence and create a successful marketing strategy that meets your unique business needs.
                <br />
                <br />
                We don't believe in one-size-fits-all solutions. Instead, we work closely with OnBuy sellers to understand their business, audience, and competition. Then, armed with this knowledge, we develop a custom plan that helps you stand out in the crowded marketplace, increase sales, and grow your business.
                <br />
                <br />
                Our OnBuy seller consulting services cover everything from optimizing product listings to developing a pricing strategy and creating a marketing plan that drives traffic and increases conversions. We use data-driven insights to make informed decisions that take your business to the next level.
                <br />
                <br />
                Partnering with us means partnering with a team of professionals committed to your success. We have a proven track record of helping sellers achieve their goals on OnBuy and other marketplaces. Let us help you establish a presence on this exciting new platform and reach new heights of success.
                <br />
                <br />
                OnBuy offers several advantages over Amazon and eBay, such as lower fees, transparent seller ratings, and a more straightforward selling process. With a fixed commission rate of just 5-9%, OnBuy's commission rates are significantly lower than Amazon's, making it a cost-effective option for sellers. Unlike other platforms, OnBuy doesn't prioritize big brands over smaller sellers, so every seller has an equal chance of success. Moreover, OnBuy provides transparent seller ratings, ensuring buyers purchase from reputable sellers.
                <br />
                <br />
                Overall, OnBuy provides a fresh and fair alternative to established platforms, making it an exciting new option for sellers looking to grow their business.
              </p>
            </div>
            <div className="about__img mt-80 mb-30">
              <img src={img} alt="about" />
            </div>
            {/* /.about__img*/}
            <div className="video__btn-wrapper">
              <a
                className="video__btn video__btn-white popup-video"
                href="https://www.youtube.com/watch?v=nrJtHemSPW4"
              >
                <div className="video__player">
                  <i className="fa fa-play" />
                </div>
                <span className="video__btn-title">
                  Watch Our Presentation!
                </span>
              </a>
            </div>
            {/* /.video__btn-wrapper*/}
          </div>
          {/* /.col-xl-6 */}
          <SideBarAccordion />
          {/* /.col-xl-5 */}
        </div>
        {/* /.row */}
      </div>
      {/* /.container */}
    </section>
  );
}

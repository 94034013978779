/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import logo from './assets/DigitalMarketing.jpg';
import PriceTableModal from "../../../../components/Modal/PriceTableModal";



export default function DigitalMarketingSub() {
    return (
        <>
            <section className="about-layout4 pt-80 pb-0" id="digitalMarketingSub">
                <h2 className="heading__title text-center mb-20 text-uppercase">
                    Mobile App Development
                </h2>
                <h3 className="heading__subtitle_para mb-20">
                    Are you looking to take your business to the next level? Look no further than our comprehensive digital marketing services! Our team of experts is here to help you achieve your marketing goals and grow your business.
                </h3>
                <div className="container">
                    <div className="row flex-lg-row">
                        {/* <!-- First column for image --> */}
                        <div className="col-lg-6 mb-10">
                            <div className="about__img">
                                <img src={logo} alt="Digital Marketing" />
                            </div>

                        </div>

                        {/* <!-- Second column for content --> */}
                        <div className="col-lg-6 mt-10">
                            <div className="heading mb-40">
                                <div className="d-flex align-items-center">
                                    <div className="divider divider-primary mr-30"></div>
                                    <h2 className="heading__subtitle mb-0"> We understand that</h2>
                                </div>
                                <h3 className="heading__subtitle mb-10">
                                    businesses must have a strong online presence in today's digital age. That's why we offer various digital marketing services to help you effectively reach your target audience. From search engine optimization to social media marketing, we use a variety of techniques to ensure your business is visible and engaging online.
                                </h3>


                                <p>
                                    Our digital marketing services are designed to help businesses of all sizes find growth. Whether you're a start-up or an established business, we can create a tailored solution that meets your specific needs. Our team works closely with clients to understand their business and objectives, allowing us to create custom marketing strategies that deliver results.
                                </p>
                                <p>
                                    We use the latest tools and technologies to track and measure our clients' digital marketing success. This allows us to provide ongoing support and recommendations to help them stay ahead of the competition. In addition, we take a data-driven approach to digital marketing, ensuring our client's marketing efforts are optimized for maximum results.
                                </p>

                                <p>
                                    Partnering with us for your digital marketing needs means working with a team of experts dedicated to your success. We take the time to understand your business and objectives, creating tailored solutions that deliver results.

                                    Contact us today to learn how we can help your business grow through effective digital marketing.

                                </p>


                            </div>
                            <div>
                                <button className="pricing__footer">
                                    <PriceTableModal />
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    );
}
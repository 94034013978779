/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import logo from './assets/socialMedia.jpg';
import PriceTableModal from "../../../../components/Modal/PriceTableModal";


export default function SocialMediaSub() {
    return (
        <>
            <section className="about-layout4 pt-80 pb-0" id="socialMediaSub">
                <h2 className="heading__title text-center mb-20 text-uppercase">
                    Social Media
                </h2>
                <h3 className="heading__subtitle_para mb-20">
                    Our social media design service is the key to unlocking <br />  the full potential of social media platforms for businesses. We know how important it is <br /> to have a strong online presence and connect with customers personally.
                </h3>
                <div className="container">
                    <div className="row flex-lg-row flex-sm-column-reverse">
                        {/* <!-- First column for image --> */}
                        <div className="col-lg-6 mb-10">
                            <div className="about__img">
                                <img src={logo} alt="socialMedia" />
                            </div>

                        </div>


                        {/* <!-- Second column for content --> */}
                        <div className="col-lg-6">
                            <div className="heading mb-40">
                                <div className="d-flex align-items-center">
                                    <div className="divider divider-primary mr-30"></div>
                                    <h2 className="heading__subtitle mb-0">
                                        That's why we offer a range
                                    </h2>
                                </div>
                                <h3 className="heading__subtitle mb-10">
                                    of social media design services that can help businesses stand out, engage their audience, and boost their online presence.Our team of social media experts works with businesses to develop custom social media strategies that target their audience and align with their brand values. We design eye-catching social media graphics, animated videos, and other multimedia content that captures your followers' attention and helps promote your brand.
                                </h3>

                                <p>
                                    We also optimize content for social media platforms to ensure businesses get the most out of their social media presence.
                                </p>

                                <p>
                                    With our social media design service, businesses can enjoy a robust online presence, increased engagement, and higher conversions. We understand the importance of staying on top of the latest social media trends and ensuring businesses stay ahead of the competition. That's why we're always exploring new ideas and implementing innovative strategies to help companies to succeed on social media.
                                </p>

                            </div>
                            <div>
                                <button className="pricing__footer">
                                    <PriceTableModal />
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    );
}

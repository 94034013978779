import React, { useState, useEffect } from "react";
import logoL from "../../assets/images/logo/logo-dark-big.png";
import logoDark from "../../assets/images/logo/footer-logo.png";
import { Link } from "react-router-dom";
import CommonModal from "../Modal/CommonModal";
import ContactForm from "../../pages/ContactUs/ContactForm";
import HubspotFormV1 from "../from";

export default function Headers({ Class }) {
  const [scroll, setScroll] = useState(false);
  const [toggleIsMenu, settoggleIsMenu] = useState(false);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  useEffect(() => {
    const handleScroll = (event) => {
      if (windowSize.innerWidth >= 992) {
        setScroll(window.scrollY > 50);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  const onMenuClick = () => {
    settoggleIsMenu(!toggleIsMenu);
  };

  const [show, setShow] = useState(false);
  const handelControlQuote = () => {
    setShow((pre) => !pre);
  };
  return (
    <header className="header header-transparent">
      <nav
        className={
          scroll
            ? "navbar navbar-expand-lg sticky-navbar is-sticky"
            : `${Class} navbar navbar-expand-lg sticky-navbar`
        }
      >
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img src={logoL} className="logo-light" alt="logo" />
            <img src={logoDark} className="logo-dark" alt="logo" />
          </Link>
          <button
            onClick={() => onMenuClick()}
            className="navbar-toggler"
            type="button"
          >
            <span className="menu-lines">
              <span />
            </span>
          </button>
          <div
            className={
              toggleIsMenu
                ? "collapse navbar-collapse"
                : "collapse navbar-collapse menu-opened"
            }
            id="mainNavigation"
          >
            <ul className="navbar-nav ml-auto">
              <li className="nav__item  has-dropdown">
                <Link
                  to="/"
                  data-toggle="dropdown"
                  className="nav__item-link active"
                >
                  Home
                </Link>

                {/* /.dropdown-menu */}
              </li>
              {/* /.nav-item */}
              <li className="nav__item  has-dropdown">
                <p
                  data-toggle="dropdown"
                  className="dropdown-toggle nav__item-link"
                >
                  Company
                </p>
                <ul className="dropdown-menu dropdown-menu-1">
                  <li className="nav__item">
                    <Link to="/about-us" className="nav__item-link">
                      About Us
                    </Link>
                  </li>
                  {/* /.nav-item */}
                  <li className="nav__item">
                    <Link to="/why-us" className="nav__item-link">
                      Why Choose Us
                    </Link>
                  </li>
                  {/* /.nav-item */}
                  <li className="nav__item">
                    <Link to="/leadership-team" className="nav__item-link">
                      Leadership Team
                    </Link>
                  </li>
                  {/* /.nav-item */}
                  {/* <li className="nav__item">
                    <Link to="/awards" className="nav__item-link">
                      Award &amp; Recognition
                    </Link>
                  </li> */}
                </ul>
                {/* /.dropdown-menu */}
              </li>

              {/* /.nav-item */}
              <li className="nav__item  has-dropdown">
                <a
                  data-toggle="dropdown"
                  className="dropdown-toggle nav__item-link"
                >
                  Services
                </a>
                <ul className="dropdown-menu dropdown-menu-2">
                  {/* /.nav-item */}
                  <li className="nav__item">
                    <Link
                      data-toggle="dropdown"
                      className="dropdown-toggle nav__item-link"
                      to="/Design-Services"
                    >
                      Design Services
                    </Link>
                    <ul className="dropdown-menu d-left">
                      <li className="nav__item">
                        <Link to="/website-design" className="nav__item-link">
                          Website design
                        </Link>
                      </li>
                      <li className="nav__item">
                        <Link to="/Logo-Branding" className="nav__item-link">
                          Logo & Branding
                        </Link>
                      </li>
                      <li className="nav__item">
                        <Link to="/advertising" className="nav__item-link">
                          Advertising
                        </Link>
                      </li>
                      <li className="nav__item">
                        <Link to="/Social-media" className="nav__item-link">
                          Social Media
                        </Link>
                      </li>
                      <li className="nav__item">
                        <Link to="/packaging" className="nav__item-link">
                          Packaging
                        </Link>
                      </li>
                      <li className="nav__item">
                        <Link to="/Graphic-Design" className="nav__item-link">
                          Graphic design
                        </Link>
                      </li>
                      <li className="nav__item">
                        <Link
                          to="/Copy-writing-Services"
                          className="nav__item-link"
                        >
                          Copywriting Services
                        </Link>
                      </li>
                    </ul>
                  </li>
                  {/* /.nav-item */}
                  <li className="nav__item">
                    <Link
                      data-toggle="dropdown"
                      className="dropdown-toggle nav__item-link"
                      to="/IT-Web-Services"
                    >
                      IT & Web Services
                    </Link>
                    <ul className="dropdown-menu d-left">
                      <li className="nav__item">
                        <Link to="/Website-Building" className="nav__item-link">
                          Website Building
                        </Link>
                      </li>
                      <li className="nav__item">
                        <Link to="/IT-Support" className="nav__item-link">
                          IT Support
                        </Link>
                      </li>
                      <li className="nav__item">
                        <Link to="/web-app" className="nav__item-link">
                          Web App Development
                        </Link>
                      </li>
                      <li className="nav__item">
                        <Link to="/Mobile-App " className="nav__item-link">
                          Mobile App Development
                        </Link>
                      </li>
                      <li className="nav__item">
                        <Link to="/AI-ML" className="nav__item-link">
                          AI & ML
                        </Link>
                      </li>
                      <li className="nav__item">
                        <Link
                          to="/Digital-Marketing"
                          className="nav__item-link"
                        >
                          Digital Marketing
                        </Link>
                      </li>
                    </ul>
                  </li>
                  {/* /.nav-item */}
                  <li className="nav__item">
                    <Link
                      data-toggle="dropdown"
                      className="dropdown-toggle nav__item-link"
                      to="/Selling-Platforms"
                    >
                      Selling Platforms
                    </Link>
                    <ul className="dropdown-menu d-left">
                      <li className="nav__item">
                        <Link to="/Amazon" className="nav__item-link">
                          Amazon
                        </Link>
                      </li>
                      <li className="nav__item">
                        <Link to="/eBay" className="nav__item-link">
                          eBay
                        </Link>
                      </li>
                      <li className="nav__item">
                        <Link to="/OnBuy" className="nav__item-link">
                          OnBuy
                        </Link>
                      </li>
                      <li className="nav__item">
                        <Link
                          to="/Social-Marketing "
                          className="nav__item-link"
                        >
                          Social Marketing
                        </Link>
                      </li>
                      <li className="nav__item">
                        <Link to="/E-Campaigners" className="nav__item-link">
                          e-Campaigners
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="nav__item">
                    <Link
                      data-toggle="dropdown"
                      className="dropdown-toggle nav__item-link"
                      to="/Sales-Marketing"
                    >
                      Sales & Marketing
                    </Link>
                    <ul className="dropdown-menu d-left">
                      <li className="nav__item">
                        <Link to="/Data-Discovery" className="nav__item-link">
                          Data Discovery
                        </Link>
                      </li>
                      <li className="nav__item">
                        <Link to="/Product-Sourcing" className="nav__item-link">
                          Product Sourcing
                        </Link>
                      </li>
                      <li className="nav__item">
                        <Link
                          to="/Business-Opportunitie"
                          className="nav__item-link"
                        >
                          Business Opportunities
                        </Link>
                      </li>
                      <li className="nav__item">
                        <Link
                          to="/LinkedIn-Services"
                          className="nav__item-link"
                        >
                          LinkedIn Services
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="nav__item">
                    <Link
                      data-toggle="dropdown"
                      className="dropdown-toggle nav__item-link"
                      to="/Network-Partners"
                    >
                      Network Partners
                    </Link>
                    <ul className="dropdown-menu d-left">
                      <li className="nav__item">
                        <Link to="/Sales-Agents" className="nav__item-link">
                          Sales Agents
                        </Link>
                      </li>
                      <li className="nav__item">
                        <Link to="/Accountants" className="nav__item-link">
                          Accountants
                        </Link>
                      </li>
                      <li className="nav__item">
                        <Link to="/Solicitorsn" className="nav__item-link">
                          Solicitors
                        </Link>
                      </li>
                      <li className="nav__item">
                        <Link
                          to="/Telecommunications"
                          className="nav__item-link"
                        >
                          Telecommunications
                        </Link>
                      </li>
                      <li className="nav__item">
                        <Link to="/Video-Production" className="nav__item-link">
                          Video Production
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="nav__item">
                    <Link
                      data-toggle="dropdown"
                      className="dropdown-toggle nav__item-link"
                      to="/Recruitment"
                    >
                      Recruitment Services
                    </Link>
                    <ul className="dropdown-menu d-left">
                      <li className="nav__item">
                        <Link to="/Salespeople" className="nav__item-link">
                          Salespeople
                        </Link>
                      </li>
                      <li className="nav__item">
                        <Link
                          to="/Technology-Industry"
                          className="nav__item-link"
                        >
                          Technology Industry
                        </Link>
                      </li>
                      <li className="nav__item">
                        <Link to="/Education" className="nav__item-link">
                          Education
                        </Link>
                      </li>
                      <li className="nav__item">
                        <Link
                          to="/Medical-Healthcare"
                          className="nav__item-link"
                        >
                          Medical & Healthcare
                        </Link>
                      </li>
                      <li className="nav__item">
                        <Link to="/Hospitality" className="nav__item-link">
                          Hospitality
                        </Link>
                      </li>
                      <li className="nav__item">
                        <Link to="/Finance" className="nav__item-link">
                          Finance
                        </Link>
                      </li>
                      <li className="nav__item">
                        <Link
                          to="/Recruitment-services"
                          className="nav__item-link"
                        >
                          Recruitment services
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
                {/* /.dropdown-menu */}
              </li>

              <li className="nav__item">
                <Link to="/contact-us" className="nav__item-link">
                  Contacts
                </Link>
              </li>
              {/* /.nav-item */}
            </ul>
            {/* /.navbar-nav */}
          </div>
          {/* /.navbar-collapse */}
          <ul className="navbar-actions list-unstyled mb-0 d-flex align-items-center">
            <li className="d-none d-xl-block">
              <Link
                to=""
                onClick={handelControlQuote}
                className="btn action__btn-contact"
              >
                Request A Quote
              </Link>
            </li>
            <li>
              <button className="action__btn action__btn-login open-login-popup">
                <i className="icon-user" />
                <span>Login</span>
              </button>
            </li>
          </ul>
          {/* /.navbar-actions */}
        </div>
        {/* /.container */}
      </nav>
      {/* /.navabr */}
      {show ? (
        <CommonModal handelClose={handelControlQuote}>
          <ContactForm />
          <HubspotFormV1 />
        </CommonModal>
      ) : (
        ""
      )}
    </header>
  );
}

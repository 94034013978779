import HubspotForm from 'react-hubspot-form';

const HubspotFormV1 = () => {
    
    return (
        <HubspotForm
            portalId='143174648'
            formId='7ee3a6c0-6ff6-4f6a-9047-0994115b754c'
            onSubmit={() => console.log('Submit!')}
            onReady={(form) => console.log('Form ready!')}
            loading={<div>Loading...</div>}
        />
    )
}

export default HubspotFormV1;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import logo from './assets/amazon.jpg';
import PriceTableModal from "../../../../components/Modal/PriceTableModal";

export default function AmazonSub() {
    return (
        <>
            <section className="about-layout4 pt-80 pb-0" id="AmazonSub">
                <h2 className="heading__title text-center mb-20 text-uppercase">
                    Amazon
                </h2>
                <h3 className="heading__subtitle_para mb-20">
                    Are you looking to improve your Amazon sales Optimize your listings, and increase your profits? Look no further than BuyBox Savvy! Our team of Amazon experts provides comprehensive consulting and marketing services designed to help sellers succeed on the world's largest online marketplace.
                </h3>
                <div className="container">
                    <div className="row flex-lg-row flex-sm-column-reverse">
                        {/* <!-- First column for image --> */}
                        <div className="col-lg-6">
                            <div className="heading mb-40">
                                <div className="d-flex align-items-center">
                                    <div className="divider divider-primary mr-30"></div>
                                    <h2 className="heading__subtitle mb-0">With years of experience</h2>
                                </div>
                                <h3 className="heading__subtitle mb-10">
                                    With years of experience in the industry, we understand what it takes to succeed on Amazon, from optimizing your product listings to leveraging advertising tools to drive sales. Our services include everything from keyword research and listing optimization to sponsored ads management and brand registry management.
                                </h3>

                                <p>
                                    At BuyBox Savvy, we take a holistic approach to Amazon consulting, working with you to understand your business goals and target audience. We provide tailored solutions that help you stand out from the competition, drive traffic to your listings, and increase your sales and revenue.
                                </p>

                                <p>
                                    Our team of experts stays up to date with the latest trends and best practices in Amazon selling, ensuring that our clients receive the most effective strategies and techniques to optimize their listings and grow their businesses. With our help, you can save time and money while achieving
                                </p>

                                <p>
                                    Partnering with BuyBox Savvy means working with a team of dedicated professionals who are committed to your success. Let us help you achieve your goals on Amazon and take your business to the next level. Contact us today to learn more about our services and how we can help you succeed.
                                </p>

                            </div>
                            <div>
                                <button className="pricing__footer">
                                    <PriceTableModal />
                                </button>
                            </div>
                        </div>
                        {/* <!-- Second column for content --> */}
                        <div className="col-lg-6">
                            <div className="about__img">
                                <img src={logo} alt="amazon" />
                            </div>

                        </div>

                    </div>
                </div>
            </section>
        </>
    );
}

import React from 'react';
// import BrochureImg from '../../assets/images/Brochure/web-design.svg'
import WebDesignSub from './AllSubMenu/WebsiteDesign/WebDesignSub';
import LogoAndBrandingSub from './AllSubMenu/LogoAndBranding/LogoAndBrandingSub';
import AdvertisingAndMarketingSub from './AllSubMenu/AdvertisingAndMarketingSub/AdvertisingAndMarketingSub';
import SocialMediaSub from './AllSubMenu/SocialMediaSub/SocialMediaSub';
import PackagingSub from './AllSubMenu/PackagingSub/PackagingSub';
import GraphicDesignSub from './AllSubMenu/GraphicDesignSub/GraphicDesignSub';
import CopywritingSub from './AllSubMenu/CopywritingSub/CopywritingSub';
import SingleServiceItems from './SingleServiceItems';

const BrochureSection = () => {
    return (
        <section className="services-layout1 bg-gray pt-130 pb-90">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-6 offset-lg-3">
                        <div className="heading text-center mb-50">
                            <h2 className="heading__subtitle">
                                Business Savvy UK
                            </h2>
                            <h3 className="heading__title">
                                Design Service Single Category items. Selected your affordable Service.
                            </h3>
                        </div>
                        {/* /.heading */}
                    </div>
                    {/* /.col-lg-8 */}
                </div>
                <>
                    <SingleServiceItems />
                </>
                {/* web Design Sub */}
                <WebDesignSub />
                {/* web Design Sub Card */}
                {/* <Pricing /> */}
                {/* Logo and Branding sub */}
                <LogoAndBrandingSub />
                {/* Logo and Branding sub Card */}
                {/* <Pricing /> */}
                {/* Advertising And Marketing sub */}
                <AdvertisingAndMarketingSub />
                {/* Advertising And Marketing sub Card */}
                {/* <Pricing /> */}
                {/* Social Media sub */}
                <SocialMediaSub />
                {/* Social Media sub Card */}
                {/* <Pricing /> */}
                {/* Packaging sub */}
                <PackagingSub />
                {/* Packaging sub Card */}
                {/* <Pricing /> */}
                {/* Graphic Design sub */}
                <GraphicDesignSub />
                {/* Graphic Design sub Card */}
                {/* <Pricing /> */}
                {/* Copywriting sub */}
                <CopywritingSub />
                {/* Copywriting sub Card */}
                {/* <Pricing /> */}

            </div>
            {/* /.container */}
        </section>
    );
};

export default BrochureSection;
import React from "react";
import logoDark from "../../assets/images/logo/footer-logo.png";
import { Link } from "react-router-dom";


export default function Footer() {
  return (
    <>
      <footer className="footer footer-light">
        <div className="footer-primary">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-sm-12 col-md-12 col-lg-3 footer-widget footer-widget-about ">
                <div
                  className="footer-widget__content"
                  style={{ marginTop: "-30px" }}
                >
                <Link to="/">
                  <img src={logoDark} alt="logo" className="mb-30" />
                  </Link>
                  <p>Privacy, Terms & Conditions</p>
                  <div className="contact-info-box">
                    {/* <h4 className="contact__info-box-title">London Office</h4> */}
                    <ul className="contact__info-list list-unstyled">
                      <li>
                        Email:{" "}
                        <Link to="mailto:info@business-savvy-uk.co.uk">
                          info@business-savvy-uk.co.uk
                        </Link>
                      </li>
                      <li>
                        Business Savvy UK Ltd Blackburn Road, Houghton Regis,
                        Dunstable LU5 5BQ,UK
                      </li>
                      <li>
                        Phone:{" "}
                        <Link to="tel:+441582551550">
                          +44 (0) 1582 551 550
                        </Link>
                      </li>
                      <li>Hours: Mon-Fri: 8am – 7pm</li>
                    </ul>
                    {/* /.contact__info-list */}
                  </div>
                  {/* /.contact-info-box */}
                </div>
                {/* /.footer-widget__content */}
              </div>
              {/* /.col-xl-3 */}
              <div className="col-sm-6 col-md-4 col-lg-2 footer-widget footer-widget-nav">
                <h6 className="footer-widget__title">Company</h6>
                <div className="footer-widget__content">
                  <nav>
                    <ul className="list-unstyled">
                      <li>
                        <Link to="/about-us">About Us</Link>
                      </li>
                      <li>
                        <Link to="/why-us">Why Chose Us</Link>
                      </li>

                      <li>
                        <Link to="/leadership-team">Leadership Team</Link>
                      </li>

                    </ul>
                  </nav>
                </div>
                {/* /.footer-widget__content */}
              </div>
              {/* /.col-lg-2 */}

              {/* /.col-lg-2 */}
              <div className="col-sm-6 col-md-4 col-lg-2 footer-widget footer-widget-nav">
                <h6 className="footer-widget__title">Services</h6>
                <div className="footer-widget__content">
                  <nav>
                    <ul className="list-unstyled">
                      <li>
                        <Link to="/Design-Services">Design-Services</Link>
                      </li>
                      <li>
                        <Link to="/IT-Web-Services">IT & Web Services</Link>
                      </li>
                      <li>
                        <Link to="/Selling-Platforms">Selling Platforms</Link>
                      </li>
                      <li>
                        <Link to="/Sales-Marketing">Sales & Marketing</Link>
                      </li>
                      <li>
                        <Link to="/Network-Partners">Network & Partners</Link>
                      </li>
                      <li>
                        <Link to="/Recruitment">Recruitment services</Link>
                      </li>
                    </ul>
                  </nav>
                </div>
                {/* /.footer-widget__content */}
              </div>

              <div className="col-sm-6 col-md-4 col-lg-2 footer-widget footer-widget-nav">
                <h6 className="footer-widget__title">Terms</h6>
                <div className="footer-widget__content">
                  <nav>
                    <ul className="list-unstyled">
                      <li>
                        <Link to="/trams">Terms of Service</Link>
                      </li>
                      <li>
                        <Link to="/policy">Privacy Policy</Link>
                      </li>
                      <li>
                        <Link to="/refund">Refund Policy</Link>
                      </li>
                      <li>
                        <Link to="/contact-us">Contact Us</Link>
                      </li>
                      <li>
                        <Link to="/request-quote">Site map</Link>
                      </li>
                    </ul>
                  </nav>
                </div>
                {/* /.footer-widget__content */}
              </div>
              {/* /.col-lg-3 */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container */}
        </div>
        {/* /.footer-primary */}
        <div className="footer-secondary">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-sm-12 col-md-5 col-lg-5">
                <div className="footer__copyrights">
                  <span className="fz-13">
                    © 2023 Business Savvy UK Ltd , All Rights Reserved. <br />{" "}
                    Powerd by{" "}
                  </span>
                  <Link
                    className="fz-14 color-primary padding"
                    to="http://aljaami.co.uk"
                  >
                    Al Jaami Technologies
                  </Link>
                </div>
              </div>
              {/* /.col-lg-6 */}
              <div className="col-sm-12 col-md-2 col-lg-2 text-center">
                <button id="scrollTopBtn" className="my-2">
                  <i className="icon-arrow-up-2" />
                </button>
              </div>
              {/* /.col-lg-2 */}
              <div className="col-sm-12 col-md-5 col-lg-5 d-flex flex-wrap justify-content-end align-items-center">
                <ul className="social-icons list-unstyled mb-0 mr-30">
                  <li>
                    <Link to="#">
                      <i className="fab fa-facebook-f" />
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="fab fa-instagram" />
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="fab fa-twitter" />
                    </Link>
                  </li>
                </ul>
                {/* /.social-icons */}
                <div>
                  <Link to="#" className="btn btn__white btn__download mr-20">
                    <i className="fab fa-apple" />
                    <span>App Store</span>
                  </Link>
                  <Link to="#" className="btn btn__white btn__download">
                    <i className="fab fa-google-play" />
                    <span>Google Play</span>
                  </Link>
                </div>
              </div>
              {/* /.col-lg-6 */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container */}
        </div>
        {/* /.footer-secondary */}
      </footer>
      {/* /.Footer */}
      <div className="login-popup" id="login-popup">
        <div className="login-popup-wrapper">
          <form className="login-popup__form">
            <h3 className="login-popup__title">Login!</h3>
            <p className="login-popup__desc">
              Connect, organize and get things done to keep your IT business
              safe.
            </p>
            <div className="form-group mb-20">
              <i className="icon-mail input-icon" />
              <input type="text" className="form-control" placeholder="Email" />
            </div>
            <div className="form-group mb-20">
              <i className="icon-lock input-icon" />
              <input
                type="password"
                className="form-control"
                placeholder="********"
              />
            </div>
            <div className="d-flex align-items-center justify-content-between mb-20">
              <div className="custom-control custom-checkbox d-flex align-items-center">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="customCheck1"
                />
                <label className="custom-control-label" htmlFor="customCheck1">
                  Remember Me!
                </label>
              </div>
              <Link to="#" className="fz-14 font-weight-bold color-secondary">
                Forget Password?
              </Link>
            </div>
            <button
              type="submit"
              className="btn btn__primary btn__block btn__xl"
            >
              Login
            </button>
          </form>
          <div className="d-flex justify-content-center align-items-center mt-20">
            <span className="color-white fz-14 font-weight-bold">
              Don’t Have An Account?
            </span>
            <button
              id="go-register"
              className="go-register fz-14 font-weight-bold"
            >
              <span>Register Here</span>
              <i className="icon-arrow-right" />
            </button>
          </div>
        </div>
      </div>
      {/* /.login-popup */}
      <div className="login-popup" id="register-popup">
        <div className="login-popup-wrapper">
          <form className="login-popup__form">
            <h3 className="login-popup__title">Register!</h3>
            <p className="login-popup__desc">
              Connect, organize and get things done to keep your IT business
              safe.
            </p>
            <div className="form-group mb-20">
              <i className="icon-mail input-icon" />
              <input type="text" className="form-control" placeholder="Email" />
            </div>
            <div className="form-group mb-20">
              <i className="icon-lock input-icon" />
              <input
                type="password"
                className="form-control"
                placeholder="********"
              />
            </div>
            <div className="d-flex align-items-center justify-content-between mb-20">
              <div className="custom-control custom-checkbox d-flex align-items-center">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="customCheck2"
                />
                <label className="custom-control-label" htmlFor="customCheck2">
                  I read &amp; agree to{" "}
                  <Link to="#">Terms &amp; Conditions</Link>
                </label>
              </div>
            </div>
            <button
              type="submit"
              className="btn btn__primary btn__block btn__xl"
            >
              Register
            </button>
          </form>
          <div className="d-flex justify-content-center align-items-center mt-20">
            <span className="color-white fz-14 font-weight-bold">
              Have An Account?
            </span>
            <button id="go-login" className="go-login fz-14 font-weight-bold">
              <span>Login Here</span>
              <i className="icon-arrow-right" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

import React, { useEffect, useState } from "react";
import Headers from "../../components/header/Headers";
import ContactLayoutOne from "./contactSections/ContactLayoutOne";
import ContactLayoutTwo from "./contactSections/ContactLayoutTwo";
import Footer from "../../components/footer/Footer";
import ContactUsSinglePageTitle from "./ContactUsSinglePageTitle";
import DarkFooter from "../../components/footer/DarkFooter";

export default function ContactUs() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <Headers />
      <ContactUsSinglePageTitle />
      <ContactLayoutOne />
      <ContactLayoutTwo />
      <section class="google-map py-0">
        <iframe width="100%" height="636" id="gmap_canvas" src="https://maps.google.com/maps?q=Blackburn%20Road,%20Houghton%20Regis,%20Dunstable%20LU5%205BQ&t=&z=15&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
      </section>
      <DarkFooter />
    </>
  );
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import logo from './assets/technology.jpg';
import PriceTableModal from "../../../../components/Modal/PriceTableModal";


export default function TechnologyIndustrySub() {
    return (
        <>
            <section className="about-layout4 pt-80 pb-0" id="TechnologyIndustrySub">
                <h2 className="heading__title text-center mb-20 text-uppercase">
                    Technology Industry
                </h2>

                <h3 className="heading__subtitle_para mb-20">
                    At Business Savvy UK, we understand the importance of having skilled professionals in the IT and technology industry to help businesses stay competitive in an ever-changing landscape. That's why we offer various services to help companies to find the right talent for their needs.
                </h3>
                <div className="container">
                    <div className="row flex-lg-row">
                        {/* <!-- First column for image --> */}
                        <div className="col-lg-6 mb-10">
                            <div className="about__img">
                                <img src={logo} alt="Technology" />
                            </div>

                        </div>

                        {/* <!-- Second column for content --> */}
                        <div className="col-lg-6 mt-10">
                            <div className="heading mb-40">
                                <div className="d-flex align-items-center">
                                    <div className="divider divider-primary mr-30"></div>
                                    <h2 className="heading__subtitle mb-0">
                                        Whether you need
                                    </h2>
                                </div>
                                <h3 className="heading__subtitle mb-10">
                                    software developers, hardware engineers, or other IT professionals, we have a network of skilled individuals who can help take your business to the next level. Our team works closely with clients to understand their needs and find the best candidate for the job.
                                </h3>

                                <p>
                                    We understand the importance of having skilled professionals in the IT and technology industry to help businesses stay competitive. That's why we only supply candidates with relevant experience and qualifications to perform their roles to the highest standard.
                                </p>
                                <p>
                                    We take the time to ensure that each candidate we supply is the best fit for the job and the company culture. Our recruitment experts use the latest sourcing and screening methods to identify the most suitable candidates so that you can focus on your business.
                                </p>
                                <p>
                                    Partnering with us for your recruitment needs means working with a team of professionals who are dedicated to your success. We aim to help businesses stay ahead of the curve by providing them access to a range of skilled professionals in the IT and technology industry.
                                </p>
                            </div>
                            <div>
                                <button className="pricing__footer">
                                    <PriceTableModal />
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    );
}
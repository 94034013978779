import React from "react";
import img from "../../../../assets/images/about/7.jpg";
import img2 from "../../../../assets/images/about/3.jpg";
import img3 from "../../../../assets/images/about/singnture.png";
import SideBarAccordion from "../../../../components/SideBarAccordion/SideBarAccordion";

export default function SolutionsLayoutThree() {
  return (
    <section id="about" className="about-layout2 animated-Progressbars">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
            <div className="heading mb-40">
              <div className="d-flex align-items-center mb-20">
                <div className="divider divider-primary mr-30" />
                <h2 className="heading__subtitle mb-0">
                  Curate And Efficient Solution Of Copywriting Services
                </h2>
              </div>
              <h3 className="heading__title mb-40">
                Our experienced teams of copywriters are skilled.</h3>
            </div>
          </div>
          {/* /.col-xl-5 */}
        </div>
        {/* /.row */}
        <div className="row about__Text">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
            <div className="offset-xl-2 position-relative">
              <i className="icon-quote" />
              <p>In crafting custom content that speaks directly to your target audience and showcases your business's unique value proposition.
                <br />
                <br />
                We understand that different businesses have different needs, so we offer a range of copywriting services to meet your specific requirements. Whether you need website copy, marketing materials, or product descriptions for online selling platforms like Amazon and eBay, our team has the expertise to deliver exceptional results.
                <br />
                <br />
                We take the time to understand your business, audience, and marketing objectives, enabling us to create compelling, persuasive copy that drives results. Our services include captivating crafting headlines and taglines, writing engaging website copy, creating compelling marketing materials, and crafting product descriptions that sell.
                <br />
                <br />
                Moreover, our copywriting services are not limited to a single language. Our team of professionals can create a copy in multiple languages, helping you reach a wider audience and grow your business globally.
                <br />
                <br />
                Partnering with us for your copywriting needs means working with a team of professionals dedicated to your success. We take the time to understand your business, audience, and goals to deliver content that drives traffic and converts leads into sales.
                <br />
                <br />
                Don't settle for average copywriting services that fail to deliver the desired results. Instead, contact us today to learn how we can help you achieve your business goals through effective and engaging copywriting.
              </p>
            </div>
            <div className="about__img mt-80 mb-30">
              <img src={img} alt="about" />
            </div>
            {/* /.about__img*/}
            <div className="video__btn-wrapper">
              <a
                className="video__btn video__btn-white popup-video"
                href="https://www.youtube.com/watch?v=nrJtHemSPW4"
              >
                <div className="video__player">
                  <i className="fa fa-play" />
                </div>
                <span className="video__btn-title">
                  Watch Our Presentation!
                </span>
              </a>
            </div>
            {/* /.video__btn-wrapper*/}
          </div>
          {/* /.col-xl-6 */}
          <SideBarAccordion />
          {/* /.col-xl-5 */}
        </div>
        {/* /.row */}
      </div>
      {/* /.container */}
    </section>
  );
}

import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

export default function SecondaryNav() {
  // const [scroll, setScroll] = useState(false);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  // useEffect(() => {
  //   const handleScroll = (event) => {
  //     if (windowSize.innerWidth >= 992) {
  //       setScroll(window.scrollY > 100);
  //     }
  //   };
  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  return (
    <section
      className=
        
          "secondary-nav secondary-nav-sticky sticky-top py-0"
         
      
    >
      <div className="container">
        <div className="row">
          <div className="col-12">
            <nav className="nav nav-tabs justify-content-center">
              <NavLink to="/about-us" className="nav__link">
                About Us
              </NavLink>
              <NavLink to="/why-us" className="nav__link">
                Why Choose Us
              </NavLink>
              <NavLink to="/leadership-team" className="nav__link">
                Leadership Team
              </NavLink>
            </nav>
          </div>
          {/* /.col-12 */}
        </div>
        {/* /.row */}
      </div>
      {/* /.container */}
    </section>
  );
}

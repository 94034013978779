/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import logo from './assets/WebAppDevelopment.jpg';
import PriceTableModal from "../../../../components/Modal/PriceTableModal";



export default function WebAppDevelopmentSub() {
    return (
        <>
            <section className="about-layout4 pt-80 pb-0" id="webAppDevelopmentSub">
                <h2 className="heading__title text-center mb-20 text-uppercase">
                    Web App Development
                </h2>
                <h3 className="heading__subtitle_para mb-20">
                    At Business Savvy UK, we know that having A solid online presence is crucial for businesses of all sizes. That's why we offer a comprehensive website-building service that is tailored to meet the specific needs of each of our clients
                </h3>
                <div className="container">
                    <div className="row flex-lg-row flex-sm-column-reverse">
                        {/* <!-- First column for image --> */}
                        <div className="col-lg-6">
                            <div className="heading mb-40">
                                <div className="d-flex align-items-center">
                                    <div className="divider divider-primary mr-30"></div>
                                    <h2 className="heading__subtitle mb-0">Design, Develop, Deliver - Mobile Solutions for Your Business</h2>
                                </div>
                                <h3 className="heading__subtitle mb-10">
                                    At Business Savvy UK, we provide a complete range of custom app development services tailored to your business needs.
                                </h3>
                                <p>
                                    We design, develop, and deliver bespoke mobile applications that are user-friendly, secure, and scalable, ensuring they meet the requirements of modern businesses.
                                </p>
                                <p>
                                    Our app development services will help your business engage with customers, streamline operations, and increase revenue. Contact us today for a free consultation and let us help you build a mobile solution that meets your business needs.
                                </p>

                            </div>
                            <div>
                                <button className="pricing__footer">
                                    <PriceTableModal />
                                </button>
                            </div>
                        </div>
                        {/* <!-- Second column for content --> */}
                        <div className="col-lg-6">
                            <div className="about__img">
                                <img src={logo} alt="WebAppDevelopment" />
                            </div>

                        </div>

                    </div>
                </div>
            </section>
        </>
    );
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import logo from './assets/GraphicDesign.jpg';
import PriceTableModal from "../../../../components/Modal/PriceTableModal";



export default function GraphicDesignSub() {
    return (
        <>
            <section className="about-layout4 pt-80 pb-0" id="graphicDesignSub">
                <h2 className="heading__title text-center mb-20 text-uppercase">
                    Graphic Design
                </h2>
                <h3 className="heading__subtitle_para mb-20">
                    Are you looking to create a professional And eye-catching image for your business? Look no further than our graphic design services.
                </h3>
                <div className="container">
                    <div className="row flex-lg-row flex-sm-column-reverse">
                        {/* <!-- First column for image --> */}
                        <div className="col-lg-6 mb-10">
                            <div className="about__img">
                                <img src={logo} alt="GraphicDesign" />
                            </div>

                        </div>


                        {/* <!-- Second column for content --> */}
                        <div className="col-lg-6">
                            <div className="heading mb-40">
                                <div className="d-flex align-items-center">
                                    <div className="divider divider-primary mr-30"></div>
                                    <h2 className="heading__subtitle mb-0">Our team of experienced</h2>
                                </div>
                                <h3 className="heading__subtitle mb-10">
                                    designers knows precisely what it takes to create designs that stand out and get noticed. From logos and business cards to brochures and other marketing materials, we have the expertise to bring your vision to life.
                                </h3>

                                <p>
                                    We take the time to understand your business and target audience and use the latest design software and techniques to create custom designs that reflect your unique brand. Our graphic design services are tailored to help businesses build a professional image that engages their customers and sets them apart in the market.
                                </p>

                                <p>
                                    Don't settle for generic designs that blend in with the competition. Instead, let us help you create a standout image that showcases your business in the best possible light. Contact us today to learn more about our custom graphic design services.
                                </p>

                            </div>
                            <div>
                                <button className="pricing__footer">
                                    <PriceTableModal />
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    );
}

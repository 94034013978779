import React, { useEffect, useState } from "react";
import Footer from "../../components/footer/Footer";
import Headers from "../../components/header/Headers";
import BlogGrind from "./blogSections/BlogGrind";
import BlogTitlePage from "./blogSections/BlogTitlePage";

export default function OurBlog() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <Headers />
      <BlogTitlePage />
      <BlogGrind />
      <Footer />
    </>
  );
}

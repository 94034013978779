const CommonModal = ({ handelClose, children, className }) => {
  return (
    <>
      <div className="modal__wrapper-1"></div>
      <div className={`quate__modal ${className}`}>
        <svg
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="icon__close"
          onClick={handelClose}
        >
          <g id="Group 27221">
            <rect
              id="Rectangle 802"
              width="30"
              height="30"
              rx="7"
              fill="#E9EAEE"
            />
            <g id="Group 2940">
              <rect
                id="Rectangle 521"
                width="1.2759"
                height="12.759"
                rx="0.637949"
                transform="matrix(0.707115 0.707099 -0.707115 0.707099 19.0229 10)"
                fill="#253053"
              />
              <rect
                id="Rectangle 522"
                width="1.38222"
                height="12.759"
                rx="0.691112"
                transform="matrix(0.707115 -0.707099 0.707115 0.707099 10 10.9785)"
                fill="#253053"
              />
            </g>
          </g>
        </svg>
        <div className="HubspotForm-from-controller">{children}</div>
      </div>
    </>
  );
};

export default CommonModal;

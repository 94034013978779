import React, { useEffect, useState } from "react";
import DarkFooter from "../../components/footer/DarkFooter";
import Headers from "../../components/header/Headers";
import AboutLayoutOne from "../Aboutus/aboutusSections/AboutLayoutOne";
import BannerLayoutTwo from "../Home/homeSections/BannerLayoutTwo";
import SolutionFaq from "../ItSolutionsSinglePage/ItSolutionsSinglePageSections/SolutionFaq";
import RequestQuoteTitle from "./requestQuoteSections/RequestQuoteTitle";

export default function RequestQuote() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <Headers />
      <RequestQuoteTitle/>
      <AboutLayoutOne/>
      <BannerLayoutTwo/>
      {/* <SolutionFaq /> */}
      <DarkFooter />
    </>
  );
}

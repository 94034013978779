/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import logo from './assets/salesAgent.jpg';
import PriceTableModal from "../../../../components/Modal/PriceTableModal";


export default function SalesAgentsSub() {
    return (
        <>
            <section className="about-layout4 pt-80 pb-0" id="SalesAgentsSub">
                <h2 className="heading__title text-center mb-20 text-uppercase">
                    Sales Agents
                </h2>
                <h3 className="heading__subtitle_para mb-20">
                    Partnering with Business Savvy or our trusted company Associates can provide businesses with access to a network of experienced sales agents. Our partners specialize in recruiting and training skilled sales agents to promote and sell a range of products and services. Working with our partners can help businesses expand their reach and increase sales without the expense of hiring an in-house sales team.
                </h3>
                <div className="container">
                    <div className="row flex-lg-row flex-sm-column-reverse">
                        {/* <!-- First column for image --> */}
                        <div className="col-lg-6">
                            <div className="heading mb-40">
                                <div className="d-flex align-items-center">
                                    <div className="divider divider-primary mr-30"></div>
                                    <h2 className="heading__subtitle mb-0">Our network of sales agents</h2>
                                </div>
                                <h3 className="heading__subtitle mb-10">
                                    has expertise in various industries and regions, giving businesses access to a broad range of potential customers. By leveraging our partners' connections and expertise, businesses can quickly and efficiently establish a sales presence in new markets, expand their customer base, and increase revenue.
                                </h3>

                                <p>
                                    Working with our sales agent partners provides businesses with several key benefits. These include access to experienced sales professionals, reduced costs compared to building an in-house sales team, increased market reach, and the ability to quickly scale sales efforts. Our partners work closely with businesses to understand their specific needs and develop a customized sales strategy that aligns with their goals and budget.
                                </p>

                                <p>
                                    Overall, partnering with Business Savvy or our trusted company associates for sales agent services can be an excellent way for businesses to expand their sales efforts and reach new customers.
                                </p>
                            </div>
                            <div>
                                <button className="pricing__footer">
                                    <PriceTableModal />
                                </button>
                            </div>
                        </div>
                        {/* <!-- Second column for content --> */}
                        <div className="col-lg-6">
                            <div className="about__img">
                                <img src={logo} alt="salesAgent" />
                            </div>

                        </div>

                    </div>
                </div>
            </section>
        </>
    );
}

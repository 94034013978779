import React from 'react';
import SingleServiceItems from './SingleServiceItems';
import AmazonSub from './SellingPlatformsAllSub/AmazonSub/AmazonSub';
import EbaySub from './SellingPlatformsAllSub/EbaySub/EbaySub';
import OnBuySub from './SellingPlatformsAllSub/OnBuySub/OnBuySub';
import SocialMarketingSub from './SellingPlatformsAllSub/SocialMarketingSub/SocialMarketingSub';
import ECampaignersSub from './SellingPlatformsAllSub/e-CampaignersSub/ECampaignersSub';

const BrochureSection = () => {
    return (
        <section className="services-layout1 bg-gray pt-130 pb-90">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-6 offset-lg-3">
                        <div className="heading text-center mb-50">
                            <h2 className="heading__subtitle">
                                Business Savvy UK
                            </h2>
                            <h3 className="heading__title">
                                Selling Platforms Single Category items. Selected your affordable Service.
                            </h3>
                        </div>
                        {/* /.heading */}
                    </div>
                    {/* /.col-lg-8 */}
                </div>
                <>
                    <SingleServiceItems />
                </>
                {/* amazon sub */}
                <AmazonSub />
                {/* amazon card */}
                {/* <Pricing /> */}
                {/* Ebay sub */}
                <EbaySub />
                {/* Ebay card */}
                {/* <Pricing /> */}
                {/* On Buy sub */}
                <OnBuySub />
                {/* On Buy card */}
                {/* <Pricing /> */}
                {/* Social Marketing sub */}
                <SocialMarketingSub />
                {/* Social Marketing card */}
                {/* <Pricing /> */}
                {/* e-Campaigners sub */}
                <ECampaignersSub />
                {/* e-Campaigners card */}
                {/* <Pricing /> */}
            </div>
            {/* /.container */}
        </section>
    );
};

export default BrochureSection;
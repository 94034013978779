/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import logo from './assets/data.jpg';
import PriceTableModal from "../../../../components/Modal/PriceTableModal";

export default function DataDiscoverySub() {
    return (
        <>
            <section className="about-layout4 pt-80 pb-0" id="DataDiscoverySub">
                <h2 className="heading__title text-center mb-20 text-uppercase">
                    Data Discovery
                </h2>
                <h3 className="heading__subtitle_para mb-20">
                    Having accurate and up-to-date data is essential In today's fast-paced business world. The ability to target the right audience with the right message can make or break a business. However, with the vast amount of data available, it can be a challenge to identify and target the right contacts. This is where Business Savvy UK's Data Discovery service comes in.
                </h3>
                <div className="container">
                    <div className="row flex-lg-row flex-sm-column-reverse">
                        {/* <!-- First column for image --> */}
                        <div className="col-lg-6">
                            <div className="heading mb-40">
                                <div className="d-flex align-items-center">
                                    <div className="divider divider-primary mr-30"></div>
                                    <h2 className="heading__subtitle mb-0">Our Data Discovery service</h2>
                                </div>
                                <h3 className="heading__subtitle mb-10">
                                    provides businesses with a comprehensive and targeted approach to data discovery. We deliver cleansed, verified databases to help companies target the right audience for their products and services, avoiding wasting time and resources associated with targeting the wrong contacts.
                                </h3>
                                <div className="d-flex flex-column justify-content-between">
                                    <p className="font-weight-bold">
                                        Our service includes
                                    </p>
                                    <ul className="list-items list-items-layout2 list-horizontal list-unstyled d-flex flex-wrap">
                                        <li>Contact Discovery</li>
                                        <li>Opt-in Data </li>
                                        <li>Account Profiling</li>
                                        <li>Data Cleansing/Enrichment</li>
                                        <br />
                                    </ul>
                                </div>

                                <div className="d-flex flex-column justify-content-between">
                                    <p className="font-weight-bold">
                                        We cover a wide range of regions worldwide, including
                                    </p>
                                    <ul className="list-items list-items-layout2 list-horizontal list-unstyled d-flex flex-wrap">
                                        <li>
                                            the South Asian Association for Regional Cooperation (SAARC)
                                        </li>
                                        <li>
                                            Asia-Pacific (APAC), Australia and New Zealand (ANZ)
                                        </li>

                                        <li>
                                            Gulf Cooperation Council (GCC)
                                        </li>

                                        <li>
                                            Middle East, and Africa
                                        </li>

                                        <li>
                                            Europe (BENELUX, Nordic)
                                        </li>

                                        <li>
                                            United Kingdom (UK)
                                        </li>

                                        <li>
                                            North America (United States of America and Canada)
                                        </li>
                                        <li>
                                            Latin America/South America
                                        </li>
                                    </ul>
                                </div>
                                <p>
                                    At Business Savvy UK, we understand that the information landscape constantly evolves, leading to continual change and data decay. Therefore, we aim to help increase growth and prosperity by supplying our clients with intelligent, cleansed, and enriched data services. Our team of experts validates and prepares required data fields for our clients to prospect and explore new viable and profitable revenues.
                                </p>

                                <p>
                                    With a proactive approach, determination, and optimized lead enrichment data, we can help you revitalize and target your business services to the appropriate audience, thereby increasing revenue. Our core strengths and values are data-driven, and we are dedicated to helping businesses unlock the power of their data.
                                </p>

                                <p>
                                    Partnering with Business Savvy UK for your Data Discovery needs means working with a team of professionals who understand the importance of accurate and up-to-date data. We take the time to understand your business, your target audience, and your objectives to provide you with custom data solutions that meet your specific needs.
                                </p>

                                <p>
                                    Contact us today to learn how we can help you target the right contacts and unlock the power of your data.
                                </p>
                            </div>
                            <div>
                                <button className="pricing__footer">
                                    <PriceTableModal />
                                </button>
                            </div>
                        </div>
                        {/* <!-- Second column for content --> */}
                        <div className="col-lg-6">
                            <div className="about__img">
                                <img src={logo} alt="DataDiscovery" />
                            </div>

                        </div>

                    </div>
                </div>
            </section>
        </>
    );
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import logo from './assets/ai.jpg';
import PriceTableModal from "../../../../components/Modal/PriceTableModal";



export default function AiAndMlSub() {
    return (
        <>
            <section className="about-layout4 pt-80 pb-0" id="aiAndMlSub">
                <h2 className="heading__title text-center mb-20 text-uppercase">
                    Ai & Ml
                </h2>
                <h3 className="heading__subtitle_para mb-20">
                    Artificial intelligence (AI) and machine learning (ML) are the new buzzwords in the tech industry, and for a good reason. With the ability to learn, adapt, and improve independently, AI and ML services are helping businesses across all sectors work more efficiently, save time and money, and make better decisions.
                </h3>
                <div className="container">
                    <div className="row flex-lg-row flex-sm-column-reverse">
                        {/* <!-- First column for image --> */}
                        <div className="col-lg-6">
                            <div className="heading mb-40">
                                <div className="d-flex align-items-center">
                                    <div className="divider divider-primary mr-30"></div>
                                    <h2 className="heading__subtitle mb-0"> At Business Savvy UK</h2>
                                </div>
                                <h3 className="heading__subtitle mb-10">
                                    we offer comprehensive AI and ML services designed to help businesses improve their operations and achieve their goals. Our team of experts deeply understands the latest AI and ML technologies and how they can be applied to various applications in all sectors of business and personal activities.
                                </h3>

                                <p>
                                    Whether you need to automate repetitive tasks, develop predictive analytics models, or build custom AI and ML solutions, we have the expertise to help. We work closely with clients to understand their specific needs and provide tailored solutions that are scalable and cost-effective.
                                </p>

                                <p>
                                    Our AI and ML services can help businesses improve their decision-making processes, increase productivity, and drive growth. From chat bots that will enhance customer service to predictive analytics that enable companies to make more informed decisions, our services are designed to help businesses stay ahead of the competition.
                                </p>

                                <p>
                                    Partnering with us for your AI and ML needs means working with a team of professionals dedicated to your success. We take the time to understand your business, goals, and unique needs to create custom solutions that meet your specific requirements. Contact us today to learn how we can help you leverage the power of AI and ML to take your business to the next level.
                                </p>

                            </div>
                            <div>
                                <button className="pricing__footer">
                                    <PriceTableModal />
                                </button>
                            </div>
                        </div>
                        {/* <!-- Second column for content --> */}
                        <div className="col-lg-6">
                            <div className="about__img">
                                <img src={logo} alt="ai" />
                            </div>

                        </div>

                    </div>
                </div>
            </section>
        </>
    );
}

import React from "react";
import img from "../../../../assets/images/about/7.jpg";
import img2 from "../../../../assets/images/about/3.jpg";
import img3 from "../../../../assets/images/about/singnture.png";
import SideBarAccordion from "../../../../components/SideBarAccordion/SideBarAccordion";

export default function SolutionsLayoutThree() {
  return (
    <section id="about" className="about-layout2 animated-Progressbars">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
            <div className="heading mb-40">
              <div className="d-flex align-items-center mb-20">
                <div className="divider divider-primary mr-30" />
                <h2 className="heading__subtitle mb-0">
                  Curate And Efficient Solution Of Technology Industry
                </h2>
              </div>
              <h3 className="heading__title mb-40">
                That's why we offer various services to help companies to find the right talent for their needs.
              </h3>
            </div>
          </div>
          {/* /.col-xl-5 */}
        </div>
        {/* /.row */}
        <div className="row about__Text">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
            <div className="offset-xl-2 position-relative">
              <i className="icon-quote" />
              <p>Whether you need software developers, hardware engineers, or other IT professionals, we have a network of skilled individuals who can help take your business to the next level. Our team works closely with clients to understand their needs and find the best candidate for the job.
                <br />
                <br />
                We understand the importance of having skilled professionals in the IT and technology industry to help businesses stay competitive. That's why we only supply candidates with relevant experience and qualifications to perform their roles to the highest standard.
                <br />
                <br />
                We take the time to ensure that each candidate we supply is the best fit for the job and the company culture. Our recruitment experts use the latest sourcing and screening methods to identify the most suitable candidates so that you can focus on your business.
                <br />
                <br />
                Partnering with us for your recruitment needs means working with a team of professionals who are dedicated to your success. We aim to help businesses stay ahead of the curve by providing them access to a range of skilled professionals in the IT and technology industry.
              </p>
            </div>
            <div className="about__img mt-80 mb-30">
              <img src={img} alt="about" />
            </div>
            {/* /.about__img*/}
            <div className="video__btn-wrapper">
              <a
                className="video__btn video__btn-white popup-video"
                href="https://www.youtube.com/watch?v=nrJtHemSPW4"
              >
                <div className="video__player">
                  <i className="fa fa-play" />
                </div>
                <span className="video__btn-title">
                  Watch Our Presentation!
                </span>
              </a>
            </div>
            {/* /.video__btn-wrapper*/}
          </div>
          {/* /.col-xl-6 */}
          <SideBarAccordion />
          {/* /.col-xl-5 */}
        </div>
        {/* /.row */}
      </div>
      {/* /.container */}
    </section>
  );
}

import React, { useEffect, useState } from "react";
import Footer from "../../components/footer/DarkFooter";
import Headers from "../../components/header/Headers";
import AboutLayoutOne from "./aboutusSections/AboutLayoutOne";
import HistoryTimeline from "./aboutusSections/HistoryTimeline";
import PageTitle from "./aboutusSections/PageTitle";
import SecondaryNav from "../../components/header/SecondaryNav";
import TestimonialSection from "./aboutusSections/TestimonialSection";
import BannerLayoutSectionFive from "../Home/homeSections/BannerLayoutSectionFive";

export default function Aboutus() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <Headers />
      <PageTitle />
      <SecondaryNav />
      <AboutLayoutOne />
      {/* <HistoryTimeline /> */}
      <TestimonialSection />
      <BannerLayoutSectionFive />
      {/* <BlogGridSection /> */}
      <Footer />
    </>
  );
}

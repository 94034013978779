/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import logo from './assets/RecruitmentServices.jpg';
import PriceTableModal from "../../../../components/Modal/PriceTableModal";

export default function RecruitmentServicesSub() {
    return (
        <>
            <section className="about-layout4 pt-80 pb-0" id="RecruitmentServicesSub">
                <h2 className="heading__title text-center mb-20 text-uppercase">
                    Recruitment Services
                </h2>
                <h3 className="heading__subtitle_para mb-20">
                    "UK Immigration Services by Business Savvy UK"
                </h3>
                <div className="container">
                    <div className="row flex-lg-row flex-sm-column-reverse">
                        {/* <!-- First column for image --> */}
                        <div className="col-lg-6">
                            <div className="heading mb-40">
                                <div className="d-flex align-items-center">
                                    <div className="divider divider-primary mr-30"></div>
                                    <h2 className="heading__subtitle mb-0">
                                        At Business Savvy UK
                                    </h2>
                                </div>
                                <h3 className="heading__subtitle mb-10">
                                    we offer comprehensive UK immigration services to individuals and businesses looking to navigate the complex process of immigrating to the UK. Our team of experienced immigration consultants provides a range of services, including visa applications, work permits, and assistance with settlement and citizenship.
                                </h3>
                                <p>
                                    Our approach is client-focused, and we work closely with each client to understand their unique needs and provide tailored solutions. So whether you're an individual seeking to immigrate to the UK or a business looking to sponsor employees, we can help you navigate the complexities of UK immigration law.
                                </p>

                                <div className="d-flex flex-column justify-content-between">
                                    <p className="font-weight-bold">
                                        Our services include
                                    </p>
                                    <ul className="list-items list-items-layout2 list-horizontal list-unstyled d-flex flex-wrap">
                                        <li>
                                            Visa applications: We assist clients with all types of UK visa applications, including visitor visas, student visas, and work visas.
                                        </li>
                                        <li>Work permits: We help businesses sponsor foreign workers and guide them through the process of obtaining a UK work permit. </li>
                                        <li>Settlement and citizenship: We assist individuals with settlement and citizenship applications, including applications for indefinite leave to remain and naturalization.</li>

                                    </ul>
                                </div>

                                <p>
                                    Partnering with Business Savvy UK means working with a team of professionals dedicated to your immigration success. We have a proven track record of helping clients achieve their immigration goals and can provide you with the guidance and support you need to succeed in your immigration journey.
                                </p>

                            </div>
                            <div>
                                <button className="pricing__footer">
                                    <PriceTableModal />
                                </button>
                            </div>
                        </div>
                        {/* <!-- Second column for content --> */}
                        <div className="col-lg-6">
                            <div className="about__img">
                                <img src={logo} alt="Recruitment" />
                            </div>

                        </div>

                    </div>
                </div>
            </section>
        </>
    );
}

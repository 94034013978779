/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import logo from './assets/social-media-marketing.jpg';
import PriceTableModal from "../../../../components/Modal/PriceTableModal";



export default function SocialMarketingSub() {
    return (
        <>
            <section className="about-layout4 pt-80 pb-0" id="SocialMarketingSub">
                <h2 className="heading__title text-center mb-20 text-uppercase">
                    Social Marketing
                </h2>
                <h3 className="heading__subtitle_para mb-20">
                    In today's digital age, social media marketing Has become vital for businesses to connect with their audience and increase sales. With the ability to reach a broad or focused audience, social media platforms like Facebook, Instagram, Twitter, LinkedIn, and others have become essential for businesses to promote their products and services.
                </h3>
                <div className="container">
                    <div className="row flex-lg-row">
                        {/* <!-- First column for image --> */}
                        <div className="col-lg-6 mb-10">
                            <div className="about__img">
                                <img src={logo} alt="Social Marketing" />
                            </div>

                        </div>

                        {/* <!-- Second column for content --> */}
                        <div className="col-lg-6 mt-10">
                            <div className="heading mb-40">
                                <div className="d-flex align-items-center">
                                    <div className="divider divider-primary mr-30"></div>
                                    <h2 className="heading__subtitle mb-0">At Business Savvy UK</h2>
                                </div>
                                <h3 className="heading__subtitle mb-10">
                                    we understand the importance of social media marketing and offer comprehensive services designed to help businesses grow their sales through various social media platforms. Our team of social media experts works closely with clients to create custom strategies that reflect their business and target audience.
                                </h3>

                                <p>
                                    We help businesses identify the right social media platforms for their business and create compelling and engaging content that resonates with their audience. We also use advanced analytics to measure the effectiveness of our social media campaigns, making informed decisions based on the data to optimize future campaigns.
                                </p>
                                <p>
                                    Our social media marketing services offer numerous benefits to businesses, including increased brand awareness, customer engagement, website traffic, and, ultimately, sales. By effectively utilizing social media, companies can build a loyal following, connect with their audience, and drive revenue growth.
                                </p>

                                <p>
                                    Partnering with us for your social media marketing needs means working with a team of professionals dedicated to your success. We take the time to understand your business, audience, and objectives to create custom strategies that deliver results.
                                </p>
                                <p>
                                    Contact us today to learn how we can help you achieve your business goals through effective social media marketing.
                                </p>
                            </div>
                            <div>
                                <button className="pricing__footer">
                                    <PriceTableModal />
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    );
}
import React from "react";
import img from "../../../../assets/images/about/7.jpg";
import img2 from "../../../../assets/images/about/3.jpg";
import img3 from "../../../../assets/images/about/singnture.png";
import SideBarAccordion from "../../../../components/SideBarAccordion/SideBarAccordion";

export default function SolutionsLayoutThree() {
  return (
    <section id="about" className="about-layout2 animated-Progressbars">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
            <div className="heading mb-40">
              <div className="d-flex align-items-center mb-20">
                <div className="divider divider-primary mr-30" />
                <h2 className="heading__subtitle mb-0">
                  Curate And Efficient Solution Of Business Opportunitie
                </h2>
              </div>
              <h3 className="heading__title mb-40">
                That's why we offer a comprehensive business opportunities service to help connect entrepreneurs with franchises,
              </h3>
            </div>
          </div>
          {/* /.col-xl-5 */}
        </div>
        {/* /.row */}
        <div className="row about__Text">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
            <div className="offset-xl-2 position-relative">
              <i className="icon-quote" />
              <p>Businesses for sale, or brand-new opportunities from our client base worldwide.
                <br />
                <br />
                Our team of experts works closely with clients to understand their specific needs, preferences, and objectives. We then leverage our extensive network and resources to identify and recommend suitable business opportunities that align with their goals.
                <br />
                <br />
                Our business opportunities service covers a wide range of industries, from retail and hospitality to technology and healthcare. So, whether you're a first-time entrepreneur or a seasoned business owner, we have the expertise and experience to help you find the perfect opportunity.
                <br />
                <br />
                In addition to recommending opportunities, we also provide support throughout the process. This includes conducting due diligence, negotiating deals, and providing guidance on financing and legal matters.
                <br />
                <br />
                Partnering with us for your business opportunities means gaining access to a vast network of global business opportunities and the expertise and support needed to navigate the complex process of buying or investing in a business.
                <br />
                <br />
                Don't let the process of finding the right business opportunity overwhelm you. Let Business Savvy UK help you find the perfect opportunity to achieve your entrepreneurial dreams.
                <br />
                <br />
                Contact us today to learn how we can help you find the ideal business opportunity to match your goals and aspirations.
              </p>
            </div>
            <div className="about__img mt-80 mb-30">
              <img src={img} alt="about" />
            </div>
            {/* /.about__img*/}
            <div className="video__btn-wrapper">
              <a
                className="video__btn video__btn-white popup-video"
                href="https://www.youtube.com/watch?v=nrJtHemSPW4"
              >
                <div className="video__player">
                  <i className="fa fa-play" />
                </div>
                <span className="video__btn-title">
                  Watch Our Presentation!
                </span>
              </a>
            </div>
            {/* /.video__btn-wrapper*/}
          </div>
          {/* /.col-xl-6 */}
          <SideBarAccordion />
          {/* /.col-xl-5 */}
        </div>
        {/* /.row */}
      </div>
      {/* /.container */}
    </section>
  );
}

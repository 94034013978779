import React, { useEffect, useState } from "react";
import Headers from "../../components/header/Headers";
import SecondaryNav from "../../components/header/SecondaryNav";
import AwardsCards from "./awardsSections/AwardsCards";
import TitleSection from "./awardsSections/TitleSection";
import MiniSlider from "../../components/slider/Minislider";
import BlogGridSection from "../Home/homeSections/BlogGridSection";
import Footer from "../../components/footer/Footer";
import BannerLayoutFive from "./awardsSections/BannerLayoutFive";

export default function Awards() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <Headers />
      <TitleSection />
      <SecondaryNav />
      <AwardsCards />
      <MiniSlider />
      <BannerLayoutFive />
      <BlogGridSection />
      <Footer />
    </>
  );
}

/* eslint-disable no-undef */
import React from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import emailjs from "@emailjs/browser";

const ContactForm = ({ handleClose }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log(data);
    emailjs
      .send("service_qd6ckit", "template_5y4ery4", data, "nSP0cYyvCFTGkDEWh")
      .then(
        (result) => {
          console.log(result.text);
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Request quote sended successfully",
            showConfirmButton: false,
            timer: 1500,
          });
          reset();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div
      className="col-sm-12 col-md-12 col-lg-12 mb-3 p-10 mt-10"
      style={{ position: "relative" }}
    >
      {/* <i
        style={{
          position: "absolute",
          zIndex: "99",
          display: "block",
          top: "-57px",
          right: "-35px",
          color: "white",
          cursor: "pointer",
          fontWeight: "bold",
          fontSize: "30px",
        }}
        class="far fa-times-circle text-white"
        onClick={handleClose}
      ></i> */}
      <div className="">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="contact-panel__form"
          id="contactForm"
        >
          <div className="row">
            <div className="col-12">
              <h4 className="contact-panel__title mb-20">Request A Quote</h4>
              <p className="contact-panel__desc mb-30">
                Our deep pool of certified engineers and IT staff are ready to
                help you to keep your IT business safe &amp; ensure high
                availability.
              </p>
            </div>{" "}
            {/* /.col-12 */}
          </div>
          {/* /.row */}
        </form>
      </div>
    </div>
  );
};

export default ContactForm;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import logo from './assets/product.jpg';
import PriceTableModal from "../../../../components/Modal/PriceTableModal";


export default function ProductSourcingSub() {
    return (
        <>
            <section className="about-layout4 pt-80 pb-0" id="ProductSourcingSub">
                <h2 className="heading__title text-center mb-20 text-uppercase">
                    Product Sourcing
                </h2>
                <h3 className="heading__subtitle_para mb-20">
                    Looking for new and innovative products to Promote can be daunting for any business. That's where Business Savvy UK comes in. Our product sourcing and recommendation service is designed to help companies source the best products from manufacturers seeking dedicated distributors or resellers. We tailor our sourcing to each client's individual needs, ensuring they have little to no competition in some cases. We aim to help businesses stand out in their market and gain a competitive edge.
                </h3>
                <div className="container">
                    <div className="row flex-lg-row">
                        {/* <!-- First column for image --> */}
                        <div className="col-lg-6 mb-10">
                            <div className="about__img">
                                <img src={logo} alt="ProductSourcing" />
                            </div>

                        </div>

                        {/* <!-- Second column for content --> */}
                        <div className="col-lg-6 mt-10">
                            <div className="heading mb-40">
                                <div className="d-flex align-items-center">
                                    <div className="divider divider-primary mr-30"></div>
                                    <h2 className="heading__subtitle mb-0">  But our services</h2>
                                </div>
                                <h3 className="heading__subtitle mb-10">
                                    don't stop at sourcing. We also offer logistics services, ensuring that the products are delivered to your doorstep promptly and efficiently. In addition, we help with the route to market in promoting the product, guiding the best ways to reach your target audience and promote your product effectively.
                                </h3>

                                <p>
                                    At Business Savvy UK, we understand that every business has unique needs. That's why we offer customized product sourcing and recommendation services tailored to each client's individual needs. Whether you're a startup or an established business, we can help you find the right products to promote and grow your business.
                                </p>
                                <p>
                                    We have established partnerships with manufacturers and suppliers worldwide, allowing us to offer a wide range of products across various industries. Our team of experts is dedicated to providing the best service possible, ensuring our clients' access to the latest and most innovative products.
                                </p>
                                <p>
                                    Partnering with us means working with a team of professionals dedicated to your success. We take the time to understand your business and goals, providing personalized solutions that meet your needs.
                                </p>

                                <p>
                                    Contact us today to learn how we can help you find the best products to promote and grow your business.
                                </p>
                            </div>
                            <div>
                                <button className="pricing__footer">
                                    <PriceTableModal />
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    );
}
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import logo from './assets/logoBranding.jpg';
import PriceTableModal from "../../../../components/Modal/PriceTableModal";


export default function LogoAndBrandingSub() {
    return (
        <>
            <section className="about-layout4 pt-80 pb-0" id="logoAndBrandingSub">
                <h2 className="heading__title text-center mb-20 text-uppercase">
                    Logo and Branding
                </h2>
                <h3 className="heading__subtitle_para mb-20">
                    Your brand is more than just a logo or a catchy slogan
                    It's an identity representing your business, values, and personality. At Business Savvy UK, we understand the importance of  creating a strong brand identity that resonates with your target audience.
                </h3>
                <div className="container">
                    <div className="row flex-lg-row">
                        {/* <!-- First column for image --> */}
                        <div className="col-lg-6 mb-10">
                            <div className="about__img">
                                <img src={logo} alt="logoAndBranding" />
                            </div>

                        </div>

                        {/* <!-- Second column for content --> */}
                        <div className="col-lg-6 mt-10">
                            <div className="heading mb-40">
                                <div className="d-flex align-items-center">
                                    <div className="divider divider-primary mr-30"></div>
                                    <h2 className="heading__subtitle mb-0">
                                        Our Logo & Branding services
                                    </h2>
                                </div>
                                <h3 className="heading__subtitle mb-10">
                                    are designed to help you stand out in your market and make a lasting impression on your customers.
                                </h3>
                                <p>
                                    First, we work closely with you to understand your business, audience, and market trends. From there, we create custom designs that include logos, colour schemes, typography, and other branding elements that reflect your unique identity.
                                </p>
                                <p>
                                    Our team of experienced designers are passionate about bringing your vision to life and creating a professional image that sets you apart from your competition. Let us help you make your mark with a strong brand identity that engages and resonates with your customers.
                                </p>
                            </div>
                            <div>
                                <button className="pricing__footer">
                                    <PriceTableModal />
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    );
}
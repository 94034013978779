/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import logo from './assets/webDesign.jpg';
import PriceTableModal from "../../../../components/Modal/PriceTableModal";
export default function WebDesignSub() {
    return (
        <>
            <section className="about-layout4 pt-80 pb-0" id="webDesignSub">
                <h2 className="heading__title text-center mb-20 text-uppercase">
                    Website design
                </h2>
                <h3 className="heading__subtitle_para mb-20">
                    Our website design service is
                    about crafting a digital home for your business  that
                    stands out, engages visitors, and drives conversions.
                </h3>
                <div className="container">
                    <div className="row flex-lg-row flex-sm-column-reverse">
                        {/* <!-- First column for image --> */}
                        <div className="col-lg-6 mt-20">
                            <div className="heading mb-40">
                                <div className="d-flex align-items-center">
                                    <div className="divider divider-primary mr-30"></div>
                                    <h2 className="heading__subtitle mb-0">We know a website isn't just a static page</h2>
                                </div>
                                <h3 className="heading__subtitle">
                                    it's your business's virtual storefront, open 24/7.
                                </h3>
                                <p>
                                    it's your business's virtual storefront, open 24/7. That's why our team of expert designers takes the time to understand your business, target audience, and goals, so we can create a custom website that speaks directly to your ideal customers.
                                </p>
                                <p>
                                    Our experienced team has a wealth of knowledge and expertise in supporting start-ups and established businesses. In addition, we provide various services that help businesses navigate their challenges, avoid common pitfalls and achieve success.
                                </p>
                            </div>
                            <div>

                                <button className="pricing__footer">
                                    <PriceTableModal />
                                </button>

                            </div>
                        </div>
                        {/* <!-- Second column for content --> */}
                        <div className="col-lg-6">
                            <div className="about__img">
                                <img src={logo} alt="webDesign" />
                            </div>

                        </div>

                    </div>
                </div>
            </section>
        </>
    );
}

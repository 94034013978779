import React from 'react';
import SingleServiceItems from './SingleServiceItems';
import WebsiteBuildingSub from './ITandWebServiceAllSub/WebsiteBuildingSub/WebsiteBuildingSub';
import ITSupportSub from './ITandWebServiceAllSub/ITSupportSub/ITSupportSub';
import WebAppDevelopmentSub from './ITandWebServiceAllSub/WebAppDevelopmentSub/WebAppDevelopmentSub';
import MobileAppDevelopmentSub from './ITandWebServiceAllSub/MobileAppDevelopmentSub/MobileAppDevelopmentSub';
import AiAndMlSub from './ITandWebServiceAllSub/AiAndMlSub/AiAndMlSub';
import DigitalMarketingSub from './ITandWebServiceAllSub/DigitalMarketingSub/DigitalMarketingSub';

const BrochureSection = () => {
    return (
        <section className="services-layout1 bg-gray pt-130 pb-90">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-6 offset-lg-3">
                        <div className="heading text-center mb-50">
                            <h2 className="heading__subtitle">
                                Business Savvy UK
                            </h2>
                            <h3 className="heading__title">
                                IT & Web Service Single Category items. Selected your affordable Service.
                            </h3>
                        </div>
                        {/* /.heading */}
                    </div>
                    {/* /.col-lg-8 */}
                </div>
                <>
                    <SingleServiceItems />
                </>
                {/* website building Sub */}
                <WebsiteBuildingSub />
                {/* website building Sub Card */}
                {/* <Pricing /> */}
                {/* IT support Sub */}
                <ITSupportSub />
                {/* IT support Sub Card */}
                {/* <Pricing /> */}
                {/* Web App Development Sub */}
                <WebAppDevelopmentSub />
                {/* Web App Development Sub Card */}
                {/* <Pricing /> */}
                {/* mobile App Development Sub */}
                <MobileAppDevelopmentSub />
                {/* mobile App Development Sub Card */}
                {/* <Pricing /> */}
                {/* Ai & Ml Sub */}
                <AiAndMlSub />
                {/* Ai & Ml Sub Card */}
                {/* <Pricing /> */}
                {/* Digital Marketing Sub */}
                <DigitalMarketingSub />
                {/* Digital Marketing Sub Card */}
                {/* <Pricing /> */}
                {/* Sub */}

                {/* Sub Card */}

            </div>
            {/* /.container */}
        </section>
    );
};

export default BrochureSection;
import React from "react";
import img from "../../../../assets/images/about/7.jpg";
import img2 from "../../../../assets/images/about/3.jpg";
import img3 from "../../../../assets/images/about/singnture.png";
import SideBarAccordion from "../../../../components/SideBarAccordion/SideBarAccordion";

export default function SolutionsLayoutThree() {
  return (
    <section id="about" className="about-layout2 animated-Progressbars">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
            <div className="heading mb-40">
              <div className="d-flex align-items-center mb-20">
                <div className="divider divider-primary mr-30" />
                <h2 className="heading__subtitle mb-0">
                  Curate And Efficient Solution Of LinkedIn Services
                </h2>
              </div>
              <h3 className="heading__title mb-40">
                With over 800 million users, LinkedIn is a powerful social media platform that lets you keep all your essential documents,
              </h3>
            </div>
          </div>
          {/* /.col-xl-5 */}
        </div>
        {/* /.row */}
        <div className="row about__Text">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
            <div className="offset-xl-2 position-relative">
              <i className="icon-quote" />
              <p>, qualifications, awards, honours, work experience, and more in one place. Plus, your profile headline alone gets put in front of hundreds and thousands of people every time you write a post.
                <br />
                <br />
                Our team of experts can optimize your LinkedIn profile to help you stand out with a profile that speaks in your unique tone of voice and positions you for success in your niche. In addition, we provide calls-to-action that move your audience to connect, engage, and message you, a captivating headline that makes people eager to click, and a summary that speaks to your target audience.
                <br />
                <br />
                We also include a featured section that captures attention and fuels intrigue, an accurate and optimistic overview of your current and past experiences that highlight your biggest wins, an up-to-date list of any publications, projects, or awards you've created/received, and a skills list that reflects who you are and what your audience is looking for.
                <br />
                <br />
                Our team follows specific formatting rules, and we understand all sorts of intricate details of the platform so that we can save you time and frustration. In addition, we have done LinkedIn Profile Upgrades for dozens of clients worldwide and have the experience and expertise to ensure you get the most out of your profile.
                <br />
                <br />
                Our service includes a 1-hour Zoom interview to cover areas including your background, ambitions, experiences, what to focus on in the profile, and what you want the profile to accomplish.
                <br />
                <br />
                We'll review any further resources you provide and do any additional research required, then return to you if we have any questions. Finally, we'll assemble the new profile in a Google Doc and send it to you for approval/edits. Once approved, we'll log into your LinkedIn and make all the changes, ensuring everything looks right.
                <br />
                <br />
                Don't leave a TON on the table. Book your slot ahead of time with Business Savvy UK, and we'll optimize your LinkedIn profile to help you win on LinkedIn.
              </p>
            </div>
            <div className="about__img mt-80 mb-30">
              <img src={img} alt="about" />
            </div>
            {/* /.about__img*/}
            <div className="video__btn-wrapper">
              <a
                className="video__btn video__btn-white popup-video"
                href="https://www.youtube.com/watch?v=nrJtHemSPW4"
              >
                <div className="video__player">
                  <i className="fa fa-play" />
                </div>
                <span className="video__btn-title">
                  Watch Our Presentation!
                </span>
              </a>
            </div>
            {/* /.video__btn-wrapper*/}
          </div>
          {/* /.col-xl-6 */}
          <SideBarAccordion />
          {/* /.col-xl-5 */}
        </div>
        {/* /.row */}
      </div>
      {/* /.container */}
    </section>
  );
}

import React from "react";
import img1 from "../../../assets/images/team/billy.jpeg";
import img2 from "../../../assets/images/team/poule.jpeg";
import img3 from "../../../assets/images/team/steve.jpeg";
import img4 from "../../../assets/images/team/Paul.jpeg";
import img5 from "../../../assets/images/team/suie.jpeg";
import img6 from "../../../assets/images/team/Purdip.jpeg";
import img7 from "../../../assets/images/team/Nick.jpeg";
import img8 from "../../../assets/images/team/Sangeeta.jpeg";
import img9 from "../../../assets/images/team/Sam.jpeg";
import img10 from "../../../assets/images/team/ahamed.jpeg";
export default function TeamLayoutOne() {
  return (
    <section className="team-layout1 text-center pb-40">
      <div className="container">
        <div className="row">
          {/* Member #1 */}
          <div className="col-sm-6 col-md-4 col-lg-4">
            <div className="member">
              <div className="member__img">
                <img src={img1} alt="member img" />
                <div className="member__hover">
                  <div className="member__content-inner">
                    <ul className="social-icons  justify-content-center list-unstyled mb-0">
                      <li>
                        <a href="#">
                          <i className="fab fa-facebook-f" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-instagram" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-twitter" />
                        </a>
                      </li>
                    </ul>
                    {/* /.social-icons */}
                  </div>
                  {/* /.member-content-inner */}
                </div>
                {/* /.member-hover */}
              </div>
              {/* /.member-img */}
              <div className="member__info">
                <h5 className="member__name">Captain of Commerce<br/>Billy Kukadia</h5>
                <p className="member__desc">Innovation at Heart, Excellence in Every Endeavor.</p>
              </div>
              {/* /.member-info */}
            </div>
            {/* /.member */}
          </div>
          {/* /.col-lg-4 */}
          {/* Member #2 */}
          <div className="col-sm-6 col-md-4 col-lg-4">
            <div className="member">
              <div className="member__img">
                <img src={img2}  alt="member img" />
                <div className="member__hover">
                  <div className="member__content-inner">
                    <ul className="social-icons justify-content-center list-unstyled mb-0">
                      <li>
                        <a href="#">
                          <i className="fab fa-facebook-f" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-instagram" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-twitter" />
                        </a>
                      </li>
                    </ul>
                    {/* /.social-icons */}
                  </div>
                  {/* /.member-content-inner */}
                </div>
                {/* /.member-hover */}
              </div>
              {/* /.member-img */}
              <div className="member__info">
                <h5 className="member__name">Design Maestro <br/>Paul Bates</h5>
                <p className="member__desc">Where Vision Meets Vivid and Craft Meets Craftsmanship.</p>
              </div>
              {/* /.member-info */}
            </div>
            {/* /.member */}
          </div>
          {/* /.col-lg-4 */}
          {/* Member #3 */}
          <div className="col-sm-6 col-md-4 col-lg-4">
            <div className="member">
              <div className="member__img">
                <img src={img3}  alt="member img" />
                <div className="member__hover">
                  <div className="member__content-inner">
                    <ul className="social-icons justify-content-center list-unstyled mb-0">
                      <li>
                        <a href="#">
                          <i className="fab fa-facebook-f" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-instagram" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-twitter" />
                        </a>
                      </li>
                    </ul>
                    {/* /.social-icons */}
                  </div>
                  {/* /.member-content-inner */}
                </div>
                {/* /.member-hover */}
              </div>
              {/* /.member-img */}
              <div className="member__info">
                <h5 className="member__name">Sales Sentinel<br/> Steve Yardley</h5>
                <p className="member__desc">Expanding Horizons, One Agent at a Time.</p>
              </div>
              {/* /.member-info */}
            </div>
            {/* /.member */}
          </div>
          {/* /.col-lg-4 */}
        </div>{" "}
        {/* /.row */}
        <div className="row">
          {/* Member #1 */}
          <div className="col-sm-6 col-md-6 col-lg-3">
            <div className="member">
              <div className="member__img">
                <img src={img4}  alt="member img" />
                <div className="member__hover">
                  <div className="member__content-inner">
                    <ul className="social-icons justify-content-center list-unstyled mb-0">
                      <li>
                        <a href="#">
                          <i className="fab fa-facebook-f" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-instagram" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-twitter" />
                        </a>
                      </li>
                    </ul>
                    {/* /.social-icons */}
                  </div>
                  {/* /.member-content-inner */}
                </div>
                {/* /.member-hover */}
              </div>
              {/* /.member-img */}
              <div className="member__info">
                <h5 className="member__name">Visual Virtuoso<br/> Paul Emerton</h5>
                <p className="member__desc">Crafting Stories, Amplifying Brands.</p>
              </div>
              {/* /.member-info */}
            </div>
            {/* /.member */}
          </div>
          {/* /.col-lg-3 */}
          {/* Member #2 */}
          <div className="col-sm-6 col-md-6 col-lg-3">
            <div className="member">
              <div className="member__img">
                <img src={img5}  alt="member img" />
                <div className="member__hover">
                  <div className="member__content-inner">
                    <ul className="social-icons  justify-content-center list-unstyled mb-0">
                      <li>
                        <a href="#">
                          <i className="fab fa-facebook-f" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-instagram" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-twitter" />
                        </a>
                      </li>
                    </ul>
                    {/* /.social-icons */}
                  </div>
                  {/* /.member-content-inner */}
                </div>
                {/* /.member-hover */}
              </div>
              {/* /.member-img */}
              <div className="member__info">
                <h5 className="member__name">Legal Luminary<br/> Sue</h5>
                <p className="member__desc">Guiding Business Brilliance with Legal Precision.</p>
              </div>
              {/* /.member-info */}
            </div>
            {/* /.member */}
          </div>
          {/* /.col-lg-3 */}
          {/* Member #3 */}
          <div className="col-sm-6 col-md-6 col-lg-3">
            <div className="member">
              <div className="member__img">
                <img src={img6}  alt="member img" />
                <div className="member__hover">
                  <div className="member__content-inner">
                    <ul className="social-icons justify-content-center list-unstyled mb-0">
                      <li>
                        <a href="#">
                          <i className="fab fa-facebook-f" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-instagram" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-twitter" />
                        </a>
                      </li>
                    </ul>
                    {/* /.social-icons */}
                  </div>
                  {/* /.member-content-inner */}
                </div>
                {/* /.member-hover */}
              </div>
              {/* /.member-img */}
              <div className="member__info">
                <h5 className="member__name">Finance Maestro<br/> Purdip Shoker</h5>
                <p className="member__desc">Precision in Numbers, Personalized for You.</p>
              </div>
              {/* /.member-info */}
            </div>
            {/* /.member */}
          </div>
          {/* /.col-lg-3 */}
          {/* Member #4 */}
          <div className="col-sm-6 col-md-6 col-lg-3">
            <div className="member">
              <div className="member__img">
                <img src={img7}  alt="member img" />
                <div className="member__hover">
                  <div className="member__content-inner">
                    <ul className="social-icons justify-content-center list-unstyled mb-0">
                      <li>
                        <a href="#">
                          <i className="fab fa-facebook-f" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-instagram" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-twitter" />
                        </a>
                      </li>
                    </ul>
                    {/* /.social-icons */}
                  </div>
                  {/* /.member-content-inner */}
                </div>
                {/* /.member-hover */}
              </div>
              {/* /.member-img */}
              <div className="member__info">
                <h5 className="member__name">Sales Sage<br/> Nick Stewart</h5>
                <p className="member__desc">Crafting Conversions, Curating Connections.</p>
              </div>
              {/* /.member-info */}
            </div>
            {/* /.member */}
          </div>
          {/* /.col-lg-3 */}
        </div>{" "}
        <div className="row justify-content-center">
          {/* Member #1 */}
          <div className="col-sm-6 col-md-6 col-lg-3">
            <div className="member">
              <div className="member__img">
                <img src={img8}  alt="member img" />
                <div className="member__hover">
                  <div className="member__content-inner">
                    <ul className="social-icons justify-content-center list-unstyled mb-0">
                      <li>
                        <a href="#">
                          <i className="fab fa-facebook-f" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-instagram" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-twitter" />
                        </a>
                      </li>
                    </ul>
                    {/* /.social-icons */}
                  </div>
                  {/* /.member-content-inner */}
                </div>
                {/* /.member-hover */}
              </div>
              {/* /.member-img */}
              <div className="member__info">
                <h5 className="member__name">E-commerce Empress<br/> Sangeeta</h5>
                <p className="member__desc">From Listings to Limelight, We Elevate Every Sale.</p>
              </div>
              {/* /.member-info */}
            </div>
            {/* /.member */}
          </div>
          {/* /.col-lg-3 */}
          {/* Member #2 */}
          <div className="col-sm-6 col-md-6 col-lg-3">
            <div className="member">
              <div className="member__img">
                <img src={img9}  alt="member img" />
                <div className="member__hover">
                  <div className="member__content-inner">
                    <ul className="social-icons  justify-content-center list-unstyled mb-0">
                      <li>
                        <a href="#">
                          <i className="fab fa-facebook-f" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-instagram" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-twitter" />
                        </a>
                      </li>
                    </ul>
                    {/* /.social-icons */}
                  </div>
                  {/* /.member-content-inner */}
                </div>
                {/* /.member-hover */}
              </div>
              {/* /.member-img */}
              <div className="member__info">
                <h5 className="member__name">Data Maestro<br/> Sam</h5>
                <p className="member__desc">Deciphering Data, Defining Decisions.</p>
              </div>
              {/* /.member-info */}
            </div>
            {/* /.member */}
          </div>
          {/* /.col-lg-3 */}
          {/* Member #3 */}
          <div className="col-sm-6 col-md-6 col-lg-3">
            <div className="member">
              <div className="member__img">
                <img src={img10}  alt="member img" />
                <div className="member__hover">
                  <div className="member__content-inner">
                    <ul className="social-icons justify-content-center list-unstyled mb-0">
                      <li>
                        <a href="#">
                          <i className="fab fa-facebook-f" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-instagram" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-twitter" />
                        </a>
                      </li>
                    </ul>
                    {/* /.social-icons */}
                  </div>
                  {/* /.member-content-inner */}
                </div>
                {/* /.member-hover */}
              </div>
              {/* /.member-img */}
              <div className="member__info">
                <h5 className="member__name">Digital Dynamo<br/> KH Nazmul Ahamed</h5>
                <p className="member__desc">Crafting Codes, Building Businesses</p>
              </div>
              {/* /.member-info */}
            </div>
            {/* /.member */}
          </div>
          {/* /.col-lg-3 */}
          {/* /.col-lg-3 */}
        </div>{" "}
        {/* /.row */}
      </div>
      {/* /.container */}
    </section>
  );
}

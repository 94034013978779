/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import logo from './assets/medical.jpg';
import PriceTableModal from "../../../../components/Modal/PriceTableModal";


export default function MedicalHealthcareSub() {
    return (
        <>
            <section className="about-layout4 pt-80 pb-0" id="MedicalHealthcareSub">
                <h2 className="heading__title text-center mb-20 text-uppercase">
                    Medical & Healthcare
                </h2>

                <h3 className="heading__subtitle_para mb-20">
                    Business Savvy UK understands the importance of having skilled and reliable professionals in the medical and healthcare industry. That's why we offer a wide range of staffing solutions that cater to the needs of healthcare facilities and organizations
                </h3>
                <div className="container">
                    <div className="row flex-lg-row">
                        {/* <!-- First column for image --> */}
                        <div className="col-lg-6 mb-10">
                            <div className="about__img">
                                <img src={logo} alt=" Medical & Healthcare" />
                            </div>

                        </div>

                        {/* <!-- Second column for content --> */}
                        <div className="col-lg-6 mt-10">
                            <div className="heading mb-40">
                                <div className="d-flex align-items-center">
                                    <div className="divider divider-primary mr-30"></div>
                                    <h2 className="heading__subtitle mb-0">
                                        We supply highly skilled
                                    </h2>
                                </div>
                                <h3 className="heading__subtitle mb-10">
                                    and experienced healthcare professionals, including doctors, nurses, medical assistants, laboratory technicians, and administrative personnel. In addition, our team works closely with healthcare facilities to understand their specific needs and provide tailored solutions that meet their staffing requirements.
                                </h3>

                                <p>
                                    We source candidates with the qualifications, experience, and personality traits to ensure they fit our client's organizations. Our comprehensive screening process ensures that all candidates are thoroughly vetted and meet the requirements to work in the medical and healthcare industry.
                                </p>
                                <p>
                                    Whether you need temporary, permanent, or contract staff, we have the expertise to help you find suitable candidates for your organization. We provide ongoing support to both our clients and candidates to ensure that their staffing needs are met and that they are satisfied with the service they receive.
                                </p>
                                <p>
                                    Partnering with us means working with a team of professionals dedicated to your success. We understand the importance of having skilled and reliable professionals in the medical and healthcare industry, and we are committed to helping our clients find the right people to meet their staffing needs.
                                </p>
                                <p>
                                    Contact us today to learn how we can help you find the right talent for your organization.
                                </p>
                            </div>
                            <div>
                                <button className="pricing__footer">
                                    <PriceTableModal />
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    );
}
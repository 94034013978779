/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import logo from './assets/Build-website.jpg';
import PriceTableModal from "../../../../components/Modal/PriceTableModal";



export default function WebsiteBuildingSub() {
    return (
        <>
            <section className="about-layout4 pt-80 pb-0" id="websiteBuildingSub">
                <h2 className="heading__title text-center mb-20 text-uppercase">
                    Website Building
                </h2>
                <h3 className="heading__subtitle_para mb-20">
                    At Business Savvy UK, we know that having A solid online presence is crucial for businesses of all sizes. That's why we offer a comprehensive website-building service that is tailored to meet the specific needs of each of our clients
                </h3>
                <div className="container">
                    <div className="row flex-lg-row flex-sm-column-reverse">
                        {/* <!-- First column for image --> */}
                        <div className="col-lg-6">
                            <div className="heading mb-40">
                                <div className="d-flex align-items-center">
                                    <div className="divider divider-primary mr-30"></div>
                                    <h2 className="heading__subtitle mb-0">
                                        Our team of web developers
                                    </h2>
                                </div>
                                <h3 className="heading__subtitle mb-10">
                                    use the latest MERN stack technology and SEO-friendly IT infrastructure is critical to success. At Business Savvy UK, we offer a comprehensive range of IT support and services designed to keep your business running smoothly.platforms to build custom websites that are designed to be engaging, responsive and optimized for search engines. We understand the importance of a user-friendly experience, and we work closely with our clients to create websites that are easy to navigate and encourage visitors to act.
                                </h3>
                                <p>
                                    No matter the size of your business, our web development team can help you create a website that will effectively represent your brand and attract potential customers. Whether you need a simple one-page website or a complex e-commerce platform, we can build it for you.
                                </p>

                                <p>
                                    Our websites are optimized for search engines, ensuring your business is visible online and effectively reaches your target audience. With our expertise in SEO and digital marketing, we can help your website rank higher in search engine results, driving more traffic to your site and increasing your online presence.
                                </p>

                                <p>
                                    At Business Savvy UK, we take pride in building high-quality, custom websites that help our clients achieve their business goals. Our web development team has the skills and experience to deliver outstanding results for businesses of all sizes.
                                </p>

                                <p>
                                    Contact us today to learn how we can help you build a website that will take your business to the next level.
                                </p>
                            </div>
                            <div>
                                <button className="pricing__footer">
                                    <PriceTableModal />
                                </button>
                            </div>
                        </div>
                        {/* <!-- Second column for content --> */}
                        <div className="col-lg-6">
                            <div className="about__img">
                                <img src={logo} alt="websiteBuilding" />
                            </div>

                        </div>

                    </div>
                </div>
            </section>
        </>
    );
}

import React, { useState, useEffect } from "react";
import { Link } from "react-scroll";
import './SecondaryNav.css'

export default function SecondaryNav() {
    const [scroll, setScroll] = useState(false);
    const [windowSize, setWindowSize] = useState(getWindowSize());
    useEffect(() => {
        const handleScroll = (event) => {
            if (windowSize.innerWidth >= 992) {
                setScroll(window.scrollY > 100);
            }
        };
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [windowSize]);

    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }

    return (
        <section
            className={
                scroll
                    ? "secondary-nav secondary-nav-sticky sticky-top py-0"
                    : "secondary-nav sticky-top py-0"
            }
        >
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <nav className="nav nav-tabs justify-content-center">
                            {/* This Link tag is from react scroll package */}
                            <Link to="DataDiscoverySub" className="nav__link cursor-pointer" spy={true} smooth={true} offset={-100} duration={500}>
                                Data Discovery
                            </Link>
                            <Link to="ProductSourcingSub" className="nav__link cursor-pointer" spy={true} smooth={true} offset={-100} duration={500}>
                                Product Sourcing
                            </Link>
                            <Link to="BusinessOpportunitieSub" className="nav__link cursor-pointer" spy={true} smooth={true} offset={-100} duration={500}>
                                Business Opportunities
                            </Link>
                            <Link to="LinkedInMarketingSub" className="nav__link cursor-pointer" spy={true} smooth={true} offset={-100} duration={500}>
                                LinkedIn Services
                            </Link>

                        </nav>
                    </div>
                    {/* /.col-12 */}
                </div>
                {/* /.row */}
            </div>
            {/* /.container */}
        </section>
    );
}

import Home from "../pages/Home/Home";
import Awards from "../pages/Awards/Awards";

import ErrorPage from "../pages/404Page/ErrorPage";
import Careers from "../pages/Careers/Careers";
import WhyUs from "../pages/WhyUs/WhyUs";
import Aboutus from "../pages/Aboutus/Aboutus";
import BlogSinglePost from "../pages/BlogSinglePost/BlogSinglePost";
import CaseStudiesGrid from "../pages/CaseStudiesGrid/CaseStudiesGrid";
import CaseStudiesSingle from "../pages/CaseStudiesSingle/CaseStudiesSingle";
import ContactUs from "../pages/ContactUs/ContactUs";
import Faqs from "../pages/Faqs/Faqs";
import ItSolutionsSinglePage from "../pages/ItSolutionsSinglePage/ItSolutionsSinglePage";
import LeadershipTeam from "../pages/LeadershipTeam/LeadershipTeam";
import OurBlog from "../pages/OurBlog/OurBlog";
import Pricing from "../pages/Pricing/Pricing";
import RequestQuote from "../pages/RequestQuote/RequestQuote";
import ItSolutions from "../pages/ItSolutions/ItSolutions";
import { createBrowserRouter } from "react-router-dom";
import Packaging from "../pages/DesignService/Packaging/Packaging";
import Advertising from "../pages/DesignService/AdvertisingandMarketingDesign/Advertising";
import CopywritingServices from "../pages/DesignService/CopywritingServices/CopywritingServices";
import GraphicDesign from "../pages/DesignService/GraphicDesign/GraphicDesign";
import LogoandBranding from "../pages/DesignService/LogoandBranding/LogoandBranding";
import SocialMedia from "../pages/DesignService/SocialMedia/SocialMedia";
import WebsiteDesign from "../pages/DesignService/WebsiteDesign/WebsiteDesign";
import AIML from "../pages/ITandWebService/AIML/AIML";
import DigitalMarketing from "../pages/ITandWebService/DigitalMarketing/DigitalMarketing";
import ITSupport from "../pages/ITandWebService/ITSupport/ITSupport";
import MobileApp from "../pages/ITandWebService/MobileApp/MobileApp";
import WebApp from "../pages/ITandWebService/WebApp/WebApp";
import WebsiteBuilding from "../pages/ITandWebService/WebsiteBuilding/WebsiteBuilding";
import Amazon from "../pages/SellingPlatforms/Amazon/Amazon";
import EBay from "../pages/SellingPlatforms/EBay/EBay";
import ECampaigners from "../pages/SellingPlatforms/ECampaigners/ECampaigners";
import OnBuy from "../pages/SellingPlatforms/OnBuy/OnBuy";
import SocialMarketing from "../pages/SellingPlatforms/SocialMarketing/SocialMarketing";
import BusinessOpportunitie from "../pages/SalesAndMarketing/BusinessOpportunitie/BusinessOpportunitie";
import DataDiscovery from "../pages/SalesAndMarketing/DataDiscovery/DataDiscovery";
import LinkedInServices from "../pages/SalesAndMarketing/LinkedInServices/LinkedInServices";
import ProductSourcing from "../pages/SalesAndMarketing/ProductSourcing/ProductSourcing";
import Accountants from "../pages/NetworkPartners/Accountants/Accountants";
import SalesAgents from "../pages/NetworkPartners/SalesAgents/SalesAgents";
import Solicitorsn from "../pages/NetworkPartners/Solicitorsn/Solicitorsn";
import Telecommunications from "../pages/NetworkPartners/Telecommunications/Telecommunications";
import VideoProduction from "../pages/NetworkPartners/VideoProduction/VideoProduction";
import Education from "../pages/RecruitmentServices/Education/Education";
import Finance from "../pages/RecruitmentServices/Finance/Finance";
import Hospitality from "../pages/RecruitmentServices/Hospitality/Hospitality";
import MedicalHealthcare from "../pages/RecruitmentServices/MedicalHealthcare/MedicalHealthcare";
import RecruitmentServices from "../pages/RecruitmentServices/RecruitmentServices/RecruitmentServices";
import Salespeople from "../pages/RecruitmentServices/Salespeople/Salespeople";
import TechnologyIndustry from "../pages/RecruitmentServices/TechnologyIndustry/TechnologyIndustry";
import Industries from "../pages/Industries/Industries";
import DesignService from "../pages/DesignService/DesignService";
import ITandWebService from "../pages/ITandWebService/ITandWebService";
import SellingPlatforms from "../pages/SellingPlatforms/SellingPlatforms";
import SalesAndMarketing from "../pages/SalesAndMarketing/SalesAndMarketing";
import NetworkPartners from "../pages/NetworkPartners/NetworkPartners";
import RecruitmentServicesDoc from "../pages/RecruitmentServices/RecruitmentServicesDoc";

export const Router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <ErrorPage />
  },
  {
    path: "/awards",
    element: <Awards />,
    errorElement: <ErrorPage />
  },
  {
    path: "/careers",
    element: <Careers />,
    errorElement: <ErrorPage />
  },
  {
    // path: "why-us/:contactId",
    path: "/why-us",
    element: <WhyUs />,
    errorElement: <ErrorPage />
  },
  {
    path: "/about-us",
    element: <Aboutus />,
    errorElement: <ErrorPage />
  },
  {
    path: "/blog-single-post",
    element: <BlogSinglePost />,
    errorElement: <ErrorPage />
  },
  {
    path: "/case-studies-grid",
    element: <CaseStudiesGrid />,
    errorElement: <ErrorPage />
  },
  {
    path: "/case-studies-single",
    element: <CaseStudiesSingle />,
    errorElement: <ErrorPage />
  },
  {
    path: "/contact-us",
    element: <ContactUs />,
    errorElement: <ErrorPage />
  },
  {
    path: "/faqs",
    element: <Faqs />,
    errorElement: <ErrorPage />
  },
  {
    path: "/it-solutions-single-page",
    element: <ItSolutionsSinglePage />,
    errorElement: <ErrorPage />
  },
  {
    path: "/leadership-team",
    element: <LeadershipTeam />,
    errorElement: <ErrorPage />
  },
  {
    path: "/our-blog",
    element: <OurBlog />,
    errorElement: <ErrorPage />
  },
  {
    path: "/pricing",
    element: <Pricing />,
    errorElement: <ErrorPage />
  },
  {
    path: "/request-quote",
    element: <RequestQuote />,
    errorElement: <ErrorPage />
  },
  {
    path: "/it-solutions",
    element: <ItSolutions />,
    errorElement: <ErrorPage />
  },
  {
    path: "/packaging",
    element: <Packaging />,
    errorElement: <ErrorPage />
  },
  {
    path: "/advertising",
    element: <Advertising />,
    errorElement: <ErrorPage />
  },
  {
    path: "/Copy-writing-Services",
    element: <CopywritingServices />,
    errorElement: <ErrorPage />
  },
  {
    path: "/Graphic-Design",
    element: <GraphicDesign />,
    errorElement: <ErrorPage />
  },
  {
    path: "/Logo-Branding",
    element: <LogoandBranding />,
    errorElement: <ErrorPage />
  },
  {
    path: "/Social-media",
    element: <SocialMedia />,
    errorElement: <ErrorPage />
  },
  {
    path: "/Website-Design",
    element: <WebsiteDesign />,
    errorElement: <ErrorPage />
  },
  // It and Web Service Routes
  {
    path: "/AI-ML",
    element: <AIML />,
    errorElement: <ErrorPage />
  },
  {
    path: "/Digital-Marketing",
    element: <DigitalMarketing />,
    errorElement: <ErrorPage />
  },
  {
    path: "/IT-Support",
    element: <ITSupport />,
    errorElement: <ErrorPage />
  },
  {
    path: "/Mobile-App",
    element: <MobileApp />,
    errorElement: <ErrorPage />
  },
  {
    path: "/Web-App",
    element: <WebApp />,
    errorElement: <ErrorPage />
  },
  {
    path: "/Website-Building",
    element: <WebsiteBuilding />,
    errorElement: <ErrorPage />
  },
  // Selling Platforms Routes
  {
    path: "/Amazon",
    element: <Amazon />,
    errorElement: <ErrorPage />
  },
  {
    path: "/eBay",
    element: <EBay />,
    errorElement: <ErrorPage />
  },
  {
    path: "/e-Campaigners",
    element: <ECampaigners />,
    errorElement: <ErrorPage />
  },
  {
    path: "/OnBuy",
    element: <OnBuy />,
    errorElement: <ErrorPage />
  },
  {
    path: "/Social-Marketing",
    element: <SocialMarketing />,
    errorElement: <ErrorPage />
  },
  // Sales & Marketing Routes
  {
    path: "/Business-Opportunitie",
    element: <BusinessOpportunitie />,
    errorElement: <ErrorPage />
  },
  {
    path: "/Data-Discovery",
    element: <DataDiscovery />,
    errorElement: <ErrorPage />
  },
  {
    path: "/LinkedIn-Services",
    element: <LinkedInServices />,
    errorElement: <ErrorPage />
  },
  {
    path: "/Product-Sourcing",
    element: <ProductSourcing />,
    errorElement: <ErrorPage />
  },
  // Network and Partners Routes
  {
    path: "/Accountants",
    element: <Accountants />,
    errorElement: <ErrorPage />
  },
  {
    path: "/Sales-Agents",
    element: <SalesAgents />,
    errorElement: <ErrorPage />
  },
  {
    path: "/Solicitorsn",
    element: <Solicitorsn />,
    errorElement: <ErrorPage />
  },
  {
    path: "/Telecommunications",
    element: <Telecommunications />,
    errorElement: <ErrorPage />
  },
  {
    path: "/Video-Production",
    element: <VideoProduction />,
    errorElement: <ErrorPage />
  },
  // Recruitment Services Routes
  {
    path: "/Education",
    element: <Education />,
    errorElement: <ErrorPage />
  },
  {
    path: "/Finance",
    element: <Finance />,
    errorElement: <ErrorPage />
  },
  {
    path: "/Hospitality",
    element: <Hospitality />,
    errorElement: <ErrorPage />
  },
  {
    path: "/Medical-Healthcare",
    element: <MedicalHealthcare />,
    errorElement: <ErrorPage />
  },
  {
    path: "/Recruitment-Services",
    element: <RecruitmentServices />,
    errorElement: <ErrorPage />
  },
  {
    path: "/Salespeople",
    element: <Salespeople />,
    errorElement: <ErrorPage />
  },
  {
    path: "/Technology-Industry",
    element: <TechnologyIndustry />,
    errorElement: <ErrorPage />
  },
  {
    path: "/industry",
    element: <Industries />,
    errorElement: <errorElement />
  },

  // Main Category Route
  {
    path: '/Design-Services',
    element: <DesignService />,
    errorElement: <ErrorPage />
  },
  {
    path: '/IT-Web-Services',
    element: <ITandWebService />,
    errorElement: <ErrorPage />
  },
  {
    path: '/Selling-Platforms',
    element: <SellingPlatforms />,
    errorElement: <ErrorPage />
  },
  {
    path: '/Sales-Marketing',
    element: <SalesAndMarketing />,
    errorElement: <ErrorPage />
  },
  {
    path: '/Network-Partners',
    element: <NetworkPartners />,
    errorElement: <ErrorPage />
  },
  {
    path: '/Recruitment',
    element: <RecruitmentServicesDoc />,
    errorElement: <ErrorPage />
  },

]);

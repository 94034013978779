/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import logo from './assets/Onbuy.jpg';
import PriceTableModal from "../../../../components/Modal/PriceTableModal";



export default function OnBuySub() {
    return (
        <>
            <section className="about-layout4 pt-80 pb-0" id="OnBuySub">
                <h2 className="heading__title text-center mb-20 text-uppercase">
                    On Buy
                </h2>
                <h3 className="heading__subtitle_para mb-20">
                    At Business Savvy UK, we know that staying ahead of the
                    Curve is essential for businesses to thrive. That's why we offer OnBuy seller consulting services to help you take advantage of this emerging marketplace. With our team of experts by your side, we'll help you optimize your OnBuy presence and create a successful marketing strategy that meets your unique business needs.
                </h3>
                <div className="container">
                    <div className="row flex-lg-row flex-sm-column-reverse">
                        {/* <!-- First column for image --> */}
                        <div className="col-lg-6">
                            <div className="heading mb-40">
                                <div className="d-flex align-items-center">
                                    <div className="divider divider-primary mr-30"></div>
                                    <h2 className="heading__subtitle mb-0">We don't believe in</h2>
                                </div>
                                <h3 className="heading__subtitle mb-10">
                                    one-size-fits-all solutions. Instead, we work closely with OnBuy sellers to understand their business, audience, and competition. Then, armed with this knowledge, we develop a custom plan that helps you stand out in the crowded marketplace, increase sales, and grow your business.
                                </h3>

                                <p>
                                    Our OnBuy seller consulting services cover everything from optimizing product listings to developing a pricing strategy and creating a marketing plan that drives traffic and increases conversions. We use data-driven insights to make informed decisions that take your business to the next level.
                                </p>

                                <p>
                                    Partnering with us means partnering with a team of professionals committed to your success. We have a proven track record of helping sellers achieve their goals on OnBuy and other marketplaces. Let us help you establish a presence on this exciting new platform and reach new heights of success.
                                </p>

                                <p>
                                    OnBuy offers several advantages over Amazon and eBay, such as lower fees, transparent seller ratings, and a more straightforward selling process. With a fixed commission rate of just 5-9%, OnBuy's commission rates are significantly lower than Amazon's, making it a cost-effective option for sellers. Unlike other platforms, OnBuy doesn't prioritize big brands over smaller sellers, so every seller has an equal chance of success. Moreover, OnBuy provides transparent seller ratings, ensuring buyers purchase from reputable sellers.
                                </p>

                                <p>
                                    Overall, OnBuy provides a fresh and fair alternative to established platforms, making it an exciting new option for sellers looking to grow their business.
                                </p>

                            </div>
                            <div>
                                <button className="pricing__footer">
                                    <PriceTableModal />
                                </button>
                            </div>
                        </div>
                        {/* <!-- Second column for content --> */}
                        <div className="col-lg-6">
                            <div className="about__img">
                                <img src={logo} alt="On buy" />
                            </div>

                        </div>

                    </div>
                </div>
            </section>
        </>
    );
}

import React from "react";
import img from "../../../../assets/images/about/7.jpg";
import img3 from "../../../../assets/images/about/singnture.png";
import SideBarAccordion from "../../../../components/SideBarAccordion/SideBarAccordion";

export default function SolutionsLayoutThree() {
  return (
    <section id="about" className="about-layout2 animated-Progressbars">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
            <div className="heading mb-40">
              <div className="d-flex align-items-center mb-20">
                <div className="divider divider-primary mr-30" />
                <h2 className="heading__subtitle mb-0">
                  Curate And Efficient Solution Of Hospitality
                </h2>
              </div>
              <h3 className="heading__title mb-40">
                From hotels to restaurants, bars to cafes, and more, the industry always needs skilled professionals who can deliver exceptional service and help businesses succeed.
              </h3>
            </div>
          </div>
          {/* /.col-xl-5 */}
        </div>
        {/* /.row */}
        <div className="row about__Text">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
            <div className="offset-xl-2 position-relative">
              <i className="icon-quote" />
              <p>At Business Savvy UK, we provide bespoke staffing solutions for the hospitality industry, helping businesses find the right people to fill a range of roles. From labourers to chefs to management, we have the expertise to source and recruit the perfect candidates to meet your needs.
                <br />
                <br />
                Our team of experienced recruiters deeply understands the hospitality industry and works closely with businesses to find the best staffing solutions for their unique needs. We know the challenges companies face in the industry, and we strive to provide our clients with staffing solutions that meet their needs and exceed their expectations.
                <br />
                <br />
                We can supply staffs for all aspects of the hospitality industry, including front-of-house, back-of-house, and management positions. Our candidates are pre-screened, tested, and vetted to ensure they have the skills and experience required to deliver exceptional service and contribute to the success of your business.
                <br />
                <br />
                Our services are tailored to your needs, with flexible staffing options available to help you manage your staffing requirements more effectively. We offer temporary, permanent, and contract staffing solutions and can work with you to find the best option for your business.
                <br />
                <br />
                Partnering with Business Savvy UK for your staffing needs means working with a team of professionals dedicated to your success. We understand the importance of staffing in the hospitality industry and are committed to providing the best possible service to help your business succeed.
                <br />
                <br />
                Whether you need a team of chefs for a special event, a group of labourers for a renovation project, or management staff to help run your business, we can help.
                Contact us today to learn more about our bespoke staffing solutions for the hospitality industry.
              </p>
            </div>
            <div className="about__img mt-80 mb-30">
              <img src={img} alt="about" />
            </div>
            {/* /.about__img*/}
            <div className="video__btn-wrapper">
              <a
                className="video__btn video__btn-white popup-video"
                href="https://www.youtube.com/watch?v=nrJtHemSPW4"
              >
                <div className="video__player">
                  <i className="fa fa-play" />
                </div>
                <span className="video__btn-title">
                  Watch Our Presentation!
                </span>
              </a>
            </div>
            {/* /.video__btn-wrapper*/}
          </div>
          {/* /.col-xl-6 */}
          <SideBarAccordion />
          {/* /.col-xl-5 */}
        </div>
        {/* /.row */}
      </div>
      {/* /.container */}
    </section>
  );
}

import React from 'react';
import SingleServiceItems from './SingleServiceItems';
import SalesAgentsSub from './NetworkPartnersAllSub/SalesAgentsSub/SalesAgentsSub';
import AccountantsSub from './NetworkPartnersAllSub/AccountantsSub/AccountantsSub';
import SolicitorsSub from './NetworkPartnersAllSub/SolicitorsSub/SolicitorsSub';
import VideoProductionSub from './NetworkPartnersAllSub/VideoProductionSub/VideoProductionSub';
import TelecommunicationsSub from './NetworkPartnersAllSub/TelecommunicationsSub/TelecommunicationsSub';


const BrochureSection = () => {
    return (
        <section className="services-layout1 bg-gray pt-130 pb-90">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-6 offset-lg-3">
                        <div className="heading text-center mb-50">
                            <h2 className="heading__subtitle">
                                Business Savvy UK
                            </h2>
                            <h3 className="heading__title">
                                Network Partners Single Category items. Selected your affordable Service.
                            </h3>
                        </div>
                        {/* /.heading */}
                    </div>
                    {/* /.col-lg-8 */}
                </div>
                <>
                    <SingleServiceItems />
                </>
                {/* sub */}
                <SalesAgentsSub />
                {/* sub Card */}
                {/* <Pricing /> */}
                {/* Accountants sub */}
                <AccountantsSub />
                {/* Accountants sub Card */}
                {/* <Pricing /> */}
                {/* solicitor sub */}
                <SolicitorsSub />
                {/* solicitor sub Card */}
                {/* <Pricing /> */}
                {/* telecommunication sub */}
                <TelecommunicationsSub />
                {/* telecommunication sub Card */}
                {/* <Pricing /> */}
                {/* vide production sub */}
                <VideoProductionSub />
                {/* vide production sub Card */}
                {/* <Pricing /> */}



            </div>
            {/* /.container */}
        </section>
    );
};

export default BrochureSection;
import React from "react";
import OfferingSlider from "../../../components/slider/OfferingSlider";
import { Link } from "react-router-dom";

export default function ServicesCarouselSection() {
  return (
    <section className="services-layout2 services-carousel pt-0 pb-0 bg-gray">

      <div className="container">
        <div className="row heading mb-40">
          <div className="col-12">
            <div className="d-flex align-items-center">
              <div className="divider divider-primary mr-30" />
              <h2 className="heading__subtitle mb-0">
                Business savvy services
              </h2>
            </div>
          </div>
          <div className="col-sm-12 col-md-12 ">
            <h3 className="heading__title">
              Empowering your growth.
            </h3>
            <p className="heading__desc">
              At Business Savvy UK, we offer a range of services to help businesses
              grow and succeed. From design and web services to selling platforms,
              we provide tailored solutions that meet each business's unique needs.
              Our experts are dedicated to empowering your growth and ensuring your
              success.
            </p>
          </div>
          {/* /col-lg-5 */}
          <div className="col-sm-12 col-md-12 col-lg-5">

          </div>
          {/* /.col-lg-6 */}
        </div>
        {/* /.row */}
        <div className="row">
          <div className="col-12">

            {/* /.carousel */}
          </div>
          {/* <p className="heading__desc_service">
          Our services provide businesses with a robust online presence that attracts
          new customers and retains existing ones.
          </p> */}
          {/* /.col-12 */}
        </div>
        {/* /.row */}
        <div className="row heading__desc ">

          {/* /.col-12 */}
        </div>
        {/* /.row */}
      </div>
      {/* /.container */}
      <div className="service-slider">
        <div>
          <OfferingSlider />
          <p className="heading__desc_service mt-0 mb-20">
            Our services provide businesses with a robust online presence that attracts
            new customers and retains existing ones.
          </p>
        </div>
      </div>
      {/* <div className="container">
      <div className="row">
      <p className="heading__desc_service mt-0 mb-20">
          Our services provide businesses with a robust online presence that attracts
          new customers and retains existing ones.
          </p>
      </div>
      </div> */}
    </section>
  );
}

import React, { useEffect, useState } from "react";
import Headers from "../../components/header/Headers";
import SingleBlog from "./blogSinglePostSections/SingleBlog";
import TitlePage from "./blogSinglePostSections/TitlePage";
import Footer from "../../components/footer/Footer";
import DarkHeaders from "../../components/header/DarkHeaders";

export default function BlogSinglePost() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <DarkHeaders />
      <TitlePage />
      <SingleBlog />
      <Footer />
    </>
  );
}

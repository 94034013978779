/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import logo from './assets/phone.jpg';
import PriceTableModal from "../../../../components/Modal/PriceTableModal";

export default function TelecommunicationsSub() {
    return (
        <>
            <section className="about-layout4 pt-80 pb-0" id="TelecommunicationsSub">
                <h2 className="heading__title text-center mb-20 text-uppercase">
                    Telecommunications
                </h2>
                <h3 className="heading__subtitle_para mb-20">
                    In addition to our consultancy services Business Savvy UK also offers project management and implementation services to ensure that your telecoms projects are delivered on time and within budget.
                </h3>
                <div className="container">
                    <div className="row flex-lg-row">
                        {/* <!-- First column for image --> */}
                        <div className="col-lg-6 mb-10">
                            <div className="about__img">
                                <img src={logo} alt="Telecommunications" />
                            </div>

                        </div>

                        {/* <!-- Second column for content --> */}
                        <div className="col-lg-6 mt-10">
                            <div className="heading mb-40">
                                <div className="d-flex align-items-center">
                                    <div className="divider divider-primary mr-30"></div>
                                    <h2 className="heading__subtitle mb-0">Partnering with us</h2>
                                </div>
                                <h3 className="heading__subtitle mb-10">
                                    means working with a company associate dedicated to delivering the best telecoms solutions for your business. Our team of experts takes the time to understand your needs and works with you to create a bespoke solution that meets your specific requirements. With extensive knowledge of the telecommunications industry, we can provide unbiased advice on the best suppliers and technologies available.
                                </h3>

                                <p>
                                    Our aim is to help businesses achieve maximum value from their telecoms investments while improving efficiency and reducing costs. Choose Business Savvy UK as your trusted telecoms partner and let us help you streamline your telecoms services and achieve your business goals.
                                </p>

                            </div>
                            <div>
                                <button className="pricing__footer">
                                    <PriceTableModal />
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    );
}
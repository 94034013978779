/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import logo from './assets/Packaging.jpg';
import PriceTableModal from "../../../../components/Modal/PriceTableModal";



export default function PackagingSub() {
    return (
        <>
            <section className="about-layout4 pt-80 pb-0" id="packagingSub">
                <h2 className="heading__title text-center mb-20 text-uppercase">
                    Packaging
                </h2>
                <h3 className="heading__subtitle_para mb-20">
                    When it comes to packaging design it's not just about creating something that looks good. Instead, it’s about creating a layout that conveys your brand message and sets your product apart from the competition.
                </h3>
                <div className="container">
                    <div className="row flex-lg-row flex-sm-column-reverse">
                        {/* <!-- Second column for content --> */}
                        <div className="col-lg-6">
                            <div className="heading mb-40">
                                <div className="d-flex align-items-center">
                                    <div className="divider divider-primary mr-30"></div>
                                    <h2 className="heading__subtitle mb-0">
                                        At Business Savvy UK
                                    </h2>
                                </div>
                                <h3 className="heading__subtitle mb-10">
                                    our expert designers can help you create packaging that looks not only great but also communicates essential information about your product.
                                </h3>
                                <p>
                                    We understand that packaging is often a customer's first touch point with a product, and it's crucial to make a lasting impression. That's why our team works closely with you to develop a design that reflects your brand's unique personality and values. We take the time to understand your target audience and the message you want to convey, ensuring that your packaging design resonates with your customers.
                                </p>
                                <p>
                                    Our packaging design services cover everything from the initial concept to the finished product. We'll work with you every step of the way, ensuring that your design meets your specific needs and helps you achieve your business goals. We also consider practical considerations, such as the size and shape of the packaging, materials used, and printing techniques, to ensure your design is functional and visually appealing.
                                </p>

                                <p>
                                    Whether you're looking to create new packaging for an existing product or launch a new one, we can help. Let our team at Business Savvy UK bring your vision to life and create a design that stands out on the shelves and makes a lasting impression on your customers.
                                </p>
                            </div>
                            <div>
                                <button className="pricing__footer">
                                    <PriceTableModal />
                                </button>
                            </div>
                        </div>
                        {/* <!-- First column for image --> */}
                        <div className="col-lg-6">
                            <div className="about__img">
                                <img src={logo} className="img-fluid" alt="Packaging" />
                            </div>

                        </div>

                    </div>
                </div>
            </section>
        </>
    );
}

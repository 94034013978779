import React from "react";
import img2 from "../../../assets/images/testimonials/thumbs/1.png";
import img3 from "../../../assets/images/about/blockquote-img.jpg";

export default function AboutLayoutOne() {
  return (
    <section className="about-layout1">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-6">
            <div className="heading mb-30">
              <div className="d-flex align-items-center mb-20">
                <div className="divider divider-primary mr-30" />
                <h2 className="heading__subtitle mb-0">
                  Our team comprises experienced professionals from various backgrounds
                </h2>
              </div>
              <h3 className="heading__title mb-40">
                including business management, marketing, IT, and finance.
              </h3>
            </div>
            {/* /heading */}
            <div className="position-relative offset-xl-1">
              <i className="icon-quote" />
              <p className="mb-40">
                We have the skills and expertise to help businesses of all sizes and types overcome their challenges and achieve their goals.
                <br />
                <br />
                Our team understands the complexities and challenges of running a business, and we are committed to using our expertise to help our clients succeed. We believe that the success of our clients measures our success, and we take great pride in seeing our clients grow and thrive with our support.
                <br />
                <br />
                One of our greatest strengths is our network. Over the years, we have built a vast network of partners and industry experts, allowing us to connect our clients with the right people and resources to achieve their goals. In addition, we are continually expanding our network and staying up to date with the latest trends and technologies to provide the best possible solutions for our clients.
                <br />
                <br />
                At Business Savvy, we believe in building long-term relationships with our clients. We take the time to understand their unique needs and challenges and work closely with them to develop tailored solutions that meet their specific goals. Our hands-on approach ensures that we are fully invested in our client's success and are with them every step of the way.
                <br />
                <br />
                Whether you are a start-up looking to get off the ground or an established business looking to expand, we have the expertise and resources to help you achieve your goals. Don't just take our word for it - our track record of success speaks for itself.
                <br />
                <br />
                Let us help you take your business to the next level.

              </p>
              <ul className="list-items list-items-layout2 list-unstyled d-flex flex-wrap list-horizontal mb-50">
                <li>Design Services</li>
                <li>IT & Web Service</li>
                <li>Selling Platforms</li>
                <li>Sales & Marketing</li>
                <li>Network Partners</li>
                <li>Recruitment Services</li>
              </ul>
            </div>
          </div>
          {/* /.col-lg-6 */}
          <div className="col-sm-12 col-md-12 col-lg-5 offset-lg-1">
            <div className="about__img mb-40">
              <img src={img3} alt="about" />
              <blockquote className="blockquote d-flex align-items-end mb-0">
                <div className="blockquote__avatar">
                  <img src={img2} alt="thumb" />
                </div>
                <div className="blockquote__content">
                  <h4 className="blockquote__title mb-0">
                    At Business Savvy, we believe in building long-term relationships with our clients. We take the time to understand their unique needs and challenges and work closely.
                  </h4>
                </div>
                {/* /.blockquote__content */}
              </blockquote>
              {/* /.blockquote */}
            </div>
            {/* /.about-img */}
          </div>
          {/* /.col-lg-6 */}
        </div>
        {/* /.row */}
      </div>
      {/* /.container */}
    </section>
  );
}

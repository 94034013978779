/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import logo from './assets/hospitality.jpg';
import PriceTableModal from "../../../../components/Modal/PriceTableModal";


export default function HospitalitySub() {
    return (
        <>
            <section className="about-layout4 pt-80 pb-0" id="HospitalitySub">
                <h2 className="heading__title text-center mb-20 text-uppercase">
                    Hospitality
                </h2>
                <h3 className="heading__subtitle_para mb-20">
                    The hospitality industry is a vital part of our society and economy, providing opportunities for people to enjoy the best life offers. From hotels to restaurants, bars to cafes, and more, the industry always needs skilled professionals who can deliver exceptional service and help businesses succeed.
                </h3>
                <div className="container">
                    <div className="row flex-lg-row flex-sm-column-reverse">
                        {/* <!-- First column for image --> */}
                        <div className="col-lg-6">
                            <div className="heading mb-40">
                                <div className="d-flex align-items-center">
                                    <div className="divider divider-primary mr-30"></div>
                                    <h2 className="heading__subtitle mb-0">
                                        At Business Savvy UK
                                    </h2>
                                </div>
                                <h3 className="heading__subtitle mb-10">
                                    we provide bespoke staffing solutions for the hospitality industry, helping businesses find the right people to fill a range of roles. From labourers to chefs to management, we have the expertise to source and recruit the perfect candidates to meet your needs.
                                </h3>
                                <p>
                                    Our team of experienced recruiters deeply understands the hospitality industry and works closely with businesses to find the best staffing solutions for their unique needs. We know the challenges companies face in the industry, and we strive to provide our clients with staffing solutions that meet their needs and exceed their expectations.
                                </p>

                                <p>
                                    We can supply staffs for all aspects of the hospitality industry, including front-of-house, back-of-house, and management positions. Our candidates are pre-screened, tested, and vetted to ensure they have the skills and experience required to deliver exceptional service and contribute to the success of your business.
                                </p>

                                <p>
                                    Our services are tailored to your needs, with flexible staffing options available to help you manage your staffing requirements more effectively. We offer temporary, permanent, and contract staffing solutions and can work with you to find the best option for your business.
                                </p>
                                <p>
                                    Partnering with Business Savvy UK for your staffing needs means working with a team of professionals dedicated to your success. We understand the importance of staffing in the hospitality industry and are committed to providing the best possible service to help your business succeed.
                                </p>
                                <p>
                                    Whether you need a team of chefs for a special event, a group of labourers for a renovation project, or management staff to help run your business, we can help.
                                    Contact us today to learn more about our bespoke staffing solutions for the hospitality industry.

                                </p>

                            </div>
                            <div>
                            <button className="pricing__footer">
                                    <PriceTableModal />
                                </button>
                            </div>
                        </div>
                        {/* <!-- Second column for content --> */}
                        <div className="col-lg-6">
                            <div className="about__img">
                                <img src={logo} alt="Hospitality" />
                            </div>

                        </div>

                    </div>
                </div>
            </section>
        </>
    );
}

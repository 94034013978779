import React, { useState } from "react";
import Minislider from "../../../components/slider/Minislider";
import Aboutone from "../../../assets/images/about/2.jpg";
import VideoPlayer from "../../../components/VideoPlayer/VideoPlayer";
import Videoss from "../../../assets/as.mp4";
import { Link } from "react-router-dom";

export default function AboutSection() {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <section className="about-layout4 pt-130 pb-0">
        <div className="container">
          <div className="row heading">
            <div className="col-12">
              <div className="d-flex align-items-center mb-20">
                <div className="divider divider-primary mr-30" />
                <h2 className="heading__subtitle mb-0">
                  Your business, our expertise - together, we succeed.
                </h2>
              </div>
            </div>
            {/* /.col-12 */}
            <div className="col-sm-12 col-md-12 col-lg-6">
              <h3 className="heading__title mb-40">
                Are you starting or growing a business? Partner with us for
                success!
              </h3>
            </div>
            {/* /.col-lg-6 */}
            <div className="col-sm-12 col-md-12 col-lg-6">
              <p>
                Starting a new business or growing an existing one can be
                challenging. At Business Savvy UK, we understand both
                complexities and are committed to helping companies achieve
                their goals.
              </p>
              <p>
                Our experienced team has a wealth of knowledge and expertise in
                supporting start-ups and established businesses. In addition, we
                provide various services that help businesses navigate their
                challenges, avoid common pitfalls and achieve success.
              </p>
            </div>
            {/* /.col-lg-6 */}
          </div>
          {/* /.row */}
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-6">
              <div className="about__img">
                <img src={Aboutone} alt="about" />
              </div>
              {/* /.about-img */}
              <div
                onClick={() => setIsOpen(true)}
                className="video__btn-wrapper"
              >
                <Link>
                  <a
                    className="video__btn video__btn-white popup-video"
                    href="#"
                  >
                    <div className="video__player">
                      <i className="fa fa-play" />
                    </div>
                    <span className="video__btn-title">
                      Watch Our Presentation!
                    </span>
                  </a>
                </Link>
              </div>
            </div>
            {/* /.col-lg-6 */}
            <div className="col-sm-12 col-md-12 col-lg-6 d-flex flex-column justify-content-between">
              <ul className="list-items list-items-layout2 list-horizontal list-unstyled d-flex flex-wrap mt-40">
                <li>Design Services</li>
                <li>Selling Platforms </li>
                <li>Network partners </li>
                <li>Recruitment Service</li>
                <li>IT/Web Services</li>
                <li>Sales & Marketing</li>
                <li>Exhibitions</li>
                <br />
                <p className="col-sm-12 col-md-12 ">
                  Whether you're just starting or seeking growth, our team is
                  committed to helping you achieve success. We are always
                  available for a free, no-obligation consultation to discuss
                  your business needs and how we can help.
                </p>
              </ul>
              <div className="clients">
                <p className="text__link text-center">Trusted By The World's</p>
                <Minislider />
                {/* /.carousel */}
              </div>
            </div>
            {/* /.col-lg-6 */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </section>

      <VideoPlayer VideoUrl={Videoss} isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
}

import React from "react";
import img from "../../../../assets/images/about/7.jpg";
import SideBarAccordion from "../../../../components/SideBarAccordion/SideBarAccordion";

export default function SolutionsLayoutThree() {
  return (
    <section id="about" className="about-layout2 animated-Progressbars">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
            <div className="heading mb-40">
              <div className="d-flex align-items-center mb-20">
                <div className="divider divider-primary mr-30" />
                <h2 className="heading__subtitle mb-0">
                  Curate And Efficient Solution Of Data Discovery
                </h2>
              </div>
              <h3 className="heading__title mb-40">
                The ability to target the right audience with the right message can make or break a business.
              </h3>
            </div>
          </div>
          {/* /.col-xl-5 */}
        </div>
        {/* /.row */}
        <div className="row about__Text">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
            <div className="offset-xl-2 position-relative">
              <i className="icon-quote" />
              <p>. However, with the vast amount of data available, it can be a challenge to identify and target the right contacts. This is where Business Savvy UK's Data Discovery service comes in.
                Our Data Discovery service provides businesses with a comprehensive and targeted approach to data discovery. We deliver cleansed, verified databases to help companies target the right audience for their products and services, avoiding wasting time and resources associated with targeting the wrong contacts.
                Our service includes.
                <br />
                <br />
                -	Contact Discovery<br />
                -	Opt-in Data<br />
                -	Account Profiling<br />
                -	Data Cleansing/Enrichment.<br />
                <br />
                <br />
                We cover a wide range of regions worldwide, including.
                <br />
                <br />
                ●	the South Asian Association for Regional Cooperation (SAARC),<br />
                ●	Asia-Pacific (APAC), Australia and New Zealand (ANZ),<br />
                ●	Gulf Cooperation Council (GCC),<br />
                ●	Middle East, and Africa,<br />
                ●	Europe (BENELUX, Nordic,<br />
                ●	Central Europe, Eastern Europe, Western Europe),<br />
                ●	United Kingdom (UK),<br />
                ●	North America (United States of America and Canada),<br />
                ●	Latin America/South America.<br />
                <br />
                <br />
                At Business Savvy UK, we understand that the information landscape constantly evolves, leading to continual change and data decay. Therefore, we aim to help increase growth and prosperity by supplying our clients with intelligent, cleansed, and enriched data services. Our team of experts validates and prepares required data fields for our clients to prospect and explore new viable and profitable revenues.
                <br />
                <br />
                With a proactive approach, determination, and optimized lead enrichment data, we can help you revitalize and target your business services to the appropriate audience, thereby increasing revenue. Our core strengths and values are data-driven, and we are dedicated to helping businesses unlock the power of their data.
                <br />
                <br />
                Partnering with Business Savvy UK for your Data Discovery needs means working with a team of professionals who understand the importance of accurate and up-to-date data. We take the time to understand your business, your target audience, and your objectives to provide you with custom data solutions that meet your specific needs.
                Contact us today to learn how we can help you target the right contacts and unlock the power of your data.
              </p>
            </div>
            <div className="about__img mt-80 mb-30">
              <img src={img} alt="about" />
            </div>
            {/* /.about__img*/}
            <div className="video__btn-wrapper">
              <a
                className="video__btn video__btn-white popup-video"
                href="https://www.youtube.com/watch?v=nrJtHemSPW4"
              >
                <div className="video__player">
                  <i className="fa fa-play" />
                </div>
                <span className="video__btn-title">
                  Watch Our Presentation!
                </span>
              </a>
            </div>
            {/* /.video__btn-wrapper*/}
          </div>
          {/* /.col-xl-6 */}
          <SideBarAccordion />
          {/* /.col-xl-5 */}
        </div>
        {/* /.row */}
      </div>
      {/* /.container */}
    </section>
  );
}

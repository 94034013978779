import React from "react";
import { Link } from "react-router-dom";
import Home from "../../pages/Home/Home";
import  error from  "../../assets/images/banners/2.jpg"

const ErrorPage = () => {
  return (
  <div className="page-404 py-0 bg-img-error-page bg-overlay">

    
    <div className="container ">
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center align-items-center vh-100">
          <div className="error-wrapper text-center">
            <h1 className="error-code">404</h1>
            <p className="error__desc">The page requested couldn't be found. This could be a spelling error in the
              URL or a removed page.
            </p>
            <Link to="/" className="btn btn__primary btn__icon">
              <span>Back To Home</span> <i className="icon-arrow-right" />
            </Link>
          </div>
        </div>
      </div>
    </div>
    
  </div>

  )
};

export default ErrorPage;

import React, { useState, useEffect } from "react";
import { Link } from "react-scroll";
import './SecondaryNav.css'

export default function SecondaryNav() {
    const [scroll, setScroll] = useState(false);
    const [windowSize, setWindowSize] = useState(getWindowSize());
    useEffect(() => {
        const handleScroll = (event) => {
            if (windowSize.innerWidth >= 992) {
                setScroll(window.scrollY > 100);
            }
        };
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [windowSize]);

    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }

    return (
        <section
            className={
                scroll
                    ? "secondary-nav secondary-nav-sticky sticky-top py-0"
                    : "secondary-nav sticky-top py-0"
            }
        >
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <nav className="nav nav-tabs justify-content-center">
                            {/* This Link tag is from react scroll package */}
                            <Link to="SalesAgentsSub" className="nav__link cursor-pointer" spy={true} smooth={true} offset={-100} duration={500}>
                                Sales Agents
                            </Link>
                            <Link to="AccountantsSub" className="nav__link cursor-pointer" spy={true} smooth={true} offset={-100} duration={500}>
                                Accountants
                            </Link>
                            <Link to="SolicitorsSub" className="nav__link cursor-pointer" spy={true} smooth={true} offset={-100} duration={500}>
                                Solicitors
                            </Link>
                            <Link to="TelecommunicationsSub" className="nav__link cursor-pointer" spy={true} smooth={true} offset={-100} duration={500}>
                                Telecommunications
                            </Link>
                            <Link to="VideoProductionSub" className="nav__link cursor-pointer" spy={true} smooth={true} offset={-100} duration={500}>
                                Video Production
                            </Link>

                        </nav>
                    </div>
                    {/* /.col-12 */}
                </div>
                {/* /.row */}
            </div>
            {/* /.container */}
        </section>
    );
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import logo from './assets/accountant.jpg';
import PriceTableModal from "../../../../components/Modal/PriceTableModal";

export default function AccountantsSub() {
    return (
        <>
            <section className="about-layout4 pt-80 pb-0" id="AccountantsSub">
                <h2 className="heading__title text-center mb-20 text-uppercase">
                    Accountants
                </h2>
                <h3 className="heading__subtitle_para mb-20">
                    Our team of qualified accountants provides A wide range of accounting and taxation services to businesses of all sizes. Every business is unique and requires a tailored accounting and tax services approach. Our team works closely with clients to understand their business and provide the most effective solutions to meet their specific needs.
                </h3>
                <div className="container">
                    <div className="row flex-lg-row">
                        {/* <!-- First column for image --> */}
                        <div className="col-lg-6 mb-10">
                            <div className="about__img">
                                <img src={logo} alt="logoAndBranding" />
                            </div>

                        </div>

                        {/* <!-- Second column for content --> */}
                        <div className="col-lg-6 mt-10">
                            <div className="heading mb-40">
                                <div className="d-flex align-items-center">
                                    <div className="divider divider-primary mr-30"></div>
                                    <h2 className="heading__subtitle mb-0">Our services include tax</h2>
                                </div>
                                <h3 className="heading__subtitle mb-10">
                                    planning and compliance, accounting, bookkeeping, payroll, and business advisory services. We have extensive experience dealing with complex tax issues and providing proactive tax planning to help our clients save money and achieve their financial goals.
                                </h3>

                                <p>
                                    Our team stays up to date with the latest tax regulations and accounting standards to ensure our clients receive accurate and compliant services. In addition, we use the latest technology and tools to streamline our processes and provide clients with a seamless and efficient service.
                                </p>
                                <p>
                                    Partnering with us means working with a team of professionals who are dedicated to your success.
                                </p>
                                <p>
                                    We take the time to understand your business and provide tailored solutions that meet your specific needs. We aim to help companies save time and money by providing efficient and effective accounting and taxation services.
                                </p>
                            </div>
                            <div>
                                <button className="pricing__footer">
                                    <PriceTableModal />
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    );
}
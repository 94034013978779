/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import logo from './assets/Solicitors.jpg';
import PriceTableModal from "../../../../components/Modal/PriceTableModal";

export default function SolicitorsSub() {
    return (
        <>
            <section className="about-layout4 pt-80 pb-0" id="SolicitorsSub">
                <h2 className="heading__title text-center mb-20 text-uppercase">
                    Solicitors
                </h2>
                <h3 className="heading__subtitle_para mb-20">
                    Business Savvy UK offers a range of legal services to businesses, governments, and institutions.Our corporate services include mergers and acquisitions, capital markets, and private equity. We advise clients on corporate governance and compliance matters.
                </h3>
                <div className="container">
                    <div className="row flex-lg-row flex-sm-column-reverse">
                        {/* <!-- First column for image --> */}
                        <div className="col-lg-6">
                            <div className="heading mb-40">
                                <div className="d-flex align-items-center">
                                    <div className="divider divider-primary mr-30"></div>
                                    <h2 className="heading__subtitle mb-0"> Business Savvy UK also provides</h2>
                                </div>
                                <h3 className="heading__subtitle mb-10">
                                    comprehensive immigration services to businesses and individuals. Our team of experienced immigration professionals can help with visa applications, work permits, and other immigration matters. We assist with obtaining the necessary documents and help clients navigate the complex immigration process. Our immigration services also include advising clients on immigration regulations and compliance matters.
                                </h3>

                                <p>
                                    In addition, we offer immigration services to businesses looking to hire foreign talent, including sponsor license applications and compliance with immigration laws. Our team can also assist with recruiting skilled workers from overseas to help businesses meet their talent needs.
                                    Whether helping individuals navigate the immigration process or assisting businesses in hiring foreign talent, our immigration services are designed to simplify the process and ensure our clients
                                </p>
                                <p>
                                    <strong>Financial Services:</strong>  We cover a range of areas, including banking and finance, project finance, and structured finance. Our team also offers regulatory advice on financial matters.
                                </p>
                                <p>
                                    <strong>Intellectual Property:</strong>
                                    Our experienced team of lawyers specializes in intellectual property law, including trademarks, patents, and copyrights.
                                </p>

                                <p>
                                    <strong>Litigation and Arbitration:</strong>
                                    We have extensive experience in handling complex litigation and arbitration matters, both domestically and internationally.
                                </p>
                                <p>
                                    <strong>Real Estate Services:</strong>
                                    We provide real estate services to clients, including advice on commercial leases, real estate finance, and development projects.
                                </p>
                                <p>
                                    <strong>Regulatory Services:</strong>
                                    Our regulatory practice advises clients on various regulatory matters, including antitrust and competition law, environmental law, data privacy and cybersecurity.
                                </p>
                                <p>
                                    <strong>Tax Services:</strong>
                                    We provide clients with tax advice on various matters, including corporate tax, international tax, and tax disputes.
                                </p>


                            </div>
                            <div>
                                <button className="pricing__footer">
                                    <PriceTableModal />
                                </button>
                            </div>
                        </div>
                        {/* <!-- Second column for content --> */}
                        <div className="col-lg-6">
                            <div className="about__img">
                                <img src={logo} alt="Solicitors" />
                            </div>

                        </div>

                    </div>
                </div>
            </section>
        </>
    );
}

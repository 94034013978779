import React from "react";
import img from "../../../../assets/images/about/7.jpg";
import img3 from "../../../../assets/images/about/singnture.png";
import SideBarAccordion from "../../../../components/SideBarAccordion/SideBarAccordion";

export default function SolutionsLayoutThree() {
  return (
    <section id="about" className="about-layout2 animated-Progressbars">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
            <div className="heading mb-40">
              <div className="d-flex align-items-center mb-20">
                <div className="divider divider-primary mr-30" />
                <h2 className="heading__subtitle mb-0">
                  Curate And Efficient Solution Of Medical Healthcare
                </h2>
              </div>
              <h3 className="heading__title mb-40">
                That's why we offer a wide range of staffing solutions that cater to the needs of healthcare facilities and organizations.
              </h3>
            </div>
          </div>
          {/* /.col-xl-5 */}
        </div>
        {/* /.row */}
        <div className="row about__Text">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
            <div className="offset-xl-2 position-relative">
              <i className="icon-quote" />
              <p>We supply highly skilled and experienced healthcare professionals, including doctors, nurses, medical assistants, laboratory technicians, and administrative personnel. In addition, our team works closely with healthcare facilities to understand their specific needs and provide tailored solutions that meet their staffing requirements.
                <br />
                <br />
                We source candidates with the qualifications, experience, and personality traits to ensure they fit our client's organizations. Our comprehensive screening process ensures that all candidates are thoroughly vetted and meet the requirements to work in the medical and healthcare industry.
                <br />
                <br />
                Whether you need temporary, permanent, or contract staff, we have the expertise to help you find suitable candidates for your organization. We provide ongoing support to both our clients and candidates to ensure that their staffing needs are met and that they are satisfied with the service they receive.
                <br />
                <br />
                Partnering with us means working with a team of professionals dedicated to your success. We understand the importance of having skilled and reliable professionals in the medical and healthcare industry, and we are committed to helping our clients find the right people to meet their staffing needs.
                <br />
                <br />
                Contact us today to learn how we can help you find the right talent for your organization.
              </p>
            </div>
            <div className="about__img mt-80 mb-30">
              <img src={img} alt="about" />
            </div>
            {/* /.about__img*/}
            <div className="video__btn-wrapper">
              <a
                className="video__btn video__btn-white popup-video"
                href="https://www.youtube.com/watch?v=nrJtHemSPW4"
              >
                <div className="video__player">
                  <i className="fa fa-play" />
                </div>
                <span className="video__btn-title">
                  Watch Our Presentation!
                </span>
              </a>
            </div>
            {/* /.video__btn-wrapper*/}
          </div>
          {/* /.col-xl-6 */}
          <SideBarAccordion />
          {/* /.col-xl-5 */}
        </div>
        {/* /.row */}
      </div>
      {/* /.container */}
    </section>
  );
}

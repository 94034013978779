import React from 'react';

import DarkFooter from '../../components/footer/DarkFooter';
import Headers from '../../components/header/Headers'
import BannerLayoutTwo from '../Home/homeSections/BannerLayoutTwo';
import BannerLayoutThree from '../Industries/IndestriesSections/BannerLayoutThree';
import BrochureSection from './BrochureSection';
import ServicePagetitle from './ServicePagetitle';
import SecondaryNav from './ITandWebServiceAllSub/SecondaryNav/SecondarySubNav';
import { useEffect } from 'react';

const ITandWebService = () => {
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }, []);
    return (
        <div>
            <Headers />
            <ServicePagetitle />
            <SecondaryNav />
            <BrochureSection />
            <BannerLayoutThree />
            <BannerLayoutTwo />
            <DarkFooter />
        </div>
    );
};

export default ITandWebService;
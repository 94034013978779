import React from "react";
import img from "../../../../assets/images/about/7.jpg";
import img2 from "../../../../assets/images/about/3.jpg";
import img3 from "../../../../assets/images/about/singnture.png";
import SideBarAccordion from "../../../../components/SideBarAccordion/SideBarAccordion";

export default function SolutionsLayoutThree() {
  return (
    <section id="about" className="about-layout2 animated-Progressbars">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
            <div className="heading mb-40">
              <div className="d-flex align-items-center mb-20">
                <div className="divider divider-primary mr-30" />
                <h2 className="heading__subtitle mb-0">
                  Curate And Efficient Solution Of Amazon
                </h2>
              </div>
              <h3 className="heading__title mb-40">
                Our team of Amazon experts provides comprehensive consulting and marketing services designed to help sellers succeed on the world's largest online marketplace.
              </h3>
            </div>
          </div>
          {/* /.col-xl-5 */}
        </div>
        {/* /.row */}
        <div className="row about__Text">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
            <div className="offset-xl-2 position-relative">
              <i className="icon-quote" />
              <p>With years of experience in the industry, we understand what it takes to succeed on Amazon, from optimizing your product listings to leveraging advertising tools to drive sales. Our services include everything from keyword research and listing optimization to sponsored ads management and brand registry management.
                <br />
                <br />
                At BuyBox Savvy, we take a holistic approach to Amazon consulting, working with you to understand your business goals and target audience. We provide tailored solutions that help you stand out from the competition, drive traffic to your listings, and increase your sales and revenue.
                <br />
                <br />
                Our team of experts stays up to date with the latest trends and best practices in Amazon selling, ensuring that our clients receive the most effective strategies and techniques to optimize their listings and grow their businesses. With our help, you can save time and money while achieving success on Amazon.
                <br />
                <br />
                Partnering with BuyBox Savvy means working with a team of dedicated professionals who are committed to your success. Let us help you achieve your goals on Amazon and take your business to the next level. Contact us today to learn more about our services and how we can help you succeed.
              </p>
            </div>
            <div className="about__img mt-80 mb-30">
              <img src={img} alt="about" />
            </div>
            {/* /.about__img*/}
            <div className="video__btn-wrapper">
              <a
                className="video__btn video__btn-white popup-video"
                href="https://www.youtube.com/watch?v=nrJtHemSPW4"
              >
                <div className="video__player">
                  <i className="fa fa-play" />
                </div>
                <span className="video__btn-title">
                  Watch Our Presentation!
                </span>
              </a>
            </div>
            {/* /.video__btn-wrapper*/}
          </div>
          {/* /.col-xl-6 */}
          <SideBarAccordion />
          {/* /.col-xl-5 */}
        </div>
        {/* /.row */}
      </div>
      {/* /.container */}
    </section>
  );
}

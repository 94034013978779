import React from "react";
import img from "../../../../assets/images/about/7.jpg";
import img2 from "../../../../assets/images/about/3.jpg";
import img3 from "../../../../assets/images/about/singnture.png";
import SideBarAccordion from "../../../../components/SideBarAccordion/SideBarAccordion";

export default function SolutionsLayoutThree() {
  return (
    <section id="about" className="about-layout2 animated-Progressbars">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
            <div className="heading mb-40">
              <div className="d-flex align-items-center mb-20">
                <div className="divider divider-primary mr-30" />
                <h2 className="heading__subtitle mb-0">
                  Curate And Efficient Solution Of Salespeople
                </h2>
              </div>
              <h3 className="heading__title mb-40">
                Salespeople, also known as grafters, are essential for the success of any trade show or exhibition.
              </h3>
            </div>
          </div>
          {/* /.col-xl-5 */}
        </div>
        {/* /.row */}
        <div className="row about__Text">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
            <div className="offset-xl-2 position-relative">
              <i className="icon-quote" />
              <p>A successful exhibition requires a team of experienced and skilled salespeople knowledgeable about the products or services being showcased and can effectively communicate their value to potential customers.
                <br />
                <br />
                Salespeople for exhibitions must have a wide range of skills, including strong communication and interpersonal skills, the ability to work well under pressure, and a deep understanding of the products or services being promoted. They must also have a strong work ethic, be able to think on their feet and be comfortable working in a fast-paced environment.
                <br />
                <br />
                At Business Savvy UK, we understand the importance of having a strong sales team for exhibitions. So, we provide businesses with access to a network of experienced salespeople with a proven track record of success. Our team of sales professionals are skilled in lead generation, relationship building, and closing deals, ensuring that businesses get the most out of their exhibition investment.
                <br />
                <br />
                Partnering with us for your exhibition sales needs means working with a team of professionals dedicated to your success. We take the time to understand your business, target audience, and goals to create a customized approach that meets your unique needs. With our experienced team of salespeople, you can rest assured that your exhibition will succeed and achieve your sales targets.
              </p>
            </div>
            <div className="about__img mt-80 mb-30">
              <img src={img} alt="about" />
            </div>
            {/* /.about__img*/}
            <div className="video__btn-wrapper">
              <a
                className="video__btn video__btn-white popup-video"
                href="https://www.youtube.com/watch?v=nrJtHemSPW4"
              >
                <div className="video__player">
                  <i className="fa fa-play" />
                </div>
                <span className="video__btn-title">
                  Watch Our Presentation!
                </span>
              </a>
            </div>
            {/* /.video__btn-wrapper*/}
          </div>
          {/* /.col-xl-6 */}
          <SideBarAccordion />
          {/* /.col-xl-5 */}
        </div>
        {/* /.row */}
      </div>
      {/* /.container */}
    </section>
  );
}

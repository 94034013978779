import React from "react";
import img from "../../../../assets/images/about/7.jpg";
import img3 from "../../../../assets/images/about/singnture.png";
import SideBarAccordion from "../../../../components/SideBarAccordion/SideBarAccordion";

export default function SolutionsLayoutThree() {
  return (
    <section id="about" className="about-layout2 animated-Progressbars">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
            <div className="heading mb-40">
              <div className="d-flex align-items-center mb-20">
                <div className="divider divider-primary mr-30" />
                <h2 className="heading__subtitle mb-0">
                  Curate And Efficient Solution Of Sales Agents
                </h2>
              </div>
              <h3 className="heading__title mb-40">
                Our partners specialize in recruiting and training skilled sales agents to promote and sell a range of products and services.
              </h3>
            </div>
          </div>
          {/* /.col-xl-5 */}
        </div>
        {/* /.row */}
        <div className="row about__Text">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
            <div className="offset-xl-2 position-relative">
              <i className="icon-quote" />
              <p>Working with our partners can help businesses expand their reach and increase sales without the expense of hiring an in-house sales team.
                <br />
                <br />
                Our network of sales agents has expertise in various industries and regions, giving businesses access to a broad range of potential customers. By leveraging our partners' connections and expertise, businesses can quickly and efficiently establish a sales presence in new markets, expand their customer base, and increase revenue.
                <br />
                <br />
                Working with our sales agent partners provides businesses with several key benefits. These include access to experienced sales professionals, reduced costs compared to building an in-house sales team, increased market reach, and the ability to quickly scale sales efforts. Our partners work closely with businesses to understand their specific needs and develop a customized sales strategy that aligns with their goals and budget.
                <br />
                <br />
                Overall, partnering with Business Savvy or our trusted company associates for sales agent services can be an excellent way for businesses to expand their sales efforts and reach new customers.
              </p>
            </div>
            <div className="about__img mt-80 mb-30">
              <img src={img} alt="about" />
            </div>
            {/* /.about__img*/}
            <div className="video__btn-wrapper">
              <a
                className="video__btn video__btn-white popup-video"
                href="https://www.youtube.com/watch?v=nrJtHemSPW4"
              >
                <div className="video__player">
                  <i className="fa fa-play" />
                </div>
                <span className="video__btn-title">
                  Watch Our Presentation!
                </span>
              </a>
            </div>
            {/* /.video__btn-wrapper*/}
          </div>
          {/* /.col-xl-6 */}
          <SideBarAccordion />
          {/* /.col-xl-5 */}
        </div>
        {/* /.row */}
      </div>
      {/* /.container */}
    </section>
  );
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import logo from './assets/video.jpg';
import PriceTableModal from "../../../../components/Modal/PriceTableModal";

export default function VideoProductionSub() {
    return (
        <>
            <section className="about-layout4 pt-80 pb-0" id="VideoProductionSub">
                <h2 className="heading__title text-center mb-20 text-uppercase">
                    Video Production
                </h2>
                <h3 className="heading__subtitle_para mb-20">
                    Concept Media Group is a video production specialist offering bespoke video production services for businesses looking to create a strong brand identity and increase their online presence. We work with clients to develop creative ideas that capture their brand essence and communicate their message effectively to their target audience.
                </h3>
                <div className="container">
                    <div className="row flex-lg-row flex-sm-column-reverse">
                        {/* <!-- First column for image --> */}
                        <div className="col-lg-6">
                            <div className="heading mb-40">
                                <div className="d-flex align-items-center">
                                    <div className="divider divider-primary mr-30"></div>
                                    <h2 className="heading__subtitle mb-0">Our team of experienced</h2>
                                </div>
                                <h3 className="heading__subtitle mb-10">
                                    video producers and editors will take you through the entire production process, from concept development and scripting to filming and editing. We use the latest equipment and techniques to produce high-quality videos that are engaging, professional, and visually stunning.
                                </h3>

                                <p>
                                    We understand that every business is unique, so we offer tailored solutions to meet your specific needs. Our services include corporate videos, product demos, event coverage, social media videos, and more. In addition, we take the time to understand your business goals and objectives and create videos that deliver results.
                                </p>

                                <p>
                                    Partnering with us means working with a team of professionals dedicated to your success. We go above and beyond to ensure that our clients are satisfied with the final product and that it achieves their desired outcomes.
                                </p>
                                <p>
                                    Let us help you create high-quality videos that capture your brand's essence and resonate with your target audience.
                                </p>
                                <p>
                                    In summary, our Network Partners service provides businesses with access to a range of partners that can help them succeed faster and more effectively. We work closely with our partners to provide tailored solutions that meet each business's specific needs and requirements.
                                </p>
                            </div>
                            <div>
                                <button className="pricing__footer">
                                    <PriceTableModal />
                                </button>
                            </div>
                        </div>
                        {/* <!-- Second column for content --> */}
                        <div className="col-lg-6">
                            <div className="about__img">
                                <img src={logo} alt=" Video Production" />
                            </div>

                        </div>

                    </div>
                </div>
            </section>
        </>
    );
}

import React from 'react';
import { Accordion } from 'react-bootstrap-accordion';
import { Link } from 'react-router-dom';


const SideBarAccordion = () => {
    return (
        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-5 offset-xl-1">
            <Accordion className="AccordionDesign" title="Design Services">
                <Link to='/website-design'><ul><li>Website Design</li></ul></Link>
                <Link to='/Logo-Branding'><ul><li>Logoand Branding</li></ul></Link>
                <Link to='/advertising'><ul><li>Advertising</li></ul></Link>
                <Link to='/Social-media'><ul><li>Social Media</li></ul></Link>
                <Link to='/packaging'><ul><li>Packaging</li></ul></Link>
                <Link to='/Graphic-Design'><ul><li>Graphic Designs</li></ul></Link>
                <Link to='/Copy-writing-Services'><ul><li>Copywriting Services</li></ul></Link>
                <br />
            </Accordion>
            <Accordion className="AccordionDesign" title="IT & Web Services">
                <Link to='/Website-Building'><ul><li>Website Building</li></ul></Link>
                <Link to='/IT-Support'><ul><li>IT Support</li></ul></Link>
                <Link to='/web-app'><ul><li>Web App Development</li></ul></Link>
                <Link to='/Mobile-App'><ul><li>Mobile App Development</li></ul></Link>
                <Link to='/AI-ML'><ul><li>AI & ML</li></ul></Link>
                <Link to='/Digital-Marketing'><ul><li>Digital Marketing</li></ul></Link>
                <br />
            </Accordion>
            <Accordion className="AccordionDesign" title="Selling Platforms">
                <Link to='/Amazon'><ul><li>Amazon</li></ul></Link>
                <Link to='/eBay'><ul><li>eBay</li></ul></Link>
                <Link to='/OnBuy'><ul><li>OnBuy</li></ul></Link>
                <Link to='/Social-Marketing'><ul><li>Social Marketing</li></ul></Link>
                <Link to='/E-Campaigners'><ul><li>E-Campaigners</li></ul></Link>
                <br />
            </Accordion>
            <Accordion className="AccordionDesign" title="Sales & Marketing">
                <Link to='/Data-Discovery'><ul><li>Data Discovery</li></ul></Link>
                <Link to='/Product-Sourcing'><ul><li>Product Sourcing</li></ul></Link>
                <Link to='/Business-Opportunitie'><ul><li>Business Opportunities</li></ul></Link>
                <Link to='/LinkedIn-Services'><ul><li>LinkedIn Services</li></ul></Link>
                <br />
            </Accordion>
            <Accordion className="AccordionDesign" title="Network Partners">
                <Link to='/Sales-Agents'><ul><li>Data Discovery</li></ul></Link>
                <Link to='/Accountants'><ul><li>Product Sourcing</li></ul></Link>
                <Link to='/Solicitorsn'><ul><li>Business Opportunities</li></ul></Link>
                <Link to='/Telecommunications'><ul><li>LinkedIn Services</li></ul></Link>
                <Link to='/Video-Production'><ul><li>Video Production</li></ul></Link>
                <br />
            </Accordion>
            <Accordion className="AccordionDesign" title="Recruitment Services">
                <Link to='/Salespeople'><ul><li>Salespeople</li></ul></Link>
                <Link to='/Technology-Industry'><ul><li>Technology Industry</li></ul></Link>
                <Link to='/Education'><ul><li>Education</li></ul></Link>
                <Link to='/Medical-Healthcare'><ul><li>Medical & Healthcare</li></ul></Link>
                <Link to='/Hospitality'><ul><li>Hospitality</li></ul></Link>
                <Link to='/Finance'><ul><li>Finance</li></ul></Link>
                <Link to='/Recruitment-services'><ul><li>Recruitment Services</li></ul></Link>
                <br />
            </Accordion>
            {/* /.progress-item  */}
            <div className="about__img mt-90">
                <div class="meipaly_services_help">
                    <h4>need Business Savvy help?</h4>
                    <p>Prefer speaking with a human to filling out a form? call corporate office and we will connect you with a team member who can help.</p>
                    <h2>666 888 000</h2>
                </div>
            </div>
        </div>
    );
};

export default SideBarAccordion;
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import logo from './assets/LinkedIn.jpg';
import PriceTableModal from "../../../../components/Modal/PriceTableModal";


export default function LinkedInMarketingSub() {
    return (
        <>
            <section className="about-layout4 pt-80 pb-0" id="LinkedInMarketingSub">
                <h2 className="heading__title text-center mb-20 text-uppercase">
                    LinkedIn Services
                </h2>
                <h3 className="heading__subtitle_para mb-20">
                    Here at Business Savvy UK, we understand    The importance of a solid online presence, and we offer services to optimize your LinkedIn profile to help you get ahead of the game.
                    With over 800 million users, LinkedIn is a powerful social media platform that lets you keep all your essential documents, qualifications, awards, honours, work experience, and more in one place. Plus, your profile headline alone gets put in front of hundreds and thousands of people every time you write a post.
                </h3>
                <div className="container">
                    <div className="row flex-lg-row">
                        {/* <!-- First column for image --> */}
                        <div className="col-lg-6 mb-10">
                            <div className="about__img">
                                <img src={logo} alt="LinkedIn" />
                            </div>

                        </div>

                        {/* <!-- Second column for content --> */}
                        <div className="col-lg-6 mt-10">
                            <div className="heading mb-40">
                                <div className="d-flex align-items-center">
                                    <div className="divider divider-primary mr-30"></div>
                                    <h2 className="heading__subtitle mb-0"> Our team of experts</h2>
                                </div>
                                <h3 className="heading__subtitle mb-10">
                                    can optimize your LinkedIn profile to help you stand out with a profile that speaks in your unique tone of voice and positions you for success in your niche. In addition, we provide calls-to-action that move your audience to connect, engage, and message you, a captivating headline that makes people eager to click, and a summary that speaks to your target audience.
                                    We also include a featured section that captures attention and fuels intrigue, an accurate and optimistic overview of your current and past experiences that highlight your biggest wins, an up-to-date list of any publications, projects, or awards you've created/received, and a skills list that reflects who you are and what your audience is looking for.
                                </h3>

                                <p>
                                    Our team follows specific formatting rules, and we understand all sorts of intricate details of the platform so that we can save you time and frustration. In addition, we have done LinkedIn Profile Upgrades for dozens of clients worldwide and have the experience and expertise to ensure you get the most out of your profile.
                                </p>
                                <p>
                                    Our service includes a 1-hour Zoom interview to cover areas including your background, ambitions, experiences, what to focus on in the profile, and what you want the profile to accomplish.
                                </p>
                                <p>
                                    We'll review any further resources you provide and do any additional research required, then return to you if we have any questions. Finally, we'll assemble the new profile in a Google Doc and send it to you for approval/edits. Once approved, we'll log into your LinkedIn and make all the changes, ensuring everything looks right.
                                </p>

                                <p>
                                    Don't leave a TON on the table. Book your slot ahead of time with Business Savvy UK, and we'll optimize your LinkedIn profile to help you win on LinkedIn.
                                </p>
                            </div>
                            <div>
                                <button className="pricing__footer">
                                    <PriceTableModal />
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    );
}
import React from "react";
import QuoteImg from "../../../assets/images/about/Quote.jpg"
export default function RequestQuoteTitle() {
  return (
    <section
      className="page-title page-title-layout9 bg-overlay bg-overlay-gradient bg-parallax text-center"
      style={{
        background: `url(${QuoteImg})`
      }}>
      {/* <div className="bg-img">
        <img src={QuoteImg} alt="background" />
      </div> */}
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6 offset-xl-3">
            <h1 className="pagetitle__heading">Request A Quote</h1>
            <p className="pagetitle__desc">
              Our hands-on approach ensures that we are fully invested in our client's success and are with them every step of the way.
            </p>
            <a
              className="video__btn video__btn-rounded video__btn-white popup-video"
              href="https://www.youtube.com/watch?v=nrJtHemSPW4"
            >
              <div className="video__player">
                <i className="fa fa-play" />
              </div>
            </a>
          </div>
          {/* /.col-xl-6 */}
        </div>
        {/* /.row */}
      </div>
      {/* /.container */}
    </section>
  );
}

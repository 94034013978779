/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import logo from './assets/financial.jpg';
import PriceTableModal from "../../../../components/Modal/PriceTableModal";

export default function FinanceSub() {
    return (
        <>
            <section className="about-layout4 pt-80 pb-0" id="FinanceSub">
                <h2 className="heading__title text-center mb-20 text-uppercase">
                    Finance
                </h2>

                <h3 className="heading__subtitle_para mb-20">
                    At Business Savvy UK, we understand that having the right finance staff is crucial to the success of any business. That's why we provide a variety of skilled finance professionals to meet the unique needs of our clients.
                </h3>
                <div className="container">
                    <div className="row flex-lg-row">
                        {/* <!-- First column for image --> */}
                        <div className="col-lg-6 mb-10">
                            <div className="about__img">
                                <img src={logo} alt="financial" />
                            </div>

                        </div>

                        {/* <!-- Second column for content --> */}
                        <div className="col-lg-6 mt-10">
                            <div className="heading mb-40">
                                <div className="d-flex align-items-center">
                                    <div className="divider divider-primary mr-30"></div>
                                    <h2 className="heading__subtitle mb-0">
                                        Our team of experienced recruiters
                                    </h2>
                                </div>
                                <h3 className="heading__subtitle mb-10">
                                    works with businesses to understand their specific requirements and source the most qualified candidates. We offer a wide range of finance staffing solutions, including temporary, contract, and permanent staffing. In addition, we provide finance professionals at all levels, from entry-level bookkeepers to senior-level CFOs.
                                </h3>

                                <p>
                                    We work with various businesses across different industries, including banking, insurance, accounting, and more. As a result, our finance professionals have expertise in multiple areas, such as financial analysis, tax, audit, risk management, and compliance.
                                </p>
                                <p>
                                    Partnering with us means working with a team of professionals dedicated to delivering the best finance staffing solutions for your business. We take the time to understand your unique requirements and provide customized solutions that meet your needs. In addition, we aim to help companies find the right finance talent to drive growth and success.
                                </p>
                                <p>
                                    With our finance staffing solutions, businesses can benefit from cost savings, increased productivity, and access to a pool of highly skilled finance professionals. Let us help you find the right finance talent to take your business to the next level.
                                </p>
                                <p>
                                    In summary, our Recruitment Services provide businesses with access to the right people to achieve success. We understand the importance of finding the right people for each industry and provide tailored solutions that meet each business's specific needs and requirements.
                                </p>
                            </div>
                            <div>
                                <button className="pricing__footer">
                                    <PriceTableModal />
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    );
}
import React from "react";
import logo from '../assets/leaderShip.jpg';
export default function LeaderShipTitle() {
  return (
    // <section className="page-title page-title-layout3 bg-overlay bg-overlay-gradient bg-parallax">
    <section className="page-title page-title-layout3 page-title-layout9 bg-overlay bg-overlay-gradient bg-parallax page-title page-title-layout2 bg-overlay bg-overlay-gradient bg-parallax"
      style={{
        background: `url(${logo})`
      }}>
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
            <h1 className="pagetitle__heading">Meet the Minds Behind Business Savvy's Success</h1>
            <p className="pagetitle__desc">
            Driven leaders. Visionaries. Pioneers in digital transformation.
            </p>
            <div className="d-flex align-items-center">
              <a href="contact-us.html" className="btn btn__white mr-30">
                Contact Us
              </a>
              <a
                href="request-quote.html"
                className="btn btn__white btn__bordered btn__icon"
              >
                <span>Get Started</span>
                <i className="icon-arrow-right" />
              </a>
            </div>
            <nav>
              <ol className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <a href="index.html">Home</a>
                </li>
                <li className="breadcrumb-item">
                  <a href="about-us.html">Company</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Leadership
                </li>
              </ol>
            </nav>
          </div>
          {/* /.col-xl-6 */}
        </div>
        {/* /.row */}
      </div>
      {/* /.container */}
    </section>
  );
}

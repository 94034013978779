/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import logo from './assets/sales.jpg';
import PriceTableModal from "../../../../components/Modal/PriceTableModal";



export default function SalespeopleSub() {
    return (
        <>
            <section className="about-layout4 pt-80 pb-0" id="SalespeopleSub">
                <h2 className="heading__title text-center mb-20 text-uppercase">
                    Sales people
                </h2>
                <h3 className="heading__subtitle_para mb-20">
                    Salespeople, also known as grafters, are essential for the success of any trade show or exhibition. These individuals are the face of a company and are responsible for generating leads, engaging with potential customers, and ultimately driving sales.

                </h3>
                <div className="container">
                    <div className="row flex-lg-row flex-sm-column-reverse">
                        {/* <!-- First column for image --> */}
                        <div className="col-lg-6">
                            <div className="heading mb-40">
                                <div className="d-flex align-items-center">
                                    <div className="divider divider-primary mr-30"></div>
                                    <h2 className="heading__subtitle mb-0">
                                        A successful exhibition
                                    </h2>
                                </div>
                                <h3 className="heading__subtitle mb-10">
                                    requires a team of experienced and skilled salespeople knowledgeable about the products or services being showcased and can effectively communicate their value to potential customers.
                                </h3>
                                <p>
                                    Salespeople for exhibitions must have a wide range of skills, including strong communication and interpersonal skills, the ability to work well under pressure, and a deep understanding of the products or services being promoted. They must also have a strong work ethic, be able to think on their feet and be comfortable working in a fast-paced environment.
                                </p>

                                <p>
                                    At Business Savvy UK, we understand the importance of having a strong sales team for exhibitions. So, we provide businesses with access to a network of experienced salespeople with a proven track record of success. Our team of sales professionals are skilled in lead generation, relationship building, and closing deals, ensuring that businesses get the most out of their exhibition investment.
                                </p>

                                <p>
                                    Partnering with us for your exhibition sales needs means working with a team of professionals dedicated to your success. We take the time to understand your business, target audience, and goals to create a customized approach that meets your unique needs. With our experienced team of salespeople, you can rest assured that your exhibition will succeed and achieve your sales targets.
                                </p>

                            </div>
                            <div>
                                <button className="pricing__footer">
                                    <PriceTableModal />
                                </button>
                            </div>
                        </div>
                        {/* <!-- Second column for content --> */}
                        <div className="col-lg-6">
                            <div className="about__img">
                                <img src={logo} alt="Salespeople" />
                            </div>

                        </div>

                    </div>
                </div>
            </section>
        </>
    );
}

import "./assets/css/style.css";
import "./assets/css/libraries.css";
import "./assets/scss/style.scss";
import Home from "./pages/Home/Home";
import AllRoute from "./router/AllRoutes";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import Awards from "./pages/Awards/Awards";
import ErrorPage from "./pages/404Page/ErrorPage";
import Careers from "./pages/Careers/Careers";
import WhyUs from "./pages/WhyUs/WhyUs";
import Aboutus from "./pages/Aboutus/Aboutus";
import BlogSinglePost from "./pages/BlogSinglePost/BlogSinglePost";
import CaseStudiesGrid from "./pages/CaseStudiesGrid/CaseStudiesGrid";
import CaseStudiesSingle from "./pages/CaseStudiesSingle/CaseStudiesSingle";
import ContactUs from "./pages/ContactUs/ContactUs";
import Faqs from "./pages/Faqs/Faqs";
import ItSolutionsSinglePage from "./pages/ItSolutionsSinglePage/ItSolutionsSinglePage";
import LeadershipTeam from "./pages/LeadershipTeam/LeadershipTeam";
import OurBlog from "./pages/OurBlog/OurBlog";
import Pricing from "./pages/Pricing/Pricing";
import ItSolutions from "./pages/ItSolutions/ItSolutions";
import Industries from "./pages/Industries/Industries";
import IndustriesSingleIndustry from "./pages/IndustriesSingleIndustry/IndustriesSingleIndustry";
import Packaging from "./pages/DesignService/Packaging/Packaging";
import Advertising from "./pages/DesignService/AdvertisingandMarketingDesign/Advertising";
import CopywritingServices from "./pages/DesignService/CopywritingServices/CopywritingServices";
import GraphicDesign from "./pages/DesignService/GraphicDesign/GraphicDesign";
import LogoandBranding from "./pages/DesignService/LogoandBranding/LogoandBranding";
import SocialMedia from "./pages/DesignService/SocialMedia/SocialMedia";
import WebsiteDesign from "./pages/DesignService/WebsiteDesign/WebsiteDesign";
import AIML from "./pages/ITandWebService/AIML/AIML";
import DigitalMarketing from "./pages/ITandWebService/DigitalMarketing/DigitalMarketing";
import ITSupport from "./pages/ITandWebService/ITSupport/ITSupport";
import MobileApp from "./pages/ITandWebService/MobileApp/MobileApp";
import WebApp from "./pages/ITandWebService/WebApp/WebApp";
import WebsiteBuilding from "./pages/ITandWebService/WebsiteBuilding/WebsiteBuilding";
import Amazon from "./pages/SellingPlatforms/Amazon/Amazon";
import EBay from "./pages/SellingPlatforms/EBay/EBay";
import ECampaigners from "./pages/SellingPlatforms/ECampaigners/ECampaigners";
import OnBuy from "./pages/SellingPlatforms/OnBuy/OnBuy";
import SocialMarketing from "./pages/SellingPlatforms/SocialMarketing/SocialMarketing";
import BusinessOpportunitie from "./pages/SalesAndMarketing/BusinessOpportunitie/BusinessOpportunitie";
import DataDiscovery from "./pages/SalesAndMarketing/DataDiscovery/DataDiscovery";
import LinkedInServices from "./pages/SalesAndMarketing/LinkedInServices/LinkedInServices";
import ProductSourcing from "./pages/SalesAndMarketing/ProductSourcing/ProductSourcing";
import Accountants from "./pages/NetworkPartners/Accountants/Accountants";
import SalesAgents from "./pages/NetworkPartners/SalesAgents/SalesAgents";
import Solicitorsn from "./pages/NetworkPartners/Solicitorsn/Solicitorsn";
import Telecommunications from "./pages/NetworkPartners/Telecommunications/Telecommunications";
import VideoProduction from "./pages/NetworkPartners/VideoProduction/VideoProduction";
import Education from "./pages/RecruitmentServices/Education/Education";
import Finance from "./pages/RecruitmentServices/Finance/Finance";
import Hospitality from "./pages/RecruitmentServices/Hospitality/Hospitality";
import MedicalHealthcare from "./pages/RecruitmentServices/MedicalHealthcare/MedicalHealthcare";
import RecruitmentServices from "./pages/RecruitmentServices/RecruitmentServices/RecruitmentServices";
import Salespeople from "./pages/RecruitmentServices/Salespeople/Salespeople";
import TechnologyIndustry from "./pages/RecruitmentServices/TechnologyIndustry/TechnologyIndustry";
import DesignService from "./pages/DesignService/DesignService";
import ITandWebService from "./pages/ITandWebService/ITandWebService";
import SellingPlatforms from "./pages/SellingPlatforms/SellingPlatforms";
import SalesAndMarketing from "./pages/SalesAndMarketing/SalesAndMarketing";
import NetworkPartners from "./pages/NetworkPartners/NetworkPartners";
import RecruitmentServicesDoc from "./pages/RecruitmentServices/RecruitmentServicesDoc";
import RequestQuote from "./pages/RequestQuote/RequestQuote";
import "./assets/fonts/icomoon.ttf";
import Trams from "./pages/TramsAndConditation/Trams";
import Policy from "./pages/TramsAndConditation/Policy";
import Refund from "./pages/TramsAndConditation/Refund";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/awards" element={<Awards />} />
      <Route path="/about-us" element={<Aboutus />} />
      <Route path="/why-us" element={<WhyUs />} />
      <Route path="/leadership-team" element={<LeadershipTeam />} />
      <Route path="/pricing" element={<Pricing />} />
      <Route path="/faqs" element={<Faqs />} />
      <Route path="/careers" element={<Careers />} />
      <Route path="/it-solutions" element={<ItSolutions />} />
      <Route path="/industries" element={<Industries />} />
      <Route
        path="/industries-single-industry"
        element={<IndustriesSingleIndustry />}
      />
      <Route
        path="/it-solutions-single-page"
        element={<ItSolutionsSinglePage />}
      />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/our-blog" element={<OurBlog />} />
      <Route path="/blog-single-post" element={<BlogSinglePost />} />
      <Route path="/case-studies-grid" element={<CaseStudiesGrid />} />
      <Route path="/case-studies-single" element={<CaseStudiesSingle />} />
      <Route path="/packaging" element={<Packaging />} />
      <Route path="/advertising" element={<Advertising />} />
      <Route path="/Copy-writing-Services" element={<CopywritingServices />} />
      <Route path="/Graphic-Design" element={<GraphicDesign />} />
      <Route path="/Logo-Branding" element={<LogoandBranding />} />
      <Route path="/Social-media" element={<SocialMedia />} />
      <Route path="/Website-Design" element={<WebsiteDesign />} />
      <Route path="/AI-ML" element={<AIML />} />
      <Route path="/Digital-Marketing" element={<DigitalMarketing />} />
      <Route path="/IT-Support" element={<ITSupport />} />
      <Route path="/request-quote" element={<RequestQuote />} />
      <Route path="/Mobile-App" element={<MobileApp />} />
      <Route path="/Web-App" element={<WebApp />} />
      <Route path="/Website-Building" element={<WebsiteBuilding />} />
      <Route path="/Amazon" element={<Amazon />} />
      <Route path="/eBay" element={<EBay />} />
      <Route path="/e-Campaigners" element={<ECampaigners />} />
      <Route path="/OnBuy" element={<OnBuy />} />
      <Route path="/Social-Marketing" element={<SocialMarketing />} />
      <Route path="/Business-Opportunitie" element={<BusinessOpportunitie />} />
      <Route path="/Data-Discovery" element={<DataDiscovery />} />
      <Route path="/LinkedIn-Services" element={<LinkedInServices />} />
      <Route path="/Product-Sourcing" element={<ProductSourcing />} />
      <Route path="/Accountants" element={<Accountants />} />
      <Route path="/Sales-Agents" element={<SalesAgents />} />
      <Route path="/Solicitorsn" element={<Solicitorsn />} />
      <Route path="/Telecommunications" element={<Telecommunications />} />
      <Route path="/Video-Production" element={<VideoProduction />} />
      <Route path="/Education" element={<Education />} />
      <Route path="/Finance" element={<Finance />} />
      <Route path="/Hospitality" element={<Hospitality />} />
      <Route path="/Medical-Healthcare" element={<MedicalHealthcare />} />
      <Route path="/Recruitment-Services" element={<RecruitmentServices />} />
      <Route path="/Salespeople" element={<Salespeople />} />
      <Route path="/Technology-Industry" element={<TechnologyIndustry />} />
      <Route path="/industry" element={<Industries />} />

      {/* Main Category Route */}
      <Route path="/Design-Services" element={<DesignService />} />
      <Route path="/IT-Web-Services" element={<ITandWebService />} />
      <Route path="/Selling-Platforms" element={<SellingPlatforms />} />
      <Route path="/Sales-Marketing" element={<SalesAndMarketing />} />
      <Route path="/Network-Partners" element={<NetworkPartners />} />
      <Route path="/Recruitment" element={<RecruitmentServicesDoc />} />
      <Route path="/trams" element={<Trams />} />
      <Route path="/policy" element={<Policy />} />
      <Route path="/refund" element={<Refund />} />

      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
}

export default App;

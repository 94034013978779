/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import logo from './assets/education.jpg';
import PriceTableModal from "../../../../components/Modal/PriceTableModal";


export default function EducationSub() {
    return (
        <>
            <section className="about-layout4 pt-80 pb-0" id="EducationSub">
                <h2 className="heading__title text-center mb-20 text-uppercase">
                    Education
                </h2>
                <h3 className="heading__subtitle_para mb-20">
                    At Business Savvy UK, we are committed to supporting the education sector by providing skilled and experienced professionals. Our team of education recruitment specialists has a wealth of experience in sourcing and supplying high-quality candidates to schools, colleges, universities, and other educational institutions.
                </h3>
                <div className="container">
                    <div className="row flex-lg-row flex-sm-column-reverse">
                        {/* <!-- First column for image --> */}
                        <div className="col-lg-6">
                            <div className="heading mb-40">
                                <div className="d-flex align-items-center">
                                    <div className="divider divider-primary mr-30"></div>
                                    <h2 className="heading__subtitle mb-0">
                                        We understand the importance of
                                    </h2>
                                </div>
                                <h3 className="heading__subtitle mb-10">
                                    having a team of skilled professionals in the education sector, which is why we offer a range of services to meet the needs of our clients. We work closely with our clients to understand their requirements and provide tailored recruitment solutions that meet their needs.
                                </h3>
                                <p>
                                    Our recruitment services include sourcing candidates for teaching, school leadership, and administrative roles. We have an extensive pool of candidates with a wide range of skills and experience, including subject specialists, curriculum designers, and education technology experts.
                                </p>

                                <p>
                                    Our focus on quality means that we only supply candidates who have been rigorously vetted and are passionate about education. We also offer ongoing support to ensure that our clients are happy with the candidates we provide and that their staffing needs are met.
                                    Partnering with us means working with a team of professionals dedicated to your success in the education sector.
                                </p>

                                <p>
                                    We are passionate about positively impacting education and helping our clients achieve their goals. Whether you need a temporary or permanent staff member, we can provide the right person for the job.
                                </p>

                            </div>
                            <div>
                                <button className="pricing__footer">
                                    <PriceTableModal />
                                </button>
                            </div>
                        </div>
                        {/* <!-- Second column for content --> */}
                        <div className="col-lg-6">
                            <div className="about__img">
                                <img src={logo} alt="Education" />
                            </div>

                        </div>

                    </div>
                </div>
            </section>
        </>
    );
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import logo from './assets/marketing.jpg';
import PriceTableModal from "../../../../components/Modal/PriceTableModal";

export default function AdvertisingAndMarketingSub() {
    return (
        <>
            <section className="about-layout4 pt-80 pb-0" id="AdvertisingAndMarketingSub">
                <h2 className="heading__title text-center mb-20 text-uppercase">
                    Advertising and Marketing
                </h2>
                <h3 className="heading__subtitle_para mb-20">
                    Our advertising and marketing design services are designed to <br />help businesses create impactful and engaging marketing materials that resonate with their target audience.
                </h3>

                <div className="container">
                    <div className="row flex-lg-row flex-sm-column-reverse">
                        {/* <!-- First column for image --> */}
                        <div className="col-lg-6">
                            <div className="heading mb-40">
                                <div className="d-flex align-items-center">
                                    <div className="divider divider-primary mr-30"></div>
                                    <h2 className="heading__subtitle mb-0">
                                        We create custom designs for
                                    </h2>
                                </div>
                                <h3 className="heading__subtitle mb-10">
                                    various marketing materials, including flyers, brochures, posters, social media graphics, and more. Our designs are tailored to each business's unique brand identity and marketing message, ensuring they stand out in a crowded market.
                                </h3>
                                <p>
                                    Our team of experienced designers works closely with clients to understand their unique value propositions, target audience, and marketing goals.
                                </p>

                                <p>
                                    We understand the importance of creating excellent marketing materials that look great and effectively communicate the benefits of a business's products or services. That's why we focus on creating visually appealing designs and highly effective in converting potential customers.
                                </p>

                                <p>
                                    Our advertising and marketing designs are optimized for various platforms, including social media, email, and traditional print media. In addition, we work closely with clients to ensure that their marketing materials reach the right audience and generate a high return on investment.
                                </p>
                            </div>
                            <div>
                            <button className="pricing__footer">
                                    <PriceTableModal />
                                </button>
                            </div>
                        </div>
                        {/* <!-- Second column for content --> */}
                        <div className="col-lg-6">
                            <div className="about__img">
                                <img src={logo} alt="AdvertisingAndMarketing" />
                            </div>

                        </div>

                    </div>
                </div>
            </section>
        </>
    );
}

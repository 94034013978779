/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import logo from './assets/e-Campaigners.jpg';
import PriceTableModal from "../../../../components/Modal/PriceTableModal";


export default function ECampaignersSub() {
    return (
        <>
            <section className="about-layout4 pt-80 pb-0" id="eCampaignersSub">
                <h2 className="heading__title text-center mb-20 text-uppercase">
                    e-Campaigners
                </h2>
                <h3 className="heading__subtitle_para mb-20">
                    Business Savvy UK understands the importance  Of strategic marketing campaigns to reach and engage with target audiences. One effective way to get a broader audience is through e-campaigners such as Wowcher, Groupon UK, HotUKDeals, and VoucherCodes.co.uk.
                </h3>
                <div className="container">
                    <div className="row flex-lg-row flex-sm-column-reverse">
                        {/* <!-- First column for image --> */}
                        <div className="col-lg-6">
                            <div className="heading mb-40">
                                <div className="d-flex align-items-center">
                                    <div className="divider divider-primary mr-30"></div>
                                    <h2 className="heading__subtitle mb-0">Our marketing experts</h2>
                                </div>
                                <h3 className="heading__subtitle mb-10">
                                    work closely with businesses to create custom campaigns tailored to their specific needs and goals. We leverage the power of e-campaigners to promote companies, products, and services to a broader audience, increasing sales and driving growth.


                                </h3>
                                <p>
                                    We begin by analyzing your business and target audience to identify the most effective platforms for your e-campaign. We then create engaging campaigns that showcase your brand, highlight your unique selling points, and encourage conversions.
                                </p>
                                <p>
                                    With our help, you can leverage the power of e-campaigners to reach a larger audience and boost your business. We understand the intricacies of each platform and have the expertise to create effective campaigns that deliver results. Partnering with us means working with a team of professionals dedicated to your success.
                                </p>

                                <p>
                                    Contact us today to learn how we can help your business achieve its marketing goals through e-campaigners.
                                </p>

                                <p>
                                    In summary, our Selling Platforms service provides businesses with a solid online presence that attracts new customers and retains existing ones. We understand the importance of creating effective marketing campaigns and provide tailored solutions that meet each business's specific needs.
                                </p>

                            </div>
                            <div>
                                <button className="pricing__footer">
                                    <PriceTableModal />
                                </button>
                            </div>
                        </div>
                        {/* <!-- Second column for content --> */}
                        <div className="col-lg-6">
                            <div className="about__img">
                                <img src={logo} alt="e-Campaigners" />
                            </div>

                        </div>

                    </div>
                </div>
            </section>
        </>
    );
}

import React from "react";
import img from "../../../../assets/images/about/7.jpg";
import img3 from "../../../../assets/images/about/singnture.png";
import SideBarAccordion from "../../../../components/SideBarAccordion/SideBarAccordion";

export default function SolutionsLayoutThree() {
  return (
    <section id="about" className="about-layout2 animated-Progressbars">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
            <div className="heading mb-40">
              <div className="d-flex align-items-center mb-20">
                <div className="divider divider-primary mr-30" />
                <h2 className="heading__subtitle mb-0">
                  Curate And Efficient Solution Of Video Production
                </h2>
              </div>
              <h3 className="heading__title mb-40">
                We work with clients to develop creative ideas that capture their brand essence and communicate their message effectively to their target audience.
              </h3>
            </div>
          </div>
          {/* /.col-xl-5 */}
        </div>
        {/* /.row */}
        <div className="row about__Text">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
            <div className="offset-xl-2 position-relative">
              <i className="icon-quote" />
              <p>
                Our team of experienced video producers and editors will take you through the entire production process, from concept development and scripting to filming and editing. We use the latest equipment and techniques to produce high-quality videos that are engaging, professional, and visually stunning.
                <br />
                <br />
                We understand that every business is unique, so we offer tailored solutions to meet your specific needs. Our services include corporate videos, product demos, event coverage, social media videos, and more. In addition, we take the time to understand your business goals and objectives and create videos that deliver results.
                <br />
                <br />
                Partnering with us means working with a team of professionals dedicated to your success. We go above and beyond to ensure that our clients are satisfied with the final product and that it achieves their desired outcomes.
                <br />
                <br />
                Let us help you create high-quality videos that capture your brand's essence and resonate with your target audience.
                <br />
                <br />
                In summary, our Network Partners service provides businesses with access to a range of partners that can help them succeed faster and more effectively. We work closely with our partners to provide tailored solutions that meet each business's specific needs and requirements.
              </p>
            </div>
            <div className="about__img mt-80 mb-30">
              <img src={img} alt="about" />
            </div>
            {/* /.about__img*/}
            <div className="video__btn-wrapper">
              <a
                className="video__btn video__btn-white popup-video"
                href="https://www.youtube.com/watch?v=nrJtHemSPW4"
              >
                <div className="video__player">
                  <i className="fa fa-play" />
                </div>
                <span className="video__btn-title">
                  Watch Our Presentation!
                </span>
              </a>
            </div>
            {/* /.video__btn-wrapper*/}
          </div>
          {/* /.col-xl-6 */}
          <SideBarAccordion />
          {/* /.col-xl-5 */}
        </div>
        {/* /.row */}
      </div>
      {/* /.container */}
    </section>
  );
}

import React from "react";
import img from "../../../../assets/images/about/7.jpg";
import img2 from "../../../../assets/images/about/3.jpg";
import img3 from "../../../../assets/images/about/singnture.png";
import SideBarAccordion from "../../../../components/SideBarAccordion/SideBarAccordion";

export default function SolutionsLayoutThree() {
  return (
    <section id="about" className="about-layout2 animated-Progressbars">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
            <div className="heading mb-40">
              <div className="d-flex align-items-center mb-20">
                <div className="divider divider-primary mr-30" />
                <h2 className="heading__subtitle mb-0">
                  Curate And Efficient Solution Of Social Marketing
                </h2>
              </div>
              <h3 className="heading__title mb-40">
                With the ability to reach a broad or focused audience!
              </h3>
            </div>
          </div>
          {/* /.col-xl-5 */}
        </div>
        {/* /.row */}
        <div className="row about__Text">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
            <div className="offset-xl-2 position-relative">
              <i className="icon-quote" />
              <p>Social media platforms like Facebook, Instagram, Twitter, LinkedIn, and others have become essential for businesses to promote their products and services.
                <br />
                <br />
                At Business Savvy UK, we understand the importance of social media marketing and offer comprehensive services designed to help businesses grow their sales through various social media platforms. Our team of social media experts works closely with clients to create custom strategies that reflect their business and target audience.
                <br />
                <br />
                We help businesses identify the right social media platforms for their business and create compelling and engaging content that resonates with their audience. We also use advanced analytics to measure the effectiveness of our social media campaigns, making informed decisions based on the data to optimize future campaigns.
                <br />
                <br />
                Our social media marketing services offer numerous benefits to businesses, including increased brand awareness, customer engagement, website traffic, and, ultimately, sales. By effectively utilizing social media, companies can build a loyal following, connect with their audience, and drive revenue growth.
                <br />
                <br />
                Partnering with us for your social media marketing needs means working with a team of professionals dedicated to your success. We take the time to understand your business, audience, and objectives to create custom strategies that deliver results.
                <br />
                <br />
                Contact us today to learn how we can help you achieve your business goals through effective social media marketing.

              </p>
            </div>
            <div className="about__img mt-80 mb-30">
              <img src={img} alt="about" />
            </div>
            {/* /.about__img*/}
            <div className="video__btn-wrapper">
              <a
                className="video__btn video__btn-white popup-video"
                href="https://www.youtube.com/watch?v=nrJtHemSPW4"
              >
                <div className="video__player">
                  <i className="fa fa-play" />
                </div>
                <span className="video__btn-title">
                  Watch Our Presentation!
                </span>
              </a>
            </div>
            {/* /.video__btn-wrapper*/}
          </div>
          {/* /.col-xl-6 */}
          <SideBarAccordion />
          {/* /.col-xl-5 */}
        </div>
        {/* /.row */}
      </div>
      {/* /.container */}
    </section>
  );
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import logo from './assets/Copywriting.jpg';
import PriceTableModal from "../../../../components/Modal/PriceTableModal";



export default function CopywritingSub() {
    return (
        <>
            <section className="about-layout4 pt-80 pb-0" id="copywritingSub">
                <h2 className="heading__title text-center mb-20 text-uppercase">
                    Copy writing
                </h2>
                <h3 className="heading__subtitle_para mb-20">
                    At Business Savvy UK, we believe that high-quality copywriting Is an essential element of successful marketing and building a solid brand. Our experienced team of copywriters are skilled in crafting custom content that speaks directly to your target audience and showcases your business's unique value proposition.
                </h3>
                <div className="container">
                    <div className="row flex-lg-row flex-sm-column-reverse">
                        {/* <!-- First column for image --> */}
                        <div className="col-lg-6">
                            <div className="heading mb-40">
                                <div className="d-flex align-items-center">
                                    <div className="divider divider-primary mr-30"></div>
                                    <h2 className="heading__subtitle mb-0">
                                        We understand that different
                                    </h2>
                                </div>
                                <h3 className="heading__subtitle mb-10">
                                    businesses have different needs, so we offer a range of copywriting services to meet your specific requirements. Whether you need website copy, marketing materials, or product descriptions for online selling platforms like Amazon and eBay, our team has the expertise to deliver exceptional results.
                                </h3>
                                <p>
                                    We take the time to understand your business, audience, and marketing objectives, enabling us to create compelling, persuasive copy that drives results. Our services include captivating crafting headlines and taglines, writing engaging website copy, creating compelling marketing materials, and crafting product descriptions that sell.
                                </p>
                                <p>
                                    Moreover, our copywriting services are not limited to a single language. Our team of professionals can create a copy in multiple languages, helping you reach a wider audience and grow your business globally.
                                </p>
                                <p>
                                    Partnering with us for your copywriting needs means working with a team of professionals dedicated to your success. We take the time to understand your business, audience, and goals to deliver content that drives traffic and converts leads into sales.

                                </p>
                                <p>
                                    Don't settle for average copywriting services that fail to deliver the desired results. Instead, contact us today to learn how we can help you achieve your business goals through effective and engaging copywriting.
                                </p>
                            </div>
                            <div>
                            <button className="pricing__footer">
                                    <PriceTableModal />
                                </button>
                            </div>
                        </div>
                        {/* <!-- Second column for content --> */}
                        <div className="col-lg-6">
                            <div className="about__img">
                                <img src={logo} alt="Copywriting" />
                            </div>

                        </div>

                    </div>
                </div>
            </section>
        </>
    );
}

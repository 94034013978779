import React from "react";
import img from "../../../../assets/images/about/7.jpg";
import img2 from "../../../../assets/images/about/3.jpg";
import img3 from "../../../../assets/images/about/singnture.png";
import SideBarAccordion from "../../../../components/SideBarAccordion/SideBarAccordion";

export default function SolutionsLayoutThree() {
  return (
    <section id="about" className="about-layout2 animated-Progressbars">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
            <div className="heading mb-40">
              <div className="d-flex align-items-center mb-20">
                <div className="divider divider-primary mr-30" />
                <h2 className="heading__subtitle mb-0">
                  Curate And Efficient Solution Of Advertising & Marketing
                </h2>
              </div>
              <h3 className="heading__title mb-40">
                With our advertising and marketing design services!!
              </h3>
            </div>
          </div>
          {/* /.col-xl-5 */}
        </div>
        {/* /.row */}
        <div className="row about__Text">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
            <div className="offset-xl-2 position-relative">
              <i className="icon-quote" />
              <p>Our team of experienced designers works closely with clients to understand their unique value propositions, target audience, and marketing goals.
                <br />
                <br />
                We create custom designs for various marketing materials, including flyers, brochures, posters, social media graphics, and more. Our designs are tailored to each business's unique brand identity and marketing message, ensuring they stand out in a crowded market.
                <br />
                <br />
                We understand the importance of creating excellent marketing materials that look great and effectively communicate the benefits of a business's products or services. That's why we focus on creating visually appealing designs and highly effective in converting potential customers.
                <br />
                <br />
                Our advertising and marketing designs are optimized for various platforms, including social media, email, and traditional print media. In addition, we work closely with clients to ensure that their marketing materials reach the right audience and generate a high return on investment.
              </p>
            </div>
            <div className="about__img mt-80 mb-30">
              <img src={img} alt="about" />
            </div>
            {/* /.about__img*/}
            <div className="video__btn-wrapper">
              <a
                className="video__btn video__btn-white popup-video"
                href="https://www.youtube.com/watch?v=nrJtHemSPW4"
              >
                <div className="video__player">
                  <i className="fa fa-play" />
                </div>
                <span className="video__btn-title">
                  Watch Our Presentation!
                </span>
              </a>
            </div>
            {/* /.video__btn-wrapper*/}
          </div>
          {/* /.col-xl-6 */}
          <SideBarAccordion />
          {/* /.col-xl-5 */}
        </div>
        {/* /.row */}
      </div>
      {/* /.container */}
    </section>
  );
}

import React from "react";
import img from "../../../../assets/images/about/7.jpg";
import img3 from "../../../../assets/images/about/singnture.png";
import SideBarAccordion from "../../../../components/SideBarAccordion/SideBarAccordion";

export default function SolutionsLayoutThree() {
  return (
    <section id="about" className="about-layout2 animated-Progressbars">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
            <div className="heading mb-40">
              <div className="d-flex align-items-center mb-20">
                <div className="divider divider-primary mr-30" />
                <h2 className="heading__subtitle mb-0">
                  Curate And Efficient Solution Of Finance
                </h2>
              </div>
              <h3 className="heading__title mb-40">
                That's why we provide a variety of skilled finance professionals to meet the unique needs of our clients.
              </h3>
            </div>
          </div>
          {/* /.col-xl-5 */}
        </div>
        {/* /.row */}
        <div className="row about__Text">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
            <div className="offset-xl-2 position-relative">
              <i className="icon-quote" />
              <p>Our team of experienced recruiters works with businesses to understand their specific requirements and source the most qualified candidates. We offer a wide range of finance staffing solutions, including temporary, contract, and permanent staffing. In addition, we provide finance professionals at all levels, from entry-level bookkeepers to senior-level CFOs.
                <br />
                <br />
                We work with various businesses across different industries, including banking, insurance, accounting, and more. As a result, our finance professionals have expertise in multiple areas, such as financial analysis, tax, audit, risk management, and compliance.
                <br />
                <br />
                Partnering with us means working with a team of professionals dedicated to delivering the best finance staffing solutions for your business. We take the time to understand your unique requirements and provide customized solutions that meet your needs. In addition, we aim to help companies find the right finance talent to drive growth and success.
                <br />
                <br />
                With our finance staffing solutions, businesses can benefit from cost savings, increased productivity, and access to a pool of highly skilled finance professionals. Let us help you find the right finance talent to take your business to the next level.
                <br />
                <br />
                In summary, our Recruitment Services provide businesses with access to the right people to achieve success. We understand the importance of finding the right people for each industry and provide tailored solutions that meet each business's specific needs and requirements.
              </p>
            </div>
            <div className="about__img mt-80 mb-30">
              <img src={img} alt="about" />
            </div>
            {/* /.about__img*/}
            <div className="video__btn-wrapper">
              <a
                className="video__btn video__btn-white popup-video"
                href="https://www.youtube.com/watch?v=nrJtHemSPW4"
              >
                <div className="video__player">
                  <i className="fa fa-play" />
                </div>
                <span className="video__btn-title">
                  Watch Our Presentation!
                </span>
              </a>
            </div>
            {/* /.video__btn-wrapper*/}
          </div>
          {/* /.col-xl-6 */}
          <SideBarAccordion />
          {/* /.col-xl-5 */}
        </div>
        {/* /.row */}
      </div>
      {/* /.container */}
    </section>
  );
}

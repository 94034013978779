import React, { useEffect } from 'react'
import Headers from '../../components/header/Headers'
import DarkFooter from '../../components/footer/DarkFooter'
import RefundContainer from './RefundContainer'

const Refund = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <div>
    <Headers Class="bg-grays"/>
     <RefundContainer />
    <DarkFooter />
    </div>
  )
}

export default Refund
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ContactForm from "../../pages/ContactUs/ContactForm";
import HubspotFormV1 from "../from";
import CommonModal from "./CommonModal";
const PriceTableModal = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button
        variant="primary"
        onClick={handleShow}
        className="btn action__btn-contact btn__secondary"
      >
        Request a quote
      </Button>

      {/* <Modal show={show} onHide={handleClose} className='d-flex justify-content-center align-items-center h-100'>
                <Modal.Body className='modal-new'
                >
                    <ContactForm handleClose={handleClose} />
                </Modal.Body>
                <HubspotFormV1 />
            </Modal> */}

      {show ? (
        <CommonModal handelClose={handleClose}>
          <ContactForm handleClose={handleClose} />
          <HubspotFormV1 />
        </CommonModal>
      ) : (
        ""
      )}
    </>
  );
};

export default PriceTableModal;

import React, { useEffect, useState } from "react";
import Headers from "../../components/header/Headers";
import SecondaryNav from "../../components/header/SecondaryNav";
import BlogTitlePage from "../CaseStudiesGrid/blogSections/BlogTitlePage";
import Faq from "./faqSections/Faq";
import BannerLayoutFour from "./faqSections/BannerLayoutFour";
import AboutLayoutFive from "./faqSections/AboutLayoutFive";
import DarkFooter from "../../components/footer/DarkFooter";

export default function Faqs() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <Headers />
      <BlogTitlePage title="Faqs" />
      <SecondaryNav />
      <Faq />
      <BannerLayoutFour />
      <AboutLayoutFive />
      <DarkFooter />
    </>
  );
}

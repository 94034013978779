import React, { useEffect, useState } from "react";
import Footer from "../../components/footer/DarkFooter";
import Headers from "../../components/header/Headers";
import SecondaryNav from "../../components/header/SecondaryNav";
import BlogGridSection from "../Home/homeSections/BlogGridSection";
import PortfolioGridSection from "../Home/homeSections/PortfolioGridSection";
import BannerLayoutThree from "../Industries/IndestriesSections/BannerLayoutThree";
import SolutionBannerLayoutOne from "../ItSolutionsSinglePage/ItSolutionsSinglePageSections/SolutionBannerLayoutOne";
import AboutLayoutThree from "./whyUsSections/AboutLayoutThree";
import WhyUsClients from "./whyUsSections/WhyUsClients";
import WhyUsTitle from "./whyUsSections/WhyUsTitle";

export default function WhyUs() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <Headers />
      <WhyUsTitle />
      <SecondaryNav />
      <AboutLayoutThree />
      <WhyUsClients />
      <BannerLayoutThree />
      <SolutionBannerLayoutOne />
       <Footer />
    </>
  );
}

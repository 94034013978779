import React from "react";
import img from "../../../../assets/images/about/7.jpg";
import img2 from "../../../../assets/images/about/3.jpg";
import img3 from "../../../../assets/images/about/singnture.png";
import SideBarAccordion from "../../../../components/SideBarAccordion/SideBarAccordion";

export default function SolutionsLayoutThree() {
  return (
    <section id="about" className="about-layout2 animated-Progressbars">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
            <div className="heading mb-40">
              <div className="d-flex align-items-center mb-20">
                <div className="divider divider-primary mr-30" />
                <h2 className="heading__subtitle mb-0">
                  Curate And Efficient Solution Of Web App
                </h2>
              </div>
              <h3 className="heading__title mb-40">
                We design, develop, and deliver bespoke mobile applications that are user-friendly, secure, and scalable.
              </h3>
            </div>
          </div>
          {/* /.col-xl-5 */}
        </div>
        {/* /.row */}
        <div className="row about__Text">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
            <div className="offset-xl-2 position-relative">
              <i className="icon-quote" />
              <p>Ensuring they meet the requirements of modern businesses.
                <br />
                <br />
                Our team of experienced developers uses the latest technologies and tools to create high-quality mobile applications for various platforms.
                <br />
                <br />
                Our services include:
                <br />
                <br />
                1.	Design and Development: We create custom mobile applications that cater to the unique needs of your business. Our development process involves careful planning, prototyping, designing, and testing, ensuring a user-friendly interface and experience. We use agile methodologies to deliver projects on time and within budget.
                <br />
                <br />
                2.	Cross-Platform App Development: Our expert developers are proficient in developing mobile applications that work seamlessly across different platforms, including iOS, Android, and Windows. This ensures that your app has a broad reach and maximizes your customer base.
                <br />
                <br />
                3.	App Integration and Testing: Our team ensures that your mobile application fully integrates with your business systems, including back-end servers, APIs, and third-party systems. We perform extensive testing to ensure the app is reliable, efficient, and secure.
                <br />
                <br />
                4.	Maintenance and Support: We provide ongoing care and support to ensure your app continues to perform at its best. Our support team is always available to help you with any issues that may arise, making sure your app runs smoothly.
                <br />
                <br />
                Our app development services will help your business engage with customers, streamline operations, and increase revenue. Contact us today for a free consultation and let us help you build a mobile solution that meets your business needs.
              </p>
            </div>
            <div className="about__img mt-80 mb-30">
              <img src={img} alt="about" />
            </div>
            {/* /.about__img*/}
            <div className="video__btn-wrapper">
              <a
                className="video__btn video__btn-white popup-video"
                href="https://www.youtube.com/watch?v=nrJtHemSPW4"
              >
                <div className="video__player">
                  <i className="fa fa-play" />
                </div>
                <span className="video__btn-title">
                  Watch Our Presentation!
                </span>
              </a>
            </div>
            {/* /.video__btn-wrapper*/}
          </div>
          {/* /.col-xl-6 */}
          <SideBarAccordion />
          {/* /.col-xl-5 */}
        </div>
        {/* /.row */}
      </div>
      {/* /.container */}
    </section>
  );
}

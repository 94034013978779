import { hover } from "@testing-library/user-event/dist/hover";
import React, { useState } from "react";
import { DefaultPlayer as Video } from "react-html5video";
import "react-html5video/dist/styles.css";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

export default function VideoPlayer({ VideoUrl, isOpen, setIsOpen }) {
  return (
    <Modal style={{ marginTop: 80, padding: '0px' }} size="lg" isOpen={isOpen} toggle={() => setIsOpen(false)}>
      <div
        onClick={() => setIsOpen(false)}
        style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
      >
        <i
          style={{
            position: "relative",
            zIndex: "99",
            display: "block",
            bottom: "35px",
            right: '0px',
            marginRight: '1px',
            color: "#000",
            cursor: "pointer",
            fontWeight: "bold",
            fontSize: '30px'
          }}
          class="far fa-times-circle text-white"></i>
      </div>
      <ModalBody style={{ padding: '0px ', position: "absolute" }}>
        <Video
          autoPlay
          loop
          muted
          controls={["PlayPause", "Seek", "Time", "Volume", "Fullscreen"]}
          poster="http://sourceposter.jpg"
          onCanPlayThrough={() => {
            // Do stuff
          }}
        >
          <source src={VideoUrl} type="video/webm" />
          <track
            label="English"
            kind="subtitles"
            srcLang="en"
            src="http://source.vtt"
            default
          />
        </Video>
      </ModalBody>
      {/* <ModalFooter>
        <Button color="secondary" onClick={() => setIsOpen(false)}>
          Cancel
        </Button>
      </ModalFooter> */}
    </Modal>
  );
}

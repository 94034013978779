import React, { useEffect, useState } from "react";
import Headers from "../../components/header/Headers";
import SecondaryNav from "../../components/header/SecondaryNav";
import LeaderShipTitle from "./leadershipSections/LeaderShipTitle";
import TeamLayoutOne from "./leadershipSections/TeamLayoutOne";
import DarkFooter from '../../components/footer/DarkFooter'

export default function LeadershipTeam() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <Headers />
      <LeaderShipTitle />
      <SecondaryNav/>
      <TeamLayoutOne/>
      <DarkFooter/>
    </>
  );
}

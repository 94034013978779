/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import logo from './assets/ITsupport.jpg';
import PriceTableModal from "../../../../components/Modal/PriceTableModal";



export default function ITSupportSub() {
    return (
        <>
            <section className="about-layout4 pt-80 pb-0" id="iTSupportSub">
                <h2 className="heading__title text-center mb-20 text-uppercase">
                    IT Support
                </h2>

                <h3 className="heading__subtitle_para mb-20">
                    In today's fast-paced business environment, reliable and efficient
                    IT infrastructure is critical to success. At Business Savvy UK, we offer a comprehensive range of IT support and services designed to keep your business running smoothly.
                </h3>
                <div className="container">
                    <div className="row flex-lg-row">
                        {/* <!-- First column for image --> */}
                        <div className="col-lg-6 mb-10">
                            <div className="about__img">
                                <img src={logo} alt="IT support" />
                            </div>

                        </div>

                        {/* <!-- Second column for content --> */}
                        <div className="col-lg-6 mt-10">
                            <div className="heading mb-40">
                                <div className="d-flex align-items-center">
                                    <div className="divider divider-primary mr-30"></div>
                                    <h2 className="heading__subtitle mb-0">
                                        Our team of IT experts
                                    </h2>
                                </div>
                                <h3 className="heading__subtitle mb-10">
                                    has the experience and knowledge to help you manage your hardware, software, network, and security needs. In addition, we work closely with our clients to provide tailored solutions that meet their specific requirements.
                                </h3>

                                <p>
                                    Our hardware support services ensure that your devices are always running smoothly and that any issues are addressed promptly. From desktops to laptops and everything in between, we have the expertise to keep your hardware running at peak performance.
                                </p>
                                <p>
                                    Our software support services assist with software installation, updates, and troubleshooting. We work with you to ensure your software is up-to-date and functioning correctly. Our network support services ensure that your network is always up and running. We provide support for network hardware and software, as well as assistance with network setup and troubleshooting.
                                </p>

                                <p>
                                    And with our security support services, we help businesses keep their data and systems safe from cyber threats. We provide a range of security services, including antivirus software installation and updates, firewall management, and data backup and recovery.
                                </p>

                                <p>
                                    At Business Savvy UK, we understand the importance of reliable and secure IT infrastructure. That's why we offer a full range of IT support and services designed to keep your business operating efficiently and securely.
                                </p>

                                <p>
                                    Contact us today to learn more about how we can help your business succeed with our IT support and services.
                                </p>
                            </div>
                            <div>
                                <button className="pricing__footer">
                                    <PriceTableModal />
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    );
}
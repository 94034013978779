import React from "react";
import img from "../../../../assets/images/about/7.jpg";
import SideBarAccordion from "../../../../components/SideBarAccordion/SideBarAccordion";

export default function SolutionsLayoutThree() {
  return (
    <section id="about" className="about-layout2 animated-Progressbars">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
            <div className="heading mb-40">
              <div className="d-flex align-items-center mb-20">
                <div className="divider divider-primary mr-30" />
                <h2 className="heading__subtitle mb-0">
                  "UK Immigration Services by Business Savvy UK"
                </h2>
              </div>
              <h3 className="heading__title mb-40">
                At Business Savvy UK, we offer comprehensive UK immigration services to individuals and businesses looking to navigate the complex process of immigrating to the UK.
              </h3>
            </div>
          </div>
          {/* /.col-xl-5 */}
        </div>
        {/* /.row */}
        <div className="row about__Text">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
            <div className="offset-xl-2 position-relative">
              <i className="icon-quote" />
              <p>Our approach is client-focused, and we work closely with each client to understand their unique needs and provide tailored solutions. So whether you're an individual seeking to immigrate to the UK or a business looking to sponsor employees, we can help you navigate the complexities of UK immigration law.
                <br />
                <br />
                Our services include:
                <br />
                <br />
                ●	Visa applications: We assist clients with all types of UK visa applications, including visitor visas, student visas, and work visas.                <br />
                ●	Work permits: We help businesses sponsor foreign workers and guide them through the process of obtaining a UK work permit. <br />
                ●	Settlement and citizenship: We assist individuals with settlement and citizenship applications, including applications for indefinite leave to remain and naturalization.
                <br />
                <br />
                Partnering with Business Savvy UK means working with a team of professionals dedicated to your immigration success. We have a proven track record of helping clients achieve their immigration goals and can provide you with the guidance and support you need to succeed in your immigration journey.
              </p>
            </div>
            <div className="about__img mt-80 mb-30">
              <img src={img} alt="about" />
            </div>
            {/* /.about__img*/}
            <div className="video__btn-wrapper">
              <a
                className="video__btn video__btn-white popup-video"
                href="https://www.youtube.com/watch?v=nrJtHemSPW4"
              >
                <div className="video__player">
                  <i className="fa fa-play" />
                </div>
                <span className="video__btn-title">
                  Watch Our Presentation!
                </span>
              </a>
            </div>
            {/* /.video__btn-wrapper*/}
          </div>
          {/* /.col-xl-6 */}
          <SideBarAccordion />
          {/* /.col-xl-5 */}
        </div>
        {/* /.row */}
      </div>
      {/* /.container */}
    </section>
  );
}

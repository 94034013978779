import React from "react";
import img from "../../../../assets/images/about/7.jpg";
import SideBarAccordion from "../../../../components/SideBarAccordion/SideBarAccordion";

export default function SolutionsLayoutThree() {
  return (
    <section id="about" className="about-layout2 animated-Progressbars">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
            <div className="heading mb-40">
              <div className="d-flex align-items-center mb-20">
                <div className="divider divider-primary mr-30" />
                <h2 className="heading__subtitle mb-0">
                  Curate And Efficient Solution Of Education
                </h2>
              </div>
              <h3 className="heading__title mb-40">
                Our team of education recruitment specialists has a wealth of experience in sourcing and supplying high-quality candidates to:
              </h3>
            </div>
          </div>
          {/* /.col-xl-5 */}
        </div>
        {/* /.row */}
        <div className="row about__Text">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
            <div className="offset-xl-2 position-relative">
              <i className="icon-quote" />
              <p>Schools, colleges, universities, and other educational institutions.
                <br />
                <br />
                We understand the importance of having a team of skilled professionals in the education sector, which is why we offer a range of services to meet the needs of our clients. We work closely with our clients to understand their requirements and provide tailored recruitment solutions that meet their needs.
                <br />
                <br />
                Our recruitment services include sourcing candidates for teaching, school leadership, and administrative roles. We have an extensive pool of candidates with a wide range of skills and experience, including subject specialists, curriculum designers, and education technology experts.
                <br />
                <br />
                Our focus on quality means that we only supply candidates who have been rigorously vetted and are passionate about education. We also offer ongoing support to ensure that our clients are happy with the candidates we provide and that their staffing needs are met.
                Partnering with us means working with a team of professionals dedicated to your success in the education sector.
                <br />
                <br />
                We are passionate about positively impacting education and helping our clients achieve their goals. Whether you need a temporary or permanent staff member, we can provide the right person for the job.
              </p>
            </div>
            <div className="about__img mt-80 mb-30">
              <img src={img} alt="about" />
            </div>
            {/* /.about__img*/}
            <div className="video__btn-wrapper">
              <a
                className="video__btn video__btn-white popup-video"
                href="https://www.youtube.com/watch?v=nrJtHemSPW4"
              >
                <div className="video__player">
                  <i className="fa fa-play" />
                </div>
                <span className="video__btn-title">
                  Watch Our Presentation!
                </span>
              </a>
            </div>
            {/* /.video__btn-wrapper*/}
          </div>
          {/* /.col-xl-6 */}
          <SideBarAccordion />
          {/* /.col-xl-5 */}
        </div>
        {/* /.row */}
      </div>
      {/* /.container */}
    </section>
  );
}

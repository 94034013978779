import React from "react";
import bannerimgthree from "../../../assets/images/banners/3.jpg";
import { Link } from "react-router-dom";

export default function FeaturesLayoutSection() {
  return (
    <section className="features-layout1 pb-0">
      <div className="features-bg"></div>
      <div className="container">
        <div className="row heading heading-light mb-30">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <div className="divider divider-primary mb-20" />
            <h3 className="heading__title">
              Business Solutions that <br />Propel Success.
            </h3>
            <p className="heading__desc">
              At Business Savvy UK, we help businesses achieve their sales
              and marketing goals through our innovative range of services.
              Our team of experts provide companies with tailored solutions to reach their target
              audience effectively, expanding their reach and increasing their revenue. In addition,
              we have a vast network of partners, national exhibitions, and recruitment services to ensure we
              can provide businesses with the right people to achieve their success.
            </p>
            <p className="heading__desc">
              {" "}
            </p>
          </div>
          {/* /col-lg-5 */}

          {/* /.col-lg-6 */}
        </div>
        {/* /.row */}
        <div className="row">
          {/* Feature item #1 */}
          <div className="col-sm-6 col-md-6 col-lg-3">
            <div className="feature-item text-center">
              <div className="feature__icon">
                <i className="icon-software" />
              </div>
              <h4 className="feature__title">Sales & Marketing</h4>
              {/* <p className="feature__desc">
              We provide businesses with the support they need to achieve 
              their sales and marketing goals and reach their target audience effectively. 
              </p> */}
              <Link to="/Sales-Marketing" className="btn__link">
                <i className="icon-arrow-right icon-outlined" />
              </Link>
            </div>
            {/* /.feature-item */}
          </div>
          {/* /.col-lg-3 */}
          {/* Feature item #2 */}
          <div className="col-sm-6 col-md-6 col-lg-3">
            <div className="feature-item text-center">
              <div className="feature__icon">
                <i className="icon-dos" />
              </div>
              <h4 className="feature__title">Network Partners</h4>
              {/* <p className="feature__desc">
              We have a range of network partners that help businesses succeed faster and more effectively
              </p> */}
              <Link to="/Network-Partners" className="btn__link">
                <i className="icon-arrow-right icon-outlined" />
              </Link>
            </div>
            {/* /.feature-item */}
          </div>
          {/* /.col-lg-3 */}
          {/* Feature item #3 */}
          <div className="col-sm-6 col-md-6 col-lg-3">
            <div className="feature-item text-center">
              <div className="feature__icon">
                <i className="icon-operating-system" />
              </div>
              <h4 className="feature__title">Design Services</h4>
              {/* <p className="feature__desc">
              Our national retail and trade exhibitions expose businesses to unlimited opportunities to 
              capitalize on instant revenue and build their brand and reseller market share.
              </p> */}
              <Link to="/Design-Services" className="btn__link">
                <i className="icon-arrow-right icon-outlined" />
              </Link>
            </div>
            {/* /.feature-item */}
          </div>
          {/* /.col-lg-3 */}
          {/* Feature item #4 */}
          <div className="col-sm-6 col-md-6 col-lg-3">
            <div className="feature-item text-center">
              <div className="feature__icon">
                <i className="icon-machine-learning" />
              </div>
              <h4 className="feature__title">Recruitment</h4>
              {/* <p className="feature__desc">
                Our technology allows you to offer the latest software to your
                possible customers!
              </p> */}
              <Link to="/Recruitment" className="btn__link">
                <i className="icon-arrow-right icon-outlined" />
              </Link>
            </div>
            {/* /.feature-item */}
          </div>
          {/* /.col-lg-3 */}
        </div>
        {/* /.row */}
        <div className="row mt-40">
          <div className="col-sm-12 col-md-12 col-lg-6 d-flex flex-column justify-content-between">
            <div className="row row-no-gutter read-note">
              <div className="col-sm-4">
                <div className="rating mb-10">
                  <i className="fas fa-star" />
                  <i className="fas fa-star" />
                  <i className="fas fa-star" />
                  <i className="fas fa-star" />
                  <i className="fas fa-star" />
                </div>
              </div>
              {/* /.col-lg-4 */}
              <div className="col-sm-8">
                <p className="read-note__text color-white">
                  <span className="font-weight-bold text-underlined">
                    99.9% Customer Satisfaction
                  </span>
                  based on 750+ reviews and 20,000 Objective Resource
                </p>
              </div>
              {/* /.col-lg-8 */}
            </div>
            {/* /.row */}
            <div className="row">
              <div className="col-sm-6">
                <p className="mb-30 font-weight-bold sub__desc">
                  As one of the world's largest ITService Providers with over
                  120 engineers and IT support staff are ready to help.
                </p>
                <a
                  href="#"
                  className="btn btn__primary btn__bordered btn__icon mb-30"
                >
                  <span>Client to Connect us</span>
                  <i className="icon-arrow-right" />
                </a>
              </div>
              {/* /.col-sm-6 */}
              <div className="col-sm-6">
                <ul className="list-items list-unstyled mb-30">
                  <li>450,000 client’s interactions </li>
                  <li>Help challenge critical activities</li>
                  <li>Simplify &amp; Automate Workflows</li>
                  <li>Peer perspectives and advice</li>
                </ul>
              </div>
              {/* /.col-sm-6 */}
            </div>
            {/* /.row */}
          </div>
          {/* /.col-lg-6 */}
          <div className="col-sm-12 col-md-12 col-lg-6">
            <img src={bannerimgthree} alt="banner" />
          </div>
          {/* /.col-lg-6 */}
        </div>
        {/* /.row */}
      </div>
      {/* /.container */}
    </section>
  );
}

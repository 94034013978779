import React from "react";
import img from "../../../../assets/images/about/7.jpg";
import img3 from "../../../../assets/images/about/singnture.png";
import SideBarAccordion from "../../../../components/SideBarAccordion/SideBarAccordion";

export default function SolutionsLayoutThree() {
  return (
    <section id="about" className="about-layout2 animated-Progressbars">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
            <div className="heading mb-40">
              <div className="d-flex align-items-center mb-20">
                <div className="divider divider-primary mr-30" />
                <h2 className="heading__subtitle mb-0">
                  Curate And Efficient Solution Of Digital Marketing
                </h2>
              </div>
              <h3 className="heading__title mb-40">
                Our team of experts is here to help you achieve your marketing goals and grow your business.
              </h3>
            </div>
          </div>
          {/* /.col-xl-5 */}
        </div>
        {/* /.row */}
        <div className="row about__Text">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
            <div className="offset-xl-2 position-relative">
              <i className="icon-quote" />
              <p>
                We understand that businesses must have a strong online presence in today's digital age. That's why we offer various digital marketing services to help you effectively reach your target audience. From search engine optimization to social media marketing, we use a variety of techniques to ensure your business is visible and engaging online.
                <br />
                <br />
                Our digital marketing services are designed to help businesses of all sizes find growth. Whether you're a start-up or an established business, we can create a tailored solution that meets your specific needs. Our team works closely with clients to understand their business and objectives, allowing us to create custom marketing strategies that deliver results.
                <br />
                <br />
                We use the latest tools and technologies to track and measure our clients' digital marketing success. This allows us to provide ongoing support and recommendations to help them stay ahead of the competition. In addition, we take a data-driven approach to digital marketing, ensuring our client's marketing efforts are optimized for maximum results.
                <br />
                <br />
                Partnering with us for your digital marketing needs means working with a team of experts dedicated to your success. We take the time to understand your business and objectives, creating tailored solutions that deliver results.
                <br />
                <br />
                Contact us today to learn how we can help your business grow through effective digital marketing.

              </p>
            </div>
            <div className="about__img mt-80 mb-30">
              <img src={img} alt="about" />
            </div>
            {/* /.about__img*/}
            <div className="video__btn-wrapper">
              <a
                className="video__btn video__btn-white popup-video"
                href="https://www.youtube.com/watch?v=nrJtHemSPW4"
              >
                <div className="video__player">
                  <i className="fa fa-play" />
                </div>
                <span className="video__btn-title">
                  Watch Our Presentation!
                </span>
              </a>
            </div>
            {/* /.video__btn-wrapper*/}
          </div>
          {/* /.col-xl-6 */}
          <SideBarAccordion />
          {/* /.col-xl-5 */}
        </div>
        {/* /.row */}
      </div>
      {/* /.container */}
    </section>
  );
}

import React from "react";

export default function ContactLayoutTwo() {
  return (
    <section className="contact-layout2 pt-0 pb-80 contact-bg">
      <div className="container">
        <div className="row">
          {/* Contact panel #1 */}
          <div className="col-sm-12 col-md-4 col-lg-4">
            <div className="contact-info-box">
              <h4 className="contact__info-box-title">London Office</h4>
              <ul className="contact__info-list list-unstyled">
                <li>
                  Email:{" "}
                  <a href="mailto:info@business-savvy-uk.co.uk">info@business-savvy-uk.co.uk</a>
                </li>
                <li>Address: Business Savvy UK Ltd <br/>Blackburn Road, Houghton Regis,<br/>
                        Dunstable LU5 5BQ,UK</li>
                <li>
                  Phone: <a href="tel:+441582551550">+44 (0) 1582 551 550</a>
                </li>
                <li>Hours: Mon-Fri: 8am – 7pm</li>
              </ul>
              {/* /.contact__info-list */}
            </div>
            {/* /.contact-info-box */}
          </div>
          {/* /.col-lg-4 */}
         
          {/* /.col-lg-4 */}
        </div>
        {/* /.row */}
      </div>
      {/* /.container */}
    </section>
  );
}

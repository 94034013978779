/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import logo from './assets/bussiness.jpg';
import PriceTableModal from "../../../../components/Modal/PriceTableModal";


export default function BusinessOpportunitieSub() {
    return (
        <>
            <section className="about-layout4 pt-80 pb-0" id="BusinessOpportunitieSub">
                <h2 className="heading__title text-center mb-20 text-uppercase">
                    Business Opportunities
                </h2>
                <h3 className="heading__subtitle_para mb-20">
                    At Business Savvy UK, we understand that finding The right business opportunity can be challenging. That's why we offer a comprehensive business opportunities service to help connect entrepreneurs with franchises, businesses for sale, or brand-new opportunities from our client base worldwide.
                </h3>
                <div className="container">
                    <div className="row flex-lg-row flex-sm-column-reverse">
                        {/* <!-- First column for image --> */}
                        <div className="col-lg-6">
                            <div className="heading mb-40">
                                <div className="d-flex align-items-center">
                                    <div className="divider divider-primary mr-30"></div>
                                    <h2 className="heading__subtitle mb-0">Our team of experts </h2>
                                </div>
                                <h3 className="heading__subtitle mb-10">
                                    works closely with clients to understand their specific needs, preferences, and objectives. We then leverage our extensive network and resources to identify and recommend suitable business opportunities that align with their goals.
                                </h3>

                                <p>
                                    Our business opportunities service covers a wide range of industries, from retail and hospitality to technology and healthcare. So, whether you're a first-time entrepreneur or a seasoned business owner, we have the expertise and experience to help you find the perfect opportunity.
                                </p>

                                <p>
                                    In addition to recommending opportunities, we also provide support throughout the process. This includes conducting due diligence, negotiating deals, and providing guidance on financing and legal matters.
                                </p>

                                <p>
                                    Partnering with us for your business opportunities means gaining access to a vast network of global business opportunities and the expertise and support needed to navigate the complex process of buying or investing in a business.
                                </p>

                                <p>
                                    Don't let the process of finding the right business opportunity overwhelm you. Let Business Savvy UK help you find the perfect opportunity to achieve your entrepreneurial dreams.
                                </p>
                                <p>
                                    Contact us today to learn how we can help you find the ideal business opportunity to match your goals and aspirations.
                                </p>
                            </div>
                            <div>
                                <button className="pricing__footer">
                                    <PriceTableModal />
                                </button>
                            </div>
                        </div>
                        {/* <!-- Second column for content --> */}
                        <div className="col-lg-6">
                            <div className="about__img">
                                <img src={logo} alt="bussiness" />
                            </div>

                        </div>

                    </div>
                </div>
            </section>
        </>
    );
}

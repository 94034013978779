import React from "react";
import img from "../../../../assets/images/about/7.jpg";
import img2 from "../../../../assets/images/about/3.jpg";
import img3 from "../../../../assets/images/about/singnture.png";
import SideBarAccordion from "../../../../components/SideBarAccordion/SideBarAccordion";

export default function SolutionsLayoutThree() {
  return (
    <section id="about" className="about-layout2 animated-Progressbars">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
            <div className="heading mb-40">
              <div className="d-flex align-items-center mb-20">
                <div className="divider divider-primary mr-30" />
                <h2 className="heading__subtitle mb-0">
                  Curate And Efficient Solution Of ECampaigners
                </h2>
              </div>
              <h3 className="heading__title mb-40">
                Manages service delivery across various business like, HR, Legal
                and other IT departments!!
              </h3>
            </div>
          </div>
          {/* /.col-xl-5 */}
        </div>
        {/* /.row */}
        <div className="row about__Text">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
            <div className="offset-xl-2 position-relative">
              <i className="icon-quote" />
              <p>One effective way to get a broader audience is through e-campaigners such as Wowcher, Groupon UK, HotUKDeals, and VoucherCodes.co.uk.
                <br />
                <br />
                Our marketing experts work closely with businesses to create custom campaigns tailored to their specific needs and goals. We leverage the power of e-campaigners to promote companies, products, and services to a broader audience, increasing sales and driving growth.
                <br />
                <br />
                We begin by analyzing your business and target audience to identify the most effective platforms for your e-campaign. We then create engaging campaigns that showcase your brand, highlight your unique selling points, and encourage conversions.
                <br />
                <br />
                With our help, you can leverage the power of e-campaigners to reach a larger audience and boost your business. We understand the intricacies of each platform and have the expertise to create effective campaigns that deliver results. Partnering with us means working with a team of professionals dedicated to your success.
                <br />
                <br />
                Contact us today to learn how we can help your business achieve its marketing goals through e-campaigners.
                <br />
                <br />
                In summary, our Selling Platforms service provides businesses with a solid online presence that attracts new customers and retains existing ones. We understand the importance of creating effective marketing campaigns and provide tailored solutions that meet each business's specific needs.
              </p>
            </div>
            <div className="about__img mt-80 mb-30">
              <img src={img} alt="about" />
            </div>
            {/* /.about__img*/}
            <div className="video__btn-wrapper">
              <a
                className="video__btn video__btn-white popup-video"
                href="https://www.youtube.com/watch?v=nrJtHemSPW4"
              >
                <div className="video__player">
                  <i className="fa fa-play" />
                </div>
                <span className="video__btn-title">
                  Watch Our Presentation!
                </span>
              </a>
            </div>
            {/* /.video__btn-wrapper*/}
          </div>
          {/* /.col-xl-6 */}
          <SideBarAccordion />
          {/* /.col-xl-5 */}
        </div>
        {/* /.row */}
      </div>
      {/* /.container */}
    </section>
  );
}

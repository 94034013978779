import React from "react";
import img from "../../../../assets/images/about/7.jpg";
import img2 from "../../../../assets/images/about/3.jpg";
import img3 from "../../../../assets/images/about/singnture.png";
import SideBarAccordion from "../../../../components/SideBarAccordion/SideBarAccordion";

export default function SolutionsLayoutThree() {
  return (
    <section id="about" className="about-layout2 animated-Progressbars">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
            <div className="heading mb-40">
              <div className="d-flex align-items-center mb-20">
                <div className="divider divider-primary mr-30" />
                <h2 className="heading__subtitle mb-0">
                  Curate And Efficient Solution Of Packaging
                </h2>
              </div>
              <h3 className="heading__title mb-40">
                Instead, it’s about creating a layout that conveys your brand message and sets your product apart from the competition.</h3>
            </div>
          </div>
          {/* /.col-xl-5 */}
        </div>
        {/* /.row */}
        <div className="row about__Text">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
            <div className="offset-xl-2 position-relative">
              <i className="icon-quote" />
              <p>At Business Savvy UK, our expert designers can help you create packaging that looks not only great but also communicates essential information about your product.
                <br />
                <br />
                We understand that packaging is often a customer's first touchpoint with a product, and it's crucial to make a lasting impression. That's why our team works closely with you to develop a design that reflects your brand's unique personality and values. We take the time to understand your target audience and the message you want to convey, ensuring that your packaging design resonates with your customers.
                <br />
                <br />
                Our packaging design services cover everything from the initial concept to the finished product. We'll work with you every step of the way, ensuring that your design meets your specific needs and helps you achieve your business goals. We also consider practical considerations, such as the size and shape of the packaging, materials used, and printing techniques, to ensure your design is functional and visually appealing.
                <br />
                <br />
                Whether you're looking to create new packaging for an existing product or launch a new one, we can help. Let our team at Business Savvy UK bring your vision to life and create a design that stands out on the shelves and makes a lasting impression on your customers.</p>
            </div>
            <div className="about__img mt-80 mb-30">
              <img src={img} alt="about" />
            </div>
            {/* /.about__img*/}
            <div className="video__btn-wrapper">
              <a
                className="video__btn video__btn-white popup-video"
                href="https://www.youtube.com/watch?v=nrJtHemSPW4"
              >
                <div className="video__player">
                  <i className="fa fa-play" />
                </div>
                <span className="video__btn-title">
                  Watch Our Presentation!
                </span>
              </a>
            </div>
            {/* /.video__btn-wrapper*/}
          </div>
          {/* /.col-xl-6 */}
          <SideBarAccordion />
          {/* /.col-xl-5 */}
        </div>
        {/* /.row */}
      </div>
      {/* /.container */}
    </section>
  );
}

import React from 'react';
import SingleServiceItems from './SingleServiceItems';
import SalespeopleSub from './RecruitmentServicesAllSub/SalespeopleSub/SalespeopleSub';
import TechnologyIndustrySub from './RecruitmentServicesAllSub/TechnologyIndustrySub/TechnologyIndustrySub';
import EducationSub from './RecruitmentServicesAllSub/EducationSub/EducationSub';
import MedicalHealthcareSub from './RecruitmentServicesAllSub/MedicalHealthcareSub/MedicalHealthcareSub';
import HospitalitySub from './RecruitmentServicesAllSub/HospitalitySub/HospitalitySub';
import FinanceSub from './RecruitmentServicesAllSub/FinanceSub/FinanceSub';
import RecruitmentServicesSub from './RecruitmentServicesAllSub/RecruitmentServicesSub/RecruitmentServicesSub';



const BrochureSection = () => {
    return (
        <section className="services-layout1 bg-gray pt-130 pb-90">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-6 offset-lg-3">
                        <div className="heading text-center mb-50">
                            <h2 className="heading__subtitle">
                                Business Savvy UK
                            </h2>
                            <h3 className="heading__title">
                                Recruitment Services Single Category items. Selected your affordable Service.
                            </h3>
                        </div>
                        {/* /.heading */}
                    </div>
                    {/* /.col-lg-8 */}
                </div>
                <>
                    <SingleServiceItems />
                </>
                {/* Sales people sub */}
                <SalespeopleSub />
                {/*  Sales people Card  */}
                {/* <Pricing /> */}
                {/* Technology Industry sub */}
                <TechnologyIndustrySub />
                {/* Technology Industry Card  */}
                {/* <Pricing /> */}
                {/* Education sub */}
                <EducationSub />
                {/* Education Card  */}
                {/* <Pricing /> */}
                {/* Medical sub */}
                <MedicalHealthcareSub />
                {/* Medical Card  */}
                {/* <Pricing /> */}
                {/* hostility sub */}
                <HospitalitySub />
                {/* hostility Card  */}
                {/* <Pricing /> */}
                {/* finance sub */}
                <FinanceSub />
                {/* finance Card  */}
                {/* <Pricing /> */}
                {/* recruitment sub */}
                <RecruitmentServicesSub />
                {/*  recruitment  Card  */}
                {/* <Pricing /> */}
            </div>
            {/* /.container */}
        </section>
    );
};

export default BrochureSection;
import React from "react";
import img from "../../../../assets/images/about/7.jpg";
import img2 from "../../../../assets/images/about/3.jpg";
import img3 from "../../../../assets/images/about/singnture.png";
import SideBarAccordion from "../../../../components/SideBarAccordion/SideBarAccordion";

export default function SolutionsLayoutThree() {
  return (
    <section id="about" className="about-layout2 animated-Progressbars">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
            <div className="heading mb-40">
              <div className="d-flex align-items-center mb-20">
                <div className="divider divider-primary mr-30" />
                <h2 className="heading__subtitle mb-0">
                  Curate And Efficient Solution Of AIML
                </h2>
              </div>
              <h3 className="heading__title mb-40">
                With the ability to learn, adapt, and improve independently, AI and ML services are helping businesses across all sectors work more efficiently,</h3>
            </div>
          </div>
          {/* /.col-xl-5 */}
        </div>
        {/* /.row */}
        <div className="row about__Text">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
            <div className="offset-xl-2 position-relative">
              <i className="icon-quote" />
              <p>
                At Business Savvy UK, we offer comprehensive AI and ML services designed to help businesses improve their operations and achieve their goals. Our team of experts deeply understands the latest AI and ML technologies and how they can be applied to various applications in all sectors of business and personal activities.
                <br />
                <br />
                Whether you need to automate repetitive tasks, develop predictive analytics models, or build custom AI and ML solutions, we have the expertise to help. We work closely with clients to understand their specific needs and provide tailored solutions that are scalable and cost-effective.
                <br />
                <br />
                Our AI and ML services can help businesses improve their decision-making processes, increase productivity, and drive growth. From chatbots that will enhance customer service to predictive analytics that enable companies to make more informed decisions, our services are designed to help businesses stay ahead of the competition.
                <br />
                <br />
                Partnering with us for your AI and ML needs means working with a team of professionals dedicated to your success. We take the time to understand your business, goals, and unique needs to create custom solutions that meet your specific requirements. Contact us today to learn how we can help you leverage the power of AI and ML to take your business to the next level.
              </p>
            </div>
            <div className="about__img mt-80 mb-30">
              <img src={img} alt="about" />
            </div>
            {/* /.about__img*/}
            <div className="video__btn-wrapper">
              <a
                className="video__btn video__btn-white popup-video"
                href="https://www.youtube.com/watch?v=nrJtHemSPW4"
              >
                <div className="video__player">
                  <i className="fa fa-play" />
                </div>
                <span className="video__btn-title">
                  Watch Our Presentation!
                </span>
              </a>
            </div>
            {/* /.video__btn-wrapper*/}
          </div>
          {/* /.col-xl-6 */}
          <SideBarAccordion />
          {/* /.col-xl-5 */}
        </div>
        {/* /.row */}
      </div>
      {/* /.container */}
    </section>
  );
}

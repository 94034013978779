import React, { useEffect, useState } from "react";
import Headers from "../../components/header/Headers";
import SolutionFaq from "../ItSolutionsSinglePage/ItSolutionsSinglePageSections/SolutionFaq";
import PricingBanner from "./pricingSections/PricingBanner";
import PricingClient from "./pricingSections/PricingClient";
import PricingTitle from "./pricingSections/PricingTitle";
import PricingWorkProcess from "./pricingSections/PricingWorkProcess";
import DarkFooter from "../../components/footer/DarkFooter";

export default function Pricing() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <Headers />
      <PricingTitle />
      <PricingBanner />
      <PricingClient />
      <PricingWorkProcess />
      <SolutionFaq />
      <DarkFooter />
    </>
  );
}

import React from "react";
import Minislider from "../../../components/slider/Minislider";
import { TestimonailsSlider } from "../../../components/slider/TestimonailsSlider";
import CommonQuote from "../../../components/CommonQuote/CommonQuote";

export default function BannerLayoutTwo() {
  return (
    <section className="banner-layout2 pb-0 bg-overlay bg-overlay-primary" id="requestQuote">
      <div className="bg-slider-img-Banner"></div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6 col-inner">
            <div className="heading heading-light">
              <h3 className="heading__title mb-30">
                Satisfied Users Over The Globe
              </h3>
            </div>
            <TestimonailsSlider />
            {/* /.testimonials */}
            <div className="divider divider-light w-100 mt-60 mb-60" />
            <div className="heading heading-light">
              <h3 className="heading__title mb-30">Our Trusted Clients</h3>
            </div>
            <div className="clients">
              <Minislider />
              {/* /.carousel */}
            </div>
          </div>
          {/* /.col-xl-6 */}
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
            <CommonQuote />
          </div>
          {/* /.col-xl-6 */}
        </div>
        {/* /.row */}
      </div>
      {/* /.container */}
    </section>
  );
}

import React from "react";
import img from "../../../../assets/images/about/7.jpg";
import img2 from "../../../../assets/images/about/3.jpg";
import img3 from "../../../../assets/images/about/singnture.png";
import SideBarAccordion from "../../../../components/SideBarAccordion/SideBarAccordion";

export default function SolutionsLayoutThree() {
  return (
    <section id="about" className="about-layout2 animated-Progressbars">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
            <div className="heading mb-40">
              <div className="d-flex align-items-center mb-20">
                <div className="divider divider-primary mr-30" />
                <h2 className="heading__subtitle mb-0">
                  Curate And Efficient Solution Of Accountants
                </h2>
              </div>
              <h3 className="heading__title mb-40">
                Every business is unique and requires a tailored accounting and tax services approach.
              </h3>
            </div>
          </div>
          {/* /.col-xl-5 */}
        </div>
        {/* /.row */}
        <div className="row about__Text">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
            <div className="offset-xl-2 position-relative">
              <i className="icon-quote" />
              <p>Our team works closely with clients to understand their business and provide the most effective solutions to meet their specific needs.
                <br />
                <br />
                Our services include tax planning and compliance, accounting, bookkeeping, payroll, and business advisory services. We have extensive experience dealing with complex tax issues and providing proactive tax planning to help our clients save money and achieve their financial goals.
                <br />
                <br />
                Our team stays up to date with the latest tax regulations and accounting standards to ensure our clients receive accurate and compliant services. In addition, we use the latest technology and tools to streamline our processes and provide clients with a seamless and efficient service.
                <br />
                <br />
                Partnering with us means working with a team of professionals who are dedicated to your success.
                <br />
                <br />
                We take the time to understand your business and provide tailored solutions that meet your specific needs. We aim to help companies save time and money by providing efficient and effective accounting and taxation services.
              </p>
            </div>
            <div className="about__img mt-80 mb-30">
              <img src={img} alt="about" />
            </div>
            {/* /.about__img*/}
            <div className="video__btn-wrapper">
              <a
                className="video__btn video__btn-white popup-video"
                href="https://www.youtube.com/watch?v=nrJtHemSPW4"
              >
                <div className="video__player">
                  <i className="fa fa-play" />
                </div>
                <span className="video__btn-title">
                  Watch Our Presentation!
                </span>
              </a>
            </div>
            {/* /.video__btn-wrapper*/}
          </div>
          {/* /.col-xl-6 */}
          <SideBarAccordion />
          {/* /.col-xl-5 */}
        </div>
        {/* /.row */}
      </div>
      {/* /.container */}
    </section>
  );
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import logo from './assets/ebay.jpg';
import PriceTableModal from "../../../../components/Modal/PriceTableModal";


export default function EbaySub() {
    return (
        <>
            <section className="about-layout4 pt-80 pb-0" id="EbaySub">
                <h2 className="heading__title text-center mb-20 text-uppercase">
                    Ebay
                </h2>
                <h3 className="heading__subtitle_para mb-20">
                    At BuyBox Savvy, we understand The unique challenges of being an eBay seller. That's why we offer expert consulting services to help businesses optimize their eBay presence and achieve success in this competitive marketplace.
                </h3>
                <div className="container">
                    <div className="row flex-lg-row">
                        {/* <!-- First column for image --> */}
                        <div className="col-lg-6 mb-10">
                            <div className="about__img">
                                <img src={logo} alt="Ebay" />
                            </div>

                        </div>

                        {/* <!-- Second column for content --> */}
                        <div className="col-lg-6 mt-10">
                            <div className="heading mb-40">
                                <div className="d-flex align-items-center">
                                    <div className="divider divider-primary mr-30"></div>
                                    <h2 className="heading__subtitle mb-0">Our team of eBay consultants has</h2>
                                </div>
                                <h3 className="heading__subtitle mb-10">
                                    years of experience working with businesses of all sizes and industries. We provide various services to help enterprises to build a strong presence on eBay, including optimizing product listings, improving search rankings, and creating effective marketing campaigns.
                                </h3>

                                <p>
                                    We start by getting to know your business, products, and target audience. This allows us to develop a customized strategy that meets your needs and goals. Our eBay consulting services include market research, competitor analysis, and data analysis to help you stay ahead of the curve and maximize your sales.
                                </p>
                                <p>
                                    We also help businesses create an effective marketing strategy, including email marketing, social media marketing, and advertising campaigns. Our team of experts is skilled at creating compelling content and graphics that engage customers and drive sales.
                                </p>

                                <p>
                                    We understand the importance of staying up to date with the latest eBay policies and guidelines. That's why we offer ongoing support and guidance to ensure your business remains compliant and thriving on the platform.
                                </p>
                                <p>
                                    Partnering with us for your eBay consulting needs means working with a team of experts dedicated to your success.

                                    Contact us today to learn how we can help you achieve your business goals on eBay.

                                </p>
                            </div>
                            <div>
                                <button className="pricing__footer">
                                    <PriceTableModal />
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    );
}
import React from "react";
import { Link } from "react-router-dom";

const TramsContent = () => {
  return (
    <div className="trams__body">
      <div className="min-h-[100dvh] max-w-[1200px] mx-auto">
        <div className="mt-[150px] container mx-auto px-10">
          <h2 className="text-[32px] text-center font-bold ">Terms of Use</h2>
          <p>
            These Terms of Use, and any documents referred to herein, set out
            the terms and conditions on which you are permitted to use our
            website,{" "}
            <Link to="https://business-savvy-uk.co.uk">
              https://business-savvy-uk.co.uk.
            </Link>{" "}
            By using our website, you agree to be bound by, and to comply with,
            these Terms of Use.
          </p>
          <p>These Terms of Use are effective from May 10, 2022.</p>
          <p>
            Please read these Terms of Use carefully. We recommend that you
            print off a copy of these Terms of Use for your records, as well as
            any future versions of them, as we may update them from time to
            time. YOUR ATTENTION IS PARTICULARLY DRAWN TO CLAUSES 14 (EXCLUSIONS
            AND LIMITATIONS OF LIABILITY), 15 (INDEMNIFICATION), 16
            (DISCLAIMERS), AND 17 (AGE RESTRICTIONS ON USE OF OUR WEBSITE).
          </p>
          <p>
            If for any reason whatsoever you do not agree to these Terms of Use
            or do not wish to be bound by them, you must not access or use our
            website.
          </p>
          {/* contact us */}

          <h2 className="text-2xl font-bold my-4">Contents</h2>
          <ul className="list-disc ml-8">
            <li>Our details</li>
            <li>
              Your responsibility for others who access our website using your
              device or internet connection
            </li>
            <li>Other documents governing your use of our website</li>
            <li>Availability of our website</li>
            <li>
              Changes we may make to these Terms of Use and other documentation
            </li>
            <li>Your account details</li>
            <li>Ownership of material on our website</li>
            <li>
              Information and content on our website provided on a non-reliance
              basis
            </li>
            <li>Permitted use of materials on our website</li>
            <li>Prohibited uses of our website</li>
            <li>Viruses and other harmful content</li>
            <li>Links to other websites</li>
            <li>Links to our website</li>
            <li>EXCLUSIONS AND LIMITATIONS OF LIABILITY</li>
            <li>INDEMNIFICATION</li>
            <li>DISCLAIMERS</li>
            <li>AGE RESTRICTIONS ON USE OF OUR WEBSITE</li>
            <li>Governing law and jurisdiction</li>
            <li>Copyright, credit and logo</li>
          </ul>

          <p>
            [1] You must insert the effective date of your Terms of Use so your
            users know when they take effect. If you have not previously
            uploaded terms of use to your website, or if you are replacing your
            existing terms of use with these Terms of Use, the effective date
            will be the date when you upload these Terms of Use to your website.
          </p>

          <p>
            [2] When you upload these Terms of Use to your website, you should
            ensure that the titles in this “Contents” section link to the
            relevant headings in the main body of these Terms of Use. For
            instance, the “Our details” title in this “Contents” section should
            link to the “Our details” heading in the main body of these Terms of
            Use.
          </p>

          <h2 className="text-2xl font-bold my-4">1. Our details</h2>

          <p>
            <strong> 1.1</strong> Business Savvy UK Ltd (we, our and us)
            operates the website.
          </p>
          <p>
            <strong> 1.2</strong> Business Savvy UK is a limited liability
            company incorporated in England and Wales . Our registered address
            is Businesss Savvy UK Limited StorageMart, Blackburn Road, Houghton
            Regis, LU5 5BQ. Our VAT registration number is GB206 366 226, if
            applicable. [ ] is a trading name of Business Savvy UK.
          </p>
          <p>
            <strong> 1.3 </strong> Our address is Business Savvy UK Limited
            Signature StorageMart, Blackburn Road, Houghton Regis, LU5 5BQ. Our
            VAT registration number is [ ]. [ ] is a trading name of [name of
            individual or sole trader].
          </p>
          <p>
            <strong> 1.4 </strong> Our contact telephone number is [(+44) 1582
            325 753 and our contact email address is
            info@business-savvy-uk.co.uk.
          </p>

          <h2 className="text-2xl font-bold my-4">
            2. Your responsibility for others who access our website using your
            device or internet connection
          </h2>

          <p>
            You must ensure that any persons who access our website on your
            computer(s) or device(s), or who are permitted or able to access our
            website on your computer(s) or device(s), or who use your internet
            connection, are aware of these Terms of Use and all other
            documentation referred to in them, and that such persons also agree
            to be bound by and to comply with these Terms of Use. If for any
            reason whatsoever, such persons do not agree to these Terms of Use
            or do not wish to be bound by them, they must not access or use our
            website, and you must not permit them to do so.
          </p>

          <h2 className="text-2xl font-bold my-4">
            3.Other documents governing your use of our website
          </h2>
          <p>
            <strong>3.1</strong> In addition to these Terms of Use, your use of
            our website is also governed by the following documents:
          </p>

          <p>
            (a) Our privacy policy, which is available at
            https://9-stars.co.uk/privacy-policy. Our privacy policy governs our
            use of your information. It sets out the types of information we
            collect, the reasons we collect it, how we use it, where we may pass
            it on to any third parties, in what circumstances and for what
            reasons, and any other relevant information relating to our use
            and/or processing of your information and your rights in relation to
            your information.
          </p>

          <p>
            (b) Our cookies policy, which is available at
            https://9-stars.co.uk/cookies-policy. Our cookies policy governs our
            use of cookies and similar technologies on our website. It sets out
            the types of cookies we use, the purposes for which we use them, the
            circumstances in which we may place cookies on your computer, device
            or browser, and other relevant information relating to cookies, such
            as how to change your browser preferences and settings to accept or
            reject cookies.
          </p>

          <p>
            (c) Our terms of sale, which are available at
            https://9-stars.co.uk/terms-of-use/. Our terms of sale govern any
            purchases or orders you make for goods or services on our website.
            They set out the status of any orders placed, the contract terms
            relating to delivery and performance of those orders, any exclusions
            that apply to you and other relevant terms relating to our supply of
            goods, services or digital content.
          </p>

          <p>
            (d) Our user content agreement, which is available at
            https://9-stars.co.uk/user-content-agreement/. Our user content
            agreement sets out the terms upon which you are permitted to upload
            content to our website and make use of its interactive functions. It
            also sets out the restrictions applicable to the type of content you
            may upload and describes our rights and remedies in respect of such
            content.
          </p>

          <p className="my-4">
            <strong> 3.2</strong> By accessing and using our website, you agree
            to be bound by the terms and conditions contained in these Terms of
            Use, you acknowledge that we will process your information in
            accordance with our privacy policy, and our use of cookies and
            similar technologies in accordance with our cookies policy.
          </p>
          <p className="my-4">
            {" "}
            <strong> 3.3</strong> If you do not agree to the terms set out in
            these Terms of Use, you must not use our website.
          </p>

          <h2 className="text-2xl font-bold my-4">
            4. Availability of our website
          </h2>

          <p className="py-4">
            {" "}
            <strong>4.1</strong> We make no representations and provide no
            warranties that:
          </p>

          <p>
            (a) the website will be made available at any specific time or from
            any specific geographical location;
          </p>
          <p>
            (b) your access to the website will be continuous or uninterrupted;
            or
          </p>
          <p>
            (c) the website will be accessible or optimised on all browsers,
            computers, tablets, phones or viewing platforms.
          </p>

          <p className="py-4">
            {" "}
            <strong>4.2</strong>
            We reserve the right to suspend access to all or part of the website
            for any reason, including for business or operational reasons, such
            as improving the appearance or functionality of the website, content
            updates, periodic maintenance, or to resolve any issues that we
            become aware of. Wherever we anticipate that we need to suspend
            access to the website for a considerable period of time, we will try
            to provide you with prior notice where reasonably practicable.
          </p>

          <p className="py-4">
            <strong>4.3</strong> Our website is provided for users in the United
            Kingdom only. Although it may be possible to access the website from
            other countries, we make no representation that our website is
            compliant with any legal requirements in force in any jurisdiction
            other than the United Kingdom, or that the content available on the
            website will be appropriate for users in other countries or states.
          </p>

          <h2 className="text-2xl font-bold my-4">
            5. Changes we may make to these Terms of Use and other documentation
          </h2>

          <p>
            <strong>5.1</strong>
            We reserve the right to update these Terms of Use, our privacy
            policy, our cookies policy and any other documentation referred to
            in any of these documents from time to time. We may change our Terms
            of Use and other documentation for any reason, including:
          </p>
          <p>
            (a) to reflect any changes in the way we carry out our business;
          </p>
          <p>
            (b) to account for any changes we make to our website, including,
            without limitation, any new features or functionality we provide,
            any adjustments to the means by which we provide notices to you, or
            any changes in the content, purpose or availability of the website;
          </p>
          <p>
            (c) to accurately describe our current data-processing activities so
            that you are kept up to date with our latest practices;
          </p>
          <p>
            (d) to inform you of any changes in the way that we use cookies or
            similar information-gathering technologies; or
          </p>
          <p>
            (e) to ensure that our documentation complies and remains compliant
            with any and all current and future applicable laws, regulations and
            official guidance.
          </p>
          <p>
            <strong> 5.2 </strong> If required by law, we will provide you with
            notice of any changes in these Terms of Use or the other
            documentation referred to in them by posting a notice on the website
            and/or by posting an updated version of these Terms of Use or other
            such documentation on our website with a new effective date stated
            at the beginning of them
          </p>
          <p>
            <strong> 5.3 </strong> By continuing to access our website after we
            have updated our Terms of Use, terms of sale, and/or user content
            agreement, you agree to be bound by those updated versions. You also
            acknowledge that by continuing to access our website after we have
            updated our privacy policy and/or our cookies policy, that the
            practices set out in those updated policies will apply to our
            handling of your information and our use of cookies and similar
            technologies.
          </p>
          <p>
            {" "}
            <strong> 5.4 </strong>
            5.4 You must check these Terms of Use and all other documentation
            referred to in them each time you access our website in order to
            ensure that you are aware of the terms that apply to you at that
            time.
          </p>
          <p>
            <strong> 5.5 </strong> The date that these Terms of Use and/or any
            other documents (including our privacy policy and cookies policy)
            were last amended is set out at the top of that document and is
            referred to as that document’s “effective date”.
          </p>

          <h2 className="text-2xl font-bold my-4">6. Your account details</h2>
          <p>
            <strong>6.1</strong> If we provide you with account information such
            as a user name, identification number, account code and/or password,
            you must keep such information confidential and secret and not
            disclose it to anyone. All account information is provided for use
            of the named account holder only, and not for any other person. You
            are responsible for any consequences of unauthorised access to your
            account due to any disclosure of your account information to any
            third party.
          </p>
          <p>
            <strong>6.2</strong> Where we provide you with the option to select
            your own login information, including a password, we recommend that
            you supply login information unique to your own use of this website,
            and do not use information from other accounts you may hold with
            other websites or any easily discoverable information about you. You
            are responsible for any consequences of unauthorised access to your
            account due to any disclosure of your login information to any third
            party
          </p>
          <p>
            <strong>6.3</strong> You must never use another user’s account
            without permission. When creating your account, you must provide
            accurate and complete information. You agree that you will not
            solicit, collect or use the login credentials of other individuals.
            We prohibit the creation of, and you agree that you will not create,
            an account for anyone other than yourself. You also represent that
            all information you provide to us upon registration and at all other
            times will be true, accurate, current, and complete. You agree to
            update your information as necessary to maintain its truth and
            accuracy.
          </p>
          <p>
            <strong> 6.4 </strong> We reserve the right to withdraw access to
            your account without notice for any actual or suspected breach of
            these Terms of Use or any other documentation referred to in them,
            including, without limitation, where we suspect that there has been
            unauthorised access to your account, or any unauthorised disclosure
            of your login information.
          </p>
          <p>
            <strong> 6.5</strong> If you know or suspect that the
            confidentiality of your login information has been compromised, for
            example, by the disclosure of such information to any third party,
            you must immediately change your password. If you are unable to
            change your password, you must immediately notify us by email, at
          </p>
          <a href="info@business-savvy-uk.co.uk." target="_blank">
            info@business-savvy-uk.co.uk.
          </a>

          <h2 className="text-2xl font-bold my-4">
            7. Ownership of material on our website
          </h2>

          <p>
            <strong> 7.1</strong> All trade marks, service marks, trade names,
            logos, copyright and other intellectual property rights in our
            website and its content are either owned by us or licensed to us.
            All such rights are protected by intellectual property laws around
            the world, and all rights are reserved. Any use of the website and
            its contents, other than as specifically authorised herein, is
            strictly prohibited. Any rights not expressly granted herein are
            reserved by us.
          </p>
          <p>
            <strong>7.2</strong> [Insert name of trade mark] and [insert name of
            trade mark] are [registered] trade marks of Business Savvy UK.
          </p>
          <p>
            <strong> 7.3</strong> The trade marks, service marks, trade names,
            logos and other branding owned by third parties and used or
            displayed on or via our website (collectively, “Third Party
            Mark(s)”) may be trade marks of their respective owners, who may or
            may not endorse or be affiliated with or connected with us. Except
            as expressly provided in these Terms of Use, or in terms provided by
            the owner of a Third Party Mark, nothing in these Terms of Use or on
            or via the website should be construed as granting, by implication,
            estoppel, or otherwise, any licence or right to use any of our or
            any Third Party Marks that are used or displayed on the website,
            without the respective owner’s prior written permission, in each
            instance. All goodwill generated from the use of our trade marks
            will benefit us exclusively.
          </p>

          <h2 className="text-2xl font-bold my-4">
            8. Information and content on our website provided on non-reliance
            basis
          </h2>
          <p>
            <strong>8.1</strong> Our website is made available to you in order
            to provide you with general information about us, our business, and
            any products or services that we offer from time to time. We do not
            make our website available for any other purposes, except as
            expressly provided in these Terms of Use.
          </p>
          <p>
            <strong>8.2</strong> The content on our website is not intended to
            be construed as advice. You must not rely on any of the content of
            our website for any purposes whatsoever, and you must seek your own
            independent professional advice before deciding to take any course
            of action on the basis, whether in whole or in part, of any of the
            content available on our website at any time.
          </p>
          <p>
            <strong> 8.3</strong> We make no representations and provide no
            warranties whatsoever, whether express or implied, that any of the
            content or materials available on our website from time to time are
            accurate, up to date or complete.
          </p>

          <h2 className="text-2xl font-bold my-4">
            9. Permitted use of materials on our website
          </h2>
          <p>
            <strong> 9.1</strong> The content on our website is provided for
            your personal, private and non-commercial use only. You may print or
            share the content from our website for lawful personal, private and
            non-commercial purposes, and you may also make others within your
            organisation aware of the content on our website. You may not
            otherwise extract, reproduce or distribute the content of our
            website without our prior written consent.
          </p>
          <p>
            <strong>9.2</strong> Whenever you print, download, share or pass on
            content from our website to others, you must not make any additions
            or deletions or otherwise modify any text from our website, you must
            not alter or change any images, media or graphics from our website
            in any way, you may not remove any accompanying text from such
            images, media or graphics, and you must ensure that all content
            passed on to any third party is an accurate representation of the
            content as it appears on our website.
          </p>

          <p>
            <strong>9.3</strong> You are prohibited from using any robots,
            spiders, data mining or scraping technology or any similar third
            party tools for the extraction or reproduction of any data or
            content from our website without our prior written consent.
          </p>
          <p>
            <strong>9.4</strong> Whenever you pass on any content or materials
            from our website to anyone, you must acknowledge us as the authors
            of such content or materials (or any other authors wherever credited
            by us) at the time when you pass on such content or materials.
          </p>

          <h2 className="text-2xl font-bold my-4">
            10. Prohibited uses of our website
          </h2>
          <p>
            <strong>10.1</strong> You must not reproduce, duplicate, copy or
            resell any part of our website or any content from our website, save
            and except to the extent expressly permitted in these Terms of Use.
          </p>
          <p>
            <strong>10.2</strong> You must not, without our prior written
            consent, access, interfere with, damage or disrupt in any way our
            website or any part of it, our systems, any of our hardware or
            equipment or any networks on which our website is hosted, any
            software that we use to create or modify the website or to make the
            website available to you, or any hardware, equipment, network,
            server, software or technology owned or operated by us or any third
            party.
          </p>
          <p>
            <strong>10.3</strong> You must use our website for lawful purposes
            only and in accordance with these Terms of Use. You must not use our
            website:
          </p>
          <p>
            <strong>(a) </strong> for any purpose that is unlawful or that in
            any way breaches any applicable laws or regulations, whether local,
            national or internationa
          </p>
          <p>
            <strong>(b) </strong> for any fraudulent purposes whatsoever;
          </p>
          <p>
            <strong>(c)</strong> to conduct any unsolicited or unauthorised
            advertising or direct or indirect marketing to anyone by any means,
            or to otherwise spam,
          </p>
          <p>
            <strong>(d)</strong> to upload, host or transmit any viruses,
            malware, adware, spyware, worms, Trojan horses, keystroke loggers,
            spyware, logic bombs, time bombs or any other harmful programs or
            code which could adversely affect the use or operation of the
            website, our hardware or systems, or the computers, tablets, phones
            or other devices of any users or other third parties, or to upload
            any content or materials containing any such content;
          </p>
          <p>
            <strong>(e)</strong> to communicate with, harm or attempt to harm
            children in any way; or
          </p>
          <p>
            <strong>(f)</strong> in any way or for any purpose that breaches
            these Terms of Use or the terms of any of the documents these Terms
            of Use refer to.
          </p>
          <p>
            <strong>10.4</strong> You must not submit any information about you
            to us if you are under the age of 18, or about any other person who
            is either:
          </p>
          <p>
            <strong>(a)</strong> under the age of 18; or
          </p>
          <p>
            <strong>(b)</strong> if they are aged 18 or above, where you have
            not received their prior written consent to submit information about
            them to us.
          </p>
          <p>
            <strong>10.5</strong> You must not submit to us any information
            which is considered ‘sensitive personal information’. ‘Sensitive
            personal information’ is information about you or any other person
            which reveals your or their racial or ethnic origin, political
            opinions, religious or philosophical beliefs, trade union membership
            or which is genetic data, biometric data, information which concerns
            your or their health, sex life or sexual orientation.
          </p>
          <p>
            <strong>10.6</strong> If you accidentally or intentionally submit
            such information to us, you will be considered to have consented to
            our processing of that information on the basis of Article 9(2)(a)
            of the General Data Protection Regulation (Regulation (EU)
            2016/769).
          </p>

          <h2 className="text-2xl font-bold my-4">
            11. Viruses and other harmful content
          </h2>
          <p>
            <strong>11.1</strong> We do not guarantee that our website does not
            contain viruses or other malicious software. [However, we do make
            reasonable efforts to prevent such viruses or bugs from being
            uploaded to our website.]
          </p>
          <p>
            <strong> 11.2</strong> We shall not be responsible for any bugs or
            viruses on our website, or any software that might be transferred to
            your computer from our website, or any consequences which the
            presence or operation of such programs may have.
          </p>
          <p>
            <strong>11.3</strong> You must ensure that you have in place
            up-to-date and effective anti-virus protection on your computer or
            other browsing device.
          </p>
          <p>
            <strong>11.4</strong> You must not upload or otherwise introduce to
            our website any viruses, malware, spyware, adware, Trojan horses,
            worms, logic bombs, time bombs, keystroke loggers or any other
            programs or code that is harmful or malicious.
          </p>
          <p>
            <strong>11.5 </strong> You must not use any third parties, software
            or technology to attempt to gain unauthorised access to our website,
            our servers, systems, hardware, software or data.
          </p>
          <p>
            <strong>11.6</strong> You must not attempt to perform any denial of
            service type attack on our website.
          </p>
          <p>
            <strong>11.7</strong> You must not perform any action which would
            contravene the Computer Misuse Act 1990.
          </p>
          <p>
            <strong>11.8</strong> We may report any breach or suspected breach
            of this clause 11 (Viruses and other harmful content) to the
            relevant authorities and may disclose your identity
          </p>

          <h2 className="text-2xl font-bold my-4">
            12. Links to other websites
          </h2>
          <p>
            <strong>12.1 </strong> Links to third party content or websites may
            appear on our website from time to time. We are not responsible for
            the content of any websites accessible via any link(s) on our
            website. All content on third party websites is outside of our
            control, and we do not represent or warrant that such content is
            related to us or our website, suitable or appropriate for use or
            viewing, lawful or accurate.
          </p>
          <p>
            <strong>12.2 </strong> Any third party website accessible via a link
            on our website may collect and process your information. We are not
            responsible for any data-processing activities carried out by any
            third party website which is linked to from our website, and we
            disclaim any and all liability in respect of the same. You should
            check the privacy policy of any such third party to establish how
            they may use your information before you decide to use their website
            and its features.
          </p>

          <h2 className="text-2xl font-bold my-4">13. Links to our website</h2>
          <p>
            <strong>13.1</strong> ou may not link to our website without our
            prior written consent.
          </p>
          <p>
            <strong>13.2</strong> Where you have obtained our consent to link to
            our website:
          </p>
          <p>
            <strong>(a)</strong> you may provide links to our website on other
            websites owned by you, provided that such websites and the use of
            any links to our website comply with these Terms of Use;
          </p>
          <p>
            <strong> (b)</strong> wherever you post a link to our website on any
            other website, you agree that you will do so in an appropriate
            manner, and not in any way which is defamatory or disparaging
            towards us, which misrepresents us or our business, or which causes
            any harm whatsoever to us or our business; and
          </p>
          <p>
            <strong>(c) </strong> you must not link to our website in order to
            suggest any form of joint venture, partnership, collaboration,
            affiliation, business relationship, approval or endorsement in
            connection with us where none exists and in any event, without
            having first obtained our prior written consent.
          </p>
          <p>
            <strong>13.3</strong> We may withdraw permission to link to our
            website at any time. In the event that we withdraw permission to
            link to our website and inform you of the same, you must immediately
            remove or cause to be removed any links to our website.
          </p>

          <h2 className="text-2xl font-bold my-4">
            14. EXCLUSIONS AND LIMITATIONS OF LIABILITY
          </h2>
          <p>
            <strong>14.1 </strong>We do not exclude our liability to you where
            it would be unlawful to do so, for example, for death or personal
            injury caused by our negligence. If applicable law does not allow
            all or any part of the below limitations of liability to apply to
            you, the limitations will apply to you only to the maximum extent
            permitted by applicable law.
          </p>
          <p>
            <strong>14.2</strong> [If you purchase goods or services from our
            website, different exclusions of liability may apply. These are
            contained in our terms of sale.]
          </p>
          <p>
            <strong>14.3</strong> SUBJECT TO THE AFORESAID, IN NO EVENT SHALL WE
            (INCLUDING OUR PARENTS, SUBSIDIARIES, AFFILIATES, OFFICERS,
            DIRECTORS, MEMBERS, EMPLOYEES OR AGENTS) UNDER ANY CIRCUMSTANCES
            WHATSOEVER BE LIABLE TO YOU FOR ANY LOSS, DAMAGE (WHETHER DIRECT,
            INDIRECT, PUNITIVE, ACTUAL, CONSEQUENTIAL, INCIDENTAL, SPECIAL,
            EXEMPLARY, OR OTHERWISE) COSTS, EXPENSES, LIABILITIES OR PENALTIES,
            WHETHER IN CONTRACT, TORT, BREACH OF STATUTORY DUTY OR OTHERWISE,
            WHETHER FORESEEABLE OR UNKNOWN, ARISING FROM, IN CONNECTION WITH OR
            RELATING TO:
          </p>
          <p>
            <strong>(a)</strong> YOUR USE OF OUR WEBSITE;
          </p>
          <p>
            <strong>(b)</strong> ANY CORRUPTION OR LOSS OF DATA;
          </p>
          <p>
            <strong>(c) </strong> ANY INABILITY TO ACCESS OUR WEBSITE,
            INCLUDING, WITHOUT LIMITATION, ANY INTERRUPTIONS, SUSPENSION OR
            WITHDRAWAL OF OUR WEBSITE (FOR ANY REASON WHATSOEVER);
          </p>
          <p>
            <strong>(d)</strong> ANY USE YOU MAKE OF ANY CONTENT OR MATERIALS ON
            OUR WEBSITE, INCLUDING ANY RELIANCE YOU MAKE ON SUCH CONTENT OR
            MATERIAL;
          </p>
          <p>
            <strong>(e)</strong> ANY LOSS OF SAVINGS, PROFITS, SALES, BUSINESS
            OR REVENUE;
          </p>
          <p>
            <strong>(f)</strong> ANY LOSS OF REPUTATION OR GOODWILL;
          </p>
          <p>
            <strong>(g)</strong> ANY LOSS OF SAVINGS;
          </p>
          <p>
            <strong> (h)</strong> ANY LOSS OF A CHANCE OR OPPORTUNITY; OR
          </p>
          <p>
            <strong>(i)</strong> ANY OTHER SECONDARY, CONSEQUENTIAL OR INDIRECT
            LOSSES, <br />
            AND EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH LOSS OR
            DAMAGE, WITHOUT LIMITATION, YOU ASSUME AND SHALL BE LIABLE FOR THE
            ENTIRE COST OF ALL NECESSARY SERVICING, REPAIR OR CORRECTION IN THE
            EVENT OF ANY SUCH LOSS, DAMAGE, COSTS, EXPENSES, LIABILITIES OR
            PENALTIES ARISING.
          </p>
          <p>
            <strong>14.4</strong> WE SHALL NOT BE LIABLE FOR ANY DAMAGE THAT YOU
            COULD HAVE AVOIDED BY FOLLOWING OUR ADVICE TO APPLY AN UPDATE
            OFFERED TO YOU FREE OF CHARGE OR FOR DAMAGE THAT WAS CAUSED BY YOU
            FAILING TO CORRECTLY FOLLOW INSTALLATION INSTRUCTIONS OR TO HAVE IN
            PLACE THE MINIMUM SYSTEM REQUIREMENTS ADVISED BY US.
          </p>
          <p>
            <strong>14.5</strong> You specifically agree that we shall not be
            liable for any content or the defamatory, offensive or illegal
            conduct of any third party and that the risk of harm or damage from
            the foregoing rests entirely with you.
          </p>
          <p>
            <strong>14.6</strong> YOU AGREE THAT IN THE EVENT THAT YOU INCUR ANY
            DAMAGES, LOSSES OR INJURIES ARISING OUT OF, OR IN CONNECTION WITH,
            OUR ACTS OR OMISSIONS, THE DAMAGES, IF ANY, CAUSED TO YOU ARE NOT
            IRREPARABLE OR SUFFICIENT TO ENTITLE YOU TO AN INJUNCTION PREVENTING
            ANY EXPLOITATION OF ANY WEBSITE, SERVICE, PROPERTY, PRODUCT OR OTHER
            CONTENT OWNED OR CONTROLLED BY US, AND YOU WILL HAVE NO RIGHTS TO
            ENJOIN OR RESTRAIN THE DEVELOPMENT, PRODUCTION, DISTRIBUTION,
            ADVERTISING, EXHIBITION OR EXPLOITATION OF ANY WEBSITE, PROPERTY,
            PRODUCT, SERVICE, OR OTHER CONTENT OWNED OR CONTROLLED BY US.
          </p>
          <p>
            <strong>14.7</strong> To the extent that any of the provisions of
            this clause 14 (EXCLUSIONS AND LIMITATIONS OF LIABILITY) are
            unenforceable as outright exclusions of liability, they shall be
            construed as limitations on liability, limiting our liability to you
            to the maximum extent permitted by law.
          </p>
          <h2 className="text-2xl font-bold my-4">15. INDEMNIFICATION</h2>
          <p>
            <strong>15.1</strong> You (and also any third party for or on behalf
            of whom you operate an account or activity on the website) agree to
            defend (at our request), indemnify and hold us harmless from and
            against any claims, liabilities, damages, losses and expenses,
            including, without limitation, reasonable legal and attorneys’ fees
            and costs, arising out of or in any way connected with any of the
            following (including as a result of your direct activities on the
            website or those conducted on your behalf):
          </p>
          <p>
            <strong>(a)</strong> your uploads, access to or use of the website;
          </p>
          <p>
            <strong>(b)</strong> your breach or alleged breach of these Terms of
            Use;
          </p>
          <p>
            <strong>(c)</strong> your violation of any third-party right,
            including, without limitation, any intellectual property right,
            publicity, confidentiality, property or privacy right;
          </p>
          <p>
            <strong>(d)</strong> your violation of any laws, rules, regulations,
            codes, statutes, ordinances or orders of any governmental and
            quasi-governmental authorities, including, without limitation, all
            regulatory, administrative and legislative authorities; or
          </p>
          <p>
            <strong>(e)</strong> any misrepresentation made by you.
          </p>
          <p>
            <strong>15.2 </strong> You will cooperate as fully required by us in
            the defence of any claim. We reserve the right to assume the
            exclusive defence and control of any matter subject to
            indemnification by you, and you will not, in any event, settle any
            claim without our prior written consent.
          </p>

          <h2 className="text-2xl font-bold my-4">16. DISCLAIMERS</h2>

          <p>
            <strong>16.1 </strong> THE WEBSITE IS PROVIDED ON AN “AS IS”, “AS
            AVAILABLE” AND “WITH ALL FAULTS” BASIS. TO THE FULLEST EXTENT
            PERMISSIBLE BY LAW, WE DO NOT MAKE ANY REPRESENTATIONS OR WARRANTIES
            OR ENDORSEMENTS OF ANY KIND WHATSOEVER, EXPRESS OR IMPLIED, AS TO:
          </p>
          <p>
            <strong>(a)</strong> THE SERVICE;
          </p>
          <p>
            <strong>(b)</strong> THE WEBSITE CONTENT;
          </p>
          <p>
            <strong>(c)</strong> USER CONTENT; OR
          </p>
          <p>
            <strong>(d)</strong> SECURITY ASSOCIATED WITH THE TRANSMISSION OF
            INFORMATION TO THE WEBSITE.
          </p>
          <p>
            N ADDITION, WE HEREBY DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED,
            INCLUDING, BUT NOT LIMITED TO, THE WARRANTIES OF MERCHANTABILITY,
            FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, TITLE, CUSTOM,
            TRADE, QUIET ENJOYMENT, SYSTEM INTEGRATION AND FREEDOM FROM COMPUTER
            VIRUS.
          </p>
          <p>
            <strong>16.2</strong> WE DO NOT REPRESENT OR WARRANT THAT THE
            SERVICE WILL BE ERROR-FREE OR UNINTERRUPTED, THAT DEFECTS WILL BE
            CORRECTED, OR THAT THE SERVICE OR THE SERVER THAT MAKES THE SERVICE
            AVAILABLE IS FREE FROM ANY HARMFUL COMPONENTS, INCLUDING, WITHOUT
            LIMITATION, VIRUSES. WE DO NOT MAKE ANY REPRESENTATIONS OR
            WARRANTIES THAT THE INFORMATION (INCLUDING ANY INSTRUCTIONS) ON THE
            SERVICE IS ACCURATE, COMPLETE OR USEFUL. YOU ACKNOWLEDGE THAT YOUR
            USE OF THE WEBSITE IS AT YOUR SOLE RISK. WE DO NOT WARRANT THAT YOUR
            USE OF THE WEBSITE IS LAWFUL IN ANY PARTICULAR JURISDICTION, AND WE
            SPECIFICALLY DISCLAIM SUCH WARRANTIES. SOME JURISDICTIONS LIMIT OR
            DO NOT ALLOW THE DISCLAIMER OF IMPLIED OR OTHER WARRANTIES SO THE
            ABOVE DISCLAIMER MAY NOT APPLY TO YOU TO THE EXTENT SUCH
            JURISDICTION’S LAW IS APPLICABLE TO YOU AND THESE TERMS OF USE.
          </p>
          <p>
            <strong>16.3</strong> BY ACCESSING OR USING THE WEBSITE YOU
            REPRESENT AND WARRANT THAT YOUR ACTIVITIES ARE LAWFUL IN EVERY
            JURISDICTION WHERE YOU ACCESS OR USE THE SERVICE.
          </p>
          <p>
            <strong>16.4</strong> WE DO NOT ENDORSE CONTENT AND SPECIFICALLY
            DISCLAIM ANY RESPONSIBILITY OR LIABILITY TO ANY PERSON OR ENTITY FOR
            ANY LOSS, DAMAGE (WHETHER ACTUAL, CONSEQUENTIAL, PUNITIVE OR
            OTHERWISE), INJURY, CLAIM, LIABILITY OR OTHER CAUSE OF ACTION OF ANY
            KIND OR CHARACTER BASED UPON OR RESULTING FROM ANY CONTENT.
          </p>

          <h2 className="text-2xl font-bold my-4">
            17. AGE RESTRICTIONS ON USE OF OUR WEBSITE
          </h2>

          <p>
            <strong> 17.1</strong> Our website and any products or services
            available on or via the website are not intended for use by
            individuals under the age of 18.
          </p>
          <p>
            <strong> 17.2</strong> IF YOU ARE UNDER THE AGE OF 18, YOU MUST NOT
            USE OUR WEBSITE, PURCHASE OR ATTEMPT TO PURCHASE ANY OF OUR PRODUCTS
            OR SERVICES, OR SUBMIT ANY INFORMATION ABOUT YOU OR ANYONE ELSE TO
            US.
          </p>
          <p>
            <strong> 17.3</strong> We do not knowingly or intentionally process
            information about any individual under the age of 18.
          </p>

          <h2 className="text-2xl font-bold my-4">
            18. Governing law and jurisdiction
          </h2>
          <p>
            <strong> 18.1</strong> These Terms of Use, any documents they refer
            to, and any disputes arising from or in relation to them or any
            documents they refer to, whether contractual or non-contractual,
            shall be governed by and construed in accordance with English law.
          </p>
          <p>
            <strong>18.2</strong> The courts of England and Wales shall have
            exclusive jurisdiction over any claims or disputes arising from or
            in relation to these Terms of Use and any documents they refer to.
          </p>
          <h2 className="text-2xl font-bold my-4">
            Copyright, credit and logo
          </h2>
          <p>
            <strong>19.1</strong> The copyright in these Terms of Use is either
            owned by, or licensed to, us and is protected by copyright laws
            around the world and copyright protection software. Unless expressly
            indicated otherwise, all intellectual property rights in this
            document and elsewhere on our website, including any content on our
            website, are reserved.
          </p>
          <p>
            <strong>19.2</strong> These Terms of Use are based on a General Data
            Protection Regulation (Regulation (EU) 2016/769) (“GDPR”) compliant
            template provided by GDPR Privacy Policy. For further information,
            please visit www.gdprprivacypolicy.org
          </p>
          <p>
            <strong>19.3</strong> Where we display the GDPR Privacy Policy logo
            on our website, this is used to indicate that we have adopted a
            privacy policy template provided by GDPR Privacy Policy as the basis
            for this Privacy Policy.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TramsContent;

import React, { useEffect } from "react";
import Headers from "../../components/header/Headers";
import DarkFooter from "../../components/footer/DarkFooter";
import TramsContent from "./TramsContent";

const Trams = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <div>
      <Headers Class="bg-grays"/>
      <TramsContent />
      <DarkFooter />
    </div>
  );
};

export default Trams;

import React from "react";
import { Link } from "react-router-dom";
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

export default function SimpleSlider() {

  return (
    <div className="slider">
      <div className="slick-carousel carousel-arrows-light carousel-dots-light m-slides-0">
        <Swiper
          slidesPerView={1}
          modules={[Pagination]}
          pagination={{ clickable: true }}
          loop={true}
        >
          <SwiperSlide>
            <div className="bg-slider-img">
              <div className="slide-item align-v-h bg-overlay bg-overlay-gradient">
                <div className="container">
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-7">
                      <div className="slide__content">
                        <h2 className="slide__title">
                          Smooth sailing in business is a rare luxury,
                        </h2>
                        <h3 className="slide__subtitle">
                          but having the right experience,
                          wisdom, and expertise can help steer you towards success.
                        </h3>
                        <p className="slide__desc">
                          At Business Savvy UK, we understand the challenges of starting or growing a business
                          and are here to make a difference. So take the first step towards achieving your goals
                          and explore our services with a free, no-obligation consultation. It could be the
                          difference between smooth sailing and navigating rough waters.
                        </p>
                        <div className="d-flex align-items-center flex-wrap">
                          <Link
                            to='/about-us'
                            className="btn btn__primary btn__icon mr-30"
                          >
                            <span>More About Us</span>
                            <i className="icon-arrow-right" />
                          </Link>
                          <a
                            className="video__btn video__btn-rounded video__btn-white popup-video"
                            href="https://www.youtube.com/watch?v=nrJtHemSPW4"
                          >
                            <div className="video__player">
                              <i className="fa fa-play" />
                            </div>
                            <span className="video__btn-title color-white">Our Video!</span>
                          </a>
                        </div>
                      </div>
                      {/* /.slide-content */}
                    </div>
                    {/* /.col-xl-7 */}
                  </div>
                  {/* /.row */}
                </div>
                {/* /.container */}
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="bg-img-two">
              <div className="slide-item align-v-h bg-overlay bg-overlay-gradient">
                <div className="container">
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-7">
                      <div className="slide__content">
                        <h2 className="slide__title">
                          Transform your business with
                        </h2>
                        <h2 className="slide__subtitle">
                          our comprehensive services designed to support
                          businesses at every stage, from start-ups to global networks.
                        </h2>
                        <p className="slide__desc">
                          As experienced professionals who genuinely care about your future and success,
                          we offer a consultative approach that adapts to the emerging and progressive
                          marketplace. With Business Savvy UK, you can trust that your true partners
                          understand your business and provide the guidance you need to succeed.
                        </p>
                        <a href="#/" className="btn btn__primary btn__icon mr-30">
                          <span>More About Us</span>
                          <i className="icon-arrow-right" />
                        </a>
                        <a href="projects-grid.html" className="btn btn__white">
                          Our Services
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
}

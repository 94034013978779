import React from "react";
import img1 from "../../../assets/images/about/2.jpg";
import img2 from "../../../assets/images/about/sign132.png"

export default function AboutLayoutThree() {
  return (
    <section id="about" className="about-layout3 pt-130 pb-40">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
            <div className="heading mb-30">
              <div className="d-flex align-items-center mb-20">
                <div className="divider divider-primary mr-30" />
                <h2 className="heading__subtitle mb-0">
                Connecting Business Aspirations with Tangible Digital Results.

{" "}
                </h2>
              </div>
              <h3 className="heading__title mb-40">
              Expertise Beyond Compare & Outcomes Beyond Expectation
              </h3>
            </div>
          </div>
          {/* /.col-xl-5 */}
        </div>
        {/* /.row */}
        <div className="row about__Text">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-5 offset-xl-1">
            <i className="icon-quote" />
            <p>
            At Business Savvy, our commitment shines through our name. 
            Through the years, we've emerged as leaders in the digital space, 
            known for understanding the digital intricacies and delivering 
            tailored solutions that align with diverse business goals. 
           {" "}
            </p>
            <img
              src={img2}
              alt="singnture"
              className="mb-20"
            />
          </div>
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-5 offset-xl-1">
            <p>
            From budding startups to seasoned enterprises, we've shaped digital 
            success stories, making us a trusted partner in every venture.
            </p>
            <ul className="list-items list-items-layout2 list-vartical list-unstyled d-flex flex-wrap mt-40">
              <li>Strategic Digital Solutions for Diverse Business Needs.</li>
              <li>Established Success Record in the Digital Arena.</li>
              <li>All-in-One Digital Solutions Hub.</li>
              <li>Pioneering Innovation & Continuous Skill Enhancement.</li>
              <li>Consistent Client Engagement & Transparent Communication.</li>
              
            </ul>
          </div>
          {/* /.col-xl-5 */}
        </div>
        {/* /.row */}
        <div className="row align-items-center mt-60">
          <div className="col-sm-12 col-md-12 col-lg-7">
            <div className="about__img about__img-1">
              <img src={img1} alt="about" />
            </div>
            {/* /.about-img */}
          </div>
          {/* /.col-lg-5 */}
          <div className="col-sm-12 col-md-12 col-lg-5">
            <div className="about__img">
              <img src={img1} alt="about" />
            </div>
            {/* /.about-img */}
          </div>
          {/* /.col-lg-5 */}
        </div>
        {/* /.row */}
      </div>
      {/* /.container */}
    </section>
  );
}

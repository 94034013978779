import React from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import emailjs from "@emailjs/browser";
import HubspotFormV1 from "../from";

const CommonQuote = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log(data);
    emailjs
      .send("service_qd6ckit", "template_5y4ery4", data, "nSP0cYyvCFTGkDEWh")
      .then(
        (result) => {
          console.log(result.text);
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Request quote sended successfully",
            showConfirmButton: false,
            timer: 1500,
          });
          reset();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  console.log(errors);
  return (
    <div className="contact-panel">
      <form
        className="contact-panel__form"
        method="post"
        action="assets/php/contact.php"
        id="contactForm"
      >
        <div className="row">
          <div className="col-12">
            <h4 className="contact-panel__title mb-20">Request A Quote</h4>
            <p className="contact-panel__desc mb-30">
              Our deep pool of certified engineers and IT staff are ready to
              help you to keep your IT business safe &amp; ensure high
              availability.
            </p>
          </div>{" "}
          {/* /.col-12 */}
          <div className="hb-from">
            <HubspotFormV1 />
          </div>
          {/* /.col-12 */}
        </div>
        {/* /.row */}
      </form>
    </div>
  );
};

export default CommonQuote;
